<template>
  <div class="page-title-box d-sm-flex justify-content-end">
    <ol class="breadcrumb m-0">
      <li
        v-for="nav in dataset.navigationList"
        :key="nav?.menuId"
        class="breadcrumb-item"
      >
        <span v-if="'FOLDER' === nav?.menuType">{{ nav?.menuName }}</span>
        <a
          v-else
          class="text-decoration-underline text-primary cursor-pointer"
          :class="{ 'fw-bold': 'SINGLE' === nav?.menuType }"
          @click.prevent="event.onClickRouterPush(nav)"
          >{{ nav?.menuName }}</a
        >
      </li>
      <li class="breadcrumb-item active">
        <span>{{ $route.meta["pageName"] }}</span>
      </li>
    </ol>
  </div>
</template>
<script>
import { ref, getCurrentInstance, watchPostEffect, computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const { getters } = useStore();

    const dataset = ref({
      navigationList: [],
    });

    const userRoleMenuList = computed(() => getters["user/roleMenuList"]);

    const event = {
      onClickRouterPush: (nav) => {
        proxy.$router.push({ path: nav.menuUrl });
      },
    };

    watchPostEffect(() => {
      const matchedRoutePathList = proxy.$route.matched.map(
        (route) => route.path,
      );
      // 현재 라우트 경로와 일치하는 메뉴
      const filteredMenu = userRoleMenuList.value?.find((menu) => {
        return new RegExp(`^${menu.menuUrl}(?=\/|$)`).exec(
          proxy.$urlUtils.filteredRoutePath(matchedRoutePathList),
        );
      });
      // 현재 라우트 경로와 일치하는 메뉴 기준으로 네비게이션 목록 주입
      dataset.value.navigationList = filteredMenu?.structureCode
        ?.split(".")
        .filter((codeId) => codeId)
        .map((codeId) => {
          return userRoleMenuList.value?.find((menu) => menu.menuId === codeId);
        });
    });

    return { dataset, event };
  },
};
</script>
