export default {
  name: "project",
  path: "/project",
  component: () => import("@/views/index"),
  redirect: { name: "projectList" },
  meta: {
    group: "organization",
    isView: true,
  },
  children: [
    {
      name: "projectList",
      path: "list",
      meta: {
        title: "프로젝트 목록",
        pageName: "프로젝트 목록",
      },
      component: () => import("@/views/project/projectList"),
    },
    {
      name: "projectView",
      path: ":projectId",
      component: () => import("@/views/project/projectView"),
      meta: {
        title: "프로젝트 상세",
        pageName: "프로젝트 상세",
      },
      children: [
        {
          name: "projectViewDetails",
          path: "details",
          meta: {
            title: "프로젝트 상세-개요",
          },
          component: () => import("@/views/project/components/projectViewDetails.vue"),
        },
      ],
    },
  ],
};
