export default {
  name: "sample",
  path: "/sample",
  component: () => import("@/views/sample/index"),
  meta: {
    group: "sample",
    isView: true,
  },
  children: [
    {
      name: "treeselectTest",
      path: "treeselect/test",
      meta: {
        pageName: "샘플",
      },
      component: () => import("@/views/sample/treeselect/test"),
    },
    {
      name: "modalSample",
      path: "modal",
      meta: {
        pageName: "모달 샘플",
      },
      component: () => import("@/views/sample/modalSample"),
    },
    {
      name: "popupParent",
      path: "popup",
      meta: {
        pageName: "팝업 샘플",
      },
      component: () => import("@/views/sample/popupParent"),
    },
    {
      name: "popupChild",
      path: "popup/:id",
      meta: {
        pageName: "팝업 샘플",
        isWinPopup: true,
      },
      component: () => import("@/views/sample/popupChild"),
    },
    {
      name: "404Error",
      path: "404",
      component: () => import("@/views/sample/error/404Error"),
    },
    {
      name: "500Error",
      path: "500",
      meta: {
        pageName: "샘플",
      },
      component: () => import("@/views/sample/error/500Error"),
    },
  ],
};
