import api from "../http";
export default {
  getPrivilegeList: (data = null) => {
    return api.getPromise("GET", `/portal/privilege/list`, data);
  },

  getPrivilegePage: (data = null) => {
    return api.getPromise("GET", `/portal/privilege`, data);
  },

  getPrivilege: (data = null) => {
    return api.getPromise("GET", `/portal/privilege/${data.privilegeId}`, data);
  },

  getPrivilegeDomainTypeList: (data = null) => {
    return api.getPromise("GET", `/portal/privilege/domaintype`, data);
  },

  getPrivilegeResourceIdList: (data = null) => {
    return api.getPromise("GET", `/portal/privilege/resourceid/list`, data);
  },

  getPrivilegeActionList: (data = null) => {
    return api.getPromise("GET", `/portal/privilege/action/list`, data);
  },

  createPrivilege: (data = null) => {
    return api.getPromise("POST", `/portal/privilege`, data);
  },

  deletePrivilege: (data = null) => {
    return api.getPromise("POST", `/portal/privilege/delete`, data);
  },

  multipleDeletePrivilege: (data = null) => {
    return api.getPromise("POST", `/portal/privilege/delete/multiple`, data);
  },

  getPrivilegeItemList: (data = null) => {
    return api.getPromise("GET", `/portal/privilege/items`, data);
  },

  getPrivilegeItemTreeList: (data = null) => {
    return api.getPromise("GET", `/portal/privilege/items/tree`, data);
  },

  getPrivilegeItemDomainTypeList: () => {
    return api.getPromise("GET", `/portal/privilege/items/domaintype`);
  },

  updatePrivilege: (data = null) => {
    return api.getPromise("POST", `/portal/privilege/update`, data);
  },
};
