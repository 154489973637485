<template>
  <Form ref="form">
    <div class="row mb-4">
      <label
        for="additionalServiceName"
        class="col-form-label col-lg-2"
        >발행일</label
      >
      <div class="col-lg-4">
        <Field
          id="invoiceDate"
          v-model="dataset.invoicedDate"
          :class="{ 'is-invalid': !dataset.invoicedDate }"
          name="invoicedDate"
          class="form-control col-2"
          type="date"
        />
      </div>
      <div class="invalid-feedback">
        {{ errors.InvoicedDate }}
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="selectProjects"
        class="col-form-label col-lg-2"
        >납부일</label
      >
      <div class="col-lg-4">
        <Field
          id="paymentDate"
          v-model="dataset.paymentDate"
          :class="{ 'is-invalid': !dataset.paymentDate }"
          name="paymentDate"
          class="form-control"
          type="date"
        />
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";
export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      invoice: [],
      invoicedDate: "",
      paymentDate: "",
    });

    const setFieldError = (id, message) => {
      proxy.$refs.form.setFieldError(id, message);
    };

    const schema = yup.object().shape({
      InvoicedDate: yup.string().required("발행일은 필수입니다."),
      PaymentDate: yup.string().required("납부일은 필수입니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onClickOk: () => {
        const formatDate = (dateString) => new Date(dateString).toISOString();

        dataset.value.invoice[0].InvoicedDate = formatDate(
          dataset.value?.invoicedDate,
        );
        dataset.value.invoice[0].PaymentDate = formatDate(
          dataset.value?.paymentDate,
        );

        const invalidInvoicedDate = proxy.$stringUtils.isBlank(
          dataset.value.invoice[0].InvoicedDate,
        );

        const invalidPaymentDate = proxy.$stringUtils.isBlank(
          dataset.value.invoice[0].PaymentDate,
        );

        if (invalidInvoicedDate) {
          setFieldError("InvoicedDate", "발행일은 필수 입력값 입니다.");
          return false;
        }

        if (invalidPaymentDate) {
          setFieldError("PaymentDate", "납부일은 필수 입력값 입니다.");
          return false;
        }

        return dataset.value.invoice ?? [];
      },
    };

    onMounted(() => {
      dataset.value.invoice = proxy.$modalUtils.getModalData();
      dataset.value.invoicedDate =
        dataset.value.invoice[0].InvoicedDate.getDate("YYYY-MM-DD");
      dataset.value.paymentDate =
        dataset.value.invoice[0].PaymentDate.getDate("YYYY-MM-DD");
    });
    return { event, dataset, errors, schema };
  },
};
</script>
