import dayjs from "dayjs-es";
import dateUtils from "@/utils/common/dateUtils";

String.prototype.getDate = function (format) {
  if (!format) {
    format = dateUtils.format.TYPE1;
    // format = "YYYY-MM-DD HH:mm";
  }
  return dayjs(this.toString()).format(format);
};
