/**
 * 숫자 콤마 추가
 *
 * 사용 예시
 * (1234567).withComma())        // "1,234,567"
 * (1234567.89).withComma();     // "1,234,567.89"
 * (123456789.9876543).withComma(); // "123,456,789.9876543"
 * @returns
 */
Number.prototype.withComma = function () {
  // 숫자를 문자열로 변환
  let numberString = this.toString();

  // 정수 부분과 소수 부분 분리
  let parts = numberString.split(".");
  let integerPart = parts[0];
  let decimalPart = parts[1] || "";

  // 정수 부분에 콤마 추가
  let regexp = /\B(?=(\d{3})+(?!\d))/g;
  integerPart = integerPart.replace(regexp, ",");

  // 소수 부분이 있으면 정수 부분과 결합하여 반환
  return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
};

Number.prototype.isIntegerBiggerThanZero = function () {
  if (typeof this !== "number" || isNaN(this)) {
    return false;
  }

  if (this % 1 !== 0) {
    return false;
  }

  if (this < 1) {
    return false;
  }

  return true;
};

Number.prototype.convertToKoreanNumber = function () {
  var result = "";
  var digits = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
  var units = [
    "",
    "십",
    "백",
    "천",
    "만",
    "십",
    "백",
    "천",
    "억",
    "십",
    "백",
    "천",
    "조",
    "십",
    "백",
    "천",
  ];

  var numStr = this.toString(); // 문자열로 변환
  var numLen = numStr.length; // 문자열의 길이

  if (numLen > 16) {
    return 0;
  }

  for (var i = 0; i < numLen; i++) {
    var digit = parseInt(numStr.charAt(i)); // i번째 자릿수 숫자
    var unit = units[numLen - i - 1]; // i번째 자릿수 단위

    // 일의 자리인 경우에는 숫자를 그대로 한글로 변환
    if (i === numLen - 1 && digit === 1 && numLen !== 1) {
      result += "일";
    } else if (digit !== 0) {
      // 일의 자리가 아니거나 숫자가 0이 아닐 경우
      result += digits[digit] + unit;
    } else if (i === numLen - 13) {
      result += "조";
    } else if (i === numLen - 9) {
      result += "억";
    } else if (i === numLen - 5) {
      // 십만 단위에서는 '만'을 붙이지 않습니다.
      result += "만";
    }
  }

  //임시
  if (result.indexOf("억만") > 0) result = result.replace("억만", "억");
  if (result.indexOf("조만") > 0) result = result.replace("조만", "조");
  if (result.indexOf("조억") > 0) result = result.replace("조억", "조");

  return result;
};
