export default {
  name: "usergroup",
  path: "/usergroup",
  component: () => import("@/views/index"),
  redirect: { name: "userGroupList" },
  meta: {
    group: "organization",
    isView: true,
  },
  children: [
    {
      name: "userGroupList",
      path: "list",
      meta: {
        title: "사용자 그룹 목록",
        pageName: "사용자 그룹 목록",
      },
      component: () => import("@/views/usergroup/userGroupList"),
    },
    {
      name: "userGroupView",
      path: ":userGroupId",
      meta: {
        title: "사용자 그룹 상세",
        pageName: "사용자 그룹 상세",
      },
      component: () => import("@/views/usergroup/userGroupView"),
      children: [
        {
          name: "userGroupViewDetails",
          path: "details",
          meta: {
            title: "사용자 그룹 상세-개요",
          },
          component: () =>
            import("@/views/usergroup/components/userGroupViewDetails"),
        },
        {
          name: "userGroupViewUserManage",
          path: "user",
          meta: {
            title: "사용자 관리",
          },
          component: () => import("@/views/usergroup/components/userGroupViewUserManage"),
        },
      ],
    },
  ],
};
