export default {
  name: "tag",
  path: "/tag",
  meta: {
    group: "tag",
    isView: true,
  },
  redirect: { name: "tagList" },
  component: () => import("@/views/index"),
  children: [
    {
      name: "tagList",
      path: "list",
      meta: {
        title: "태그 목록",
        pageName: "태그 목록",
      },
      component: () => import("@/views/tag/tagList"),
    },
    {
      name: "tagView",
      path: ":tagId",
      meta: {
        title: "태그 상세",
        pageName: "태그 상세",
      },
      component: () => import("@/views/tag/tagView"),
      children: [
        {
          name: "tagViewDetail",
          path: "details",
          meta: {
            title: "태그 상세 - 개요",
          },
          component: () => import("@/views/tag/components/tagViewDetail"),
        },
      ],
    },
  ],
};
