<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="workgroupName"
        class="col-form-label col-lg-2"
        >워크그룹 이름</label
      >
      <div class="col-lg-10">
        <Field
          name="workgroupName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.workgroupName }"
          placeholder="워크그룹 이름을 입력해주세요"
          v-model="dataset.workGroup.workgroupName"
          maxlength="40"
        />
        <div class="invalid-feedback">
          {{ errors.workgroupName }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="workgroupDescription"
        class="col-form-label col-lg-2"
        >워크그룹 설명</label
      >
      <div class="col-lg-10">
        <Field
          as="textarea"
          rows="10"
          name="workgroupDescription"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.workgroupDescription }"
          placeholder="워크그룹 설명을 입력해 주세요"
          v-model="dataset.workGroup.workgroupDescription"
          maxlength="200"
        />
        <div class="invalid-feedback">
          {{ errors.workgroupDescription }}
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { Form, Field, useForm } from "vee-validate";
import workGroupApi from "@/api/portal/workgroupApi";
import * as yup from "yup";
export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      workGroup: {
        workgroupName: "",
        workgroupDescription: "",
      },
    });

    // validation schema
    const schema = yup.object().shape({
      workgroupName: yup
        .string()
        .min(1, "1글자 이상 40 글자 이하로 입력해주세요")
        .max(40, "1글자 이상 40글자 이하로 입력해주세요")
        .required("이름은 필수 입력값 입니다."),
      workgroupDescription: yup
        .string()
        .min(0)
        .max(200, "200글자 이하로 입력해주세요"),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (value, actions) => {
        dataset.value.workGroup.organizationId =
          proxy.$store.getters["user/activeOrganization"].organizationId;

        workGroupApi
          .createWorkGroup(dataset.value.workGroup)
          .then((response) => {
            const successMessage = "생성 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              actions,
              dataset.value.alert,
            );
          });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        dataset.value.alert.isShow = false;
      },
    };

    onMounted(() => {});
    return { event, dataset, errors, schema };
  },
};
</script>
