<template>
  <div
    class="row mb-4"
    v-show="dataset.alert.isShow"
  >
    <div
      class="alert alert-danger alert-dismissible fade mb-0"
      :class="{ show: dataset.alert.isShow }"
      role="alert"
    >
      <i class="mdi mdi-alert-circle-outline me-2"></i>
      {{ dataset.alert.message }}
      <button
        type="button"
        class="btn-close"
        v-on:click="event.onClickErrorAlertClose()"
      ></button>
    </div>
  </div>
  <div class="row mb-4">
    <span class="col-lg-2">실행 활성화여부</span>
    <div class="col-lg-10">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            name="enabled"
            :value="true"
            v-model="dataset.job.enabled"
          />예</label
        >
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label"
          ><input
            class="form-check-input"
            type="radio"
            name="enabled"
            :value="false"
            v-model="dataset.job.enabled"
          />아니오</label
        >
      </div>
    </div>
  </div>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import schedulerApi from "@/api/portal/schedulerApi";

export default {
  setup() {
    const { proxy } = getCurrentInstance();

    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      job: {},
    });

    const event = {
      onClickOk: async () => {
        let result;
        if (dataset.value.job.enabled) {
          result = await schedulerApi.updateJobExecutionEnable(
            dataset.value.job.jobId,
          );
        } else {
          result = await schedulerApi.updateJobExecutionDisable(
            dataset.value.job.jobId,
          );
        }

        const successMessage = "수정 되었습니다.";
        proxy.$modalUtils.handleApiResponse(
          result,
          successMessage,
          null,
          dataset.value.alert,
        );
      },
    };

    // 모달 데이터 초기화
    const initModalData = () => {
      dataset.value.job = proxy.$modalUtils.getModalData();
    };

    onMounted(() => {
      initModalData();
    });

    return { dataset, event };
  },
};
</script>
