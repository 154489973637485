export default {
  name: "workgroup",
  path: "/workgroup",
  component: () => import("@/views/index"),
  redirect: { name: "workGroupList" },
  meta: {
    group: "organization",
    isView: true,
  },
  children: [
    {
      name: "workGroupList",
      path: "list",
      meta: {
        title: "부서 목록",
        pageName: "워크그룹 목록",
      },
      component: () => import("@/views/workgroup/workGroupList"),
    },
    {
      name: "workGroupView",
      path: ":workGroupId",
      component: () => import("@/views/workgroup/workGroupView"),
      meta: {
        title: "부서 상세",
        pageName: "워크그룹 상세",
      },
      children: [
        {
          name: "workGroupViewDetails",
          path: "details",
          meta: {
            title: "부서 상세-개요",
          },
          component: () => import("@/views/workgroup/components/workGroupViewDetails"),
        },
        {
          name: "workGroupViewTagManage",
          path: "tag",
          meta: {
            title: "태그 관리",
          },
          component: () => import("@/views/workgroup/components/workGroupViewTagManage"),
        },
        {
          name: "workGroupViewUserManage",
          path: "user",
          meta: {
            title: "사용자 관리",
          },
          component: () => import("@/views/workgroup/components/workGroupViewUserManage"),
        },
      ],
    },
  ],
};
