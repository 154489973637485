import api from "../http";

/**
 * 프로젝트 관련 API
 */
const project = {
  getHierarchyList: (organizationId) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${organizationId}/project/hierarchy/list`,
    );
  },
  getProject: (organizationId, projectId) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${organizationId}/project/${projectId}`,
    );
  },
  getProjectList: (organizationId, data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${organizationId}/project/list`,
      data,
    );
  },
  getProjectPage: (organizationId, data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${organizationId}/project`,
      data,
    );
  },
  createProject: (organizationId, data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${organizationId}/project`,
      data,
    );
  },
  updateProject: (organizationId, projectId, data = null) => {
    return api.getPromise(
      "PUT",
      `/portal/organization/${organizationId}/project/${projectId}`,
      data,
    );
  },
  deleteProject: (organizationId, data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${organizationId}/project/delete`,
      data,
    );
  },
};

/**
 * 프로젝트 구분(그룹) 관련 API
 */
const projectGroup = {
  getProjectGroupList: (organizationId) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${organizationId}/projectgroup/list`,
    );
  },
  createProjectGroup: (organizationId, data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${organizationId}/projectgroup`,
      data,
    );
  },
  updateProjectGroup: (organizationId, codeId, data = null) => {
    return api.getPromise(
      "PUT",
      `/portal/organization/${organizationId}/projectgroup/${codeId}`,
      data,
    );
  },
  deleteMultipleProjectGroup: (organizationId, data) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${organizationId}/projectgroup/delete/multiple`,
      data,
    );
  },
  getDuplicateProjectGroup: (organizationId, codeId) => {
    const data = {
      codeId,
    };
    return api.getPromise(
      "GET",
      `/portal/organization/${organizationId}/projectgroup/duplicate`,
      data,
    );
  },
  sortProjectGroup: (organizationId, data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${organizationId}/projectgroup/sort`,
      data,
    );
  },
};

export default {
  ...project,
  ...projectGroup,
};
