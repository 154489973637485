export default {
  name: "message",
  path: "/message",
  component: () => import("@/views/index"),
  // component: () => import("@/views/message/messageList"),
  redirect: { name: "messageList" },
  meta: {
    group: "portal",
    isView: true,
  },
  children: [
    {
      name: "messageList",
      path: "list",
      meta: {
        title: "메세지 목록 조회",
        pageName: "메시지 목록",
      },
      component: () => import("@/views/message/messageList"),
    },
    {
      name: "messageView",
      path: ":messageId",
      meta: {
        title: "메세지 상세 조회",
        pageName: "메시지 상세",
      },
      component: () => import("@/views/message/messageView"),
      children: [
        {
          name: "messageViewDetail",
          path: "detail",
          meta: { title: "메세지 상세 - 개요" },
          component: () =>
            import("@/views/message/components/messageViewDetail"),
        },
      ],
    },
  ],
};
