<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <!-- prettier-ignore -->
    <div class="row mb-4">
      <label for="currentFrequencyType" class="col-form-label col-lg-2" >빈도</label>
      <div class="col-lg-10">
        <div class="form-group">
          <select class="form-select"
            v-model="dataset.currentFrequencyType"
            @change="event.resetFrequency"
          >
            <option
              v-for="(value, key) in dataset.frequencyType"
              v-bind:key="key"
              v-bind:value="key"
            >{{ value }}</option>
          </select>
        </div>
      </div>
    </div>
    <!-- prettier-ignore -->
    <div v-if="dataset.currentFrequencyType === 'minutes'" class="row mb-4" >
      <label for="minuteInterval" class="col-form-label col-lg-2" >분마다</label>
      <div class="col-lg-10">
        <div class="input-group">
          <Field 
            type="text"
            name="minuteInterval"
            v-model="dataset.editorData.minuteInterval"
            class="form-control" :class="{ 'is-invalid': errors.minuteInterval }"
            maxlength="2" placeholder="값을 입력해주세요"
          />
          <div class="invalid-feedback">{{ errors.minuteInterval }}</div>
        </div>
        <small class="form-text text-muted w-100">0부터 59까지 숫자만 입력가능합니다.</small>
      </div>
    </div>
    <!-- prettier-ignore -->
    <template v-if="dataset.currentFrequencyType === 'hourly'">
      <div class="row mb-4">
        <label for="hourInterval" class="col-form-label col-lg-2" >시간마다</label>
        <div class="col-lg-10">
          <div class="input-group">
            <Field 
              type="text"
              name="hourInterval"
              v-model="dataset.editorData.hourInterval"
              class="form-control" :class="{ 'is-invalid': errors.hourInterval }"
              maxlength="2" placeholder="값을 입력해주세요"
            />
            <div class="invalid-feedback">{{ errors.hourInterval }}</div>
            <small class="form-text text-muted w-100">0부터 23까지 숫자만 입력가능합니다.</small>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label for="minutes" class="col-form-label col-lg-2" >분</label>
        <div class="col-lg-10">
          <Field 
              type="text"
              name="minutes"
              v-model="dataset.editorData.minutes"
              class="form-control" :class="{ 'is-invalid': errors.minutes }"
              maxlength="2" placeholder="값을 입력해주세요"
            />
            <div class="invalid-feedback">{{ errors.minutes }}</div>
          <small class="form-text text-muted w-100" >0부터 59까지 숫자만 입력가능합니다.</small>
        </div>
      </div>
    </template>
    <!-- prettier-ignore -->
    <template v-if="dataset.currentFrequencyType === 'daily'">
      <div class="row mb-4">
        <label for="dayInterval" class="col-form-label col-lg-2" >일마다</label>
        <div class="col-lg-10">
          <div class="input-group">
            <Field 
              type="text"
              name="dayInterval"
              v-model="dataset.editorData.dayInterval"
              class="form-control" :class="{ 'is-invalid': errors.dayInterval }"
              maxlength="2" placeholder="값을 입력해주세요"
            />
            <div class="invalid-feedback">{{ errors.dayInterval }}</div>
            <small class="form-text text-muted w-100">1부터 31까지 숫자만 입력가능합니다.</small>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label for="dateTime" class="col-form-label col-lg-2" >시간</label>
        <div class="col-lg-10">
          <div class="input-group">
            <input
              type="time"
              name="time"
              id="time-input"
              class="form-control"
              @input="event.setDateTime"
              :value="dataset.dateTime"
            />
          </div>
        </div>
      </div>
    </template>
    <!-- prettier-ignore -->
    <template v-if="dataset.currentFrequencyType === 'weekly'">
      <div class="row mb-4">
        <label
          for="days" class="col-form-label col-lg-2" >요일마다</label>
        <div class="col-lg-10">
          <div class="btn-group">
            <template
              v-for="(value, key) in weekMapping"
              v-bind:key="key"
            >
              <input
                type="checkbox"
                class="btn-check"
                :id="key"
                :value="key"
                @click="event.onClickDayOfWeek"
                autocomplete="off"
                :checked="dataset.editorData.days?.includes(key)"
              />
              <!-- :checked="dataset.editorData.days.includes(key)" -->
              <label
                class="btn btn-outline-primary"
                :for="key"
                >{{ key }}</label
              >
            </template>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label for="dateTime" class="col-form-label col-lg-2" >시간</label>
        <div class="col-lg-10">
          <div class="input-group">
            <input
              type="time"
              id="time-input"
              class="form-control"
              name="time"
              @input="event.setDateTime"
              :value="dataset.dateTime"
            />
          </div>
        </div>
      </div>
    </template>
    <!-- prettier-ignore -->
    <template v-if="dataset.currentFrequencyType === 'monthly'">
      <div class="row mb-4">
        <label for="month" class="col-form-label col-lg-2" >월마다</label>
        <div class="col-lg-10">
          <div class="btn-group">
            <template
              v-for="(value, key) in monthMapping"
              v-bind:key="key"
            >
              <input
                type="checkbox"
                class="btn-check"
                :id="key"
                :value="key"
                @click="event.onClickDayOfMonth"
                autocomplete="off"
                :checked="dataset.editorData.month?.includes(key)"
              />
              <label class="btn btn-outline-primary" :for="key" >{{ key }}</label>
            </template>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label
          for="day"
          class="col-form-label col-lg-2"
          >일</label
        >
        <div class="col-lg-10">
          <input
            type="text"
            v-model="dataset.editorData.day"
            class="form-control"
          />
        </div>
      </div>
      <div class="row mb-4">
        <label
          for="dateTime"
          class="col-form-label col-lg-2"
          >시간</label
        >
        <div class="col-lg-10">
          <input
            type="time"
            id="time-input"
            class="form-control"
            name="time"
            @input="event.setDateTime"
            :value="dataset.dateTime"
          />
        </div>
      </div>
    </template>
    <template v-if="dataset.currentFrequencyType === 'advanced'">
      <div class="row mb-4">
        <label
          for="cronExpression"
          class="col-form-label col-lg-2"
          >Cron 표현식</label
        >
        <div class="col-lg-10">
          <input
            type="text"
            v-model="dataset.editorData.cronExpression"
            class="form-control"
            :class="{
              'is-invalid': !dataset.editorData.isValid,
              'is-valid': dataset.editorData.isValid,
            }"
          />
          <div
            :class="{
              'invalid-feedback': !dataset.editorData.isValid,
              'valid-feedback': dataset.editorData.isValid,
            }"
          >
            {{ dataset.editorData.validMessage }}
          </div>
        </div>
      </div>
    </template>
    <div class="row mb-4">
      <span class="col-lg-2">Cron 표현식 확인</span>
      <div class="col-lg-10">
        {{ modelValue }}
      </div>
    </div>
    <div class="row mb-4">
      <span class="col-lg-2">Cron 표현식 설명</span>
      <div class="col-lg-10">
        {{ explanation }}
      </div>
    </div>
    <div
      class="row mb-4"
      v-if="dataset.currentFrequencyType === 'advanced'"
    >
      <span class="col-lg-2">Cron 표현식 예시</span>
      <div class="col-lg-10">
        <table class="table table-sm m-0">
          <colgroup>
            <col class="w-40" />
            <col class="w-60" />
          </colgroup>
          <thead class="table-secondary">
            <tr>
              <th>Cron 표현식</th>
              <th>의미</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0 0 12 * * ?</td>
              <td>매일 12시 정각에 실행</td>
            </tr>
            <tr>
              <td>0 15 10 ? * *</td>
              <td>매일 10시 15분에 실행</td>
            </tr>
            <tr>
              <td>0 * 14 * * ?</td>
              <td>매일 2시부터 2시 59분까지 매분 실행</td>
            </tr>
            <tr>
              <td>0 0/5 14 * * ?</td>
              <td>매일 2시부터 2시 59분까지 5분 마다 실행</td>
            </tr>
            <tr>
              <td>0 0/5 14,18 * * ?</td>
              <td>
                매일 2시부터 2시 59분까지 5분 마다 그리고<br />
                매일 6시부터 6시 59분까지 5분 마다 실행
              </td>
            </tr>
            <tr>
              <td>0 0-5 14 * * ?</td>
              <td>매일 2시부터 2시 5분까지 매분 마다 실행</td>
            </tr>
            <tr>
              <td>0 10,44 14 ? 3 WED</td>
              <td>3월 매주 수요일 2시 10분과 2시 44분에 실행</td>
            </tr>
            <tr>
              <td>0 15 10 ? * MON-FRI</td>
              <td>매주 월요일부터 금요일 10시 15분에 실행</td>
            </tr>
            <tr>
              <td>0 15 10 15 * ?</td>
              <td>매달 15일 10시 15분에 실행</td>
            </tr>
            <tr>
              <td>0 0 12 1/5 * ?</td>
              <td>매달 1일부터 5일 간격으로 12시에 실행</td>
            </tr>
            <tr>
              <td>0 11 11 11 11 ?</td>
              <td>매년 11월 11일 11 시 11분에 실행</td>
            </tr>
            <tr>
              <td>0 15 10 * * ? 2024</td>
              <td>2024년도에 한하여 매일 10시 15분에 실행</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <pre>{{ dataset }}</pre> -->
  </Form>
</template>

<script>
// https://karoletrych.github.io/vue-cron-editor/

import { getCurrentInstance, ref, onMounted, computed, watch } from "vue";
import * as cronValidator from "cron-validator";
import * as cronstrue from "cronstrue/i18n";
import { toCronstrueLocale } from "./core/i18n";

import { parseExpression } from "./core/parseExpression";
import { buildExpression, isStateValid } from "./core/buildExpression";
import { monthMapping } from "./core/alias/monthAlias";
import { weekMapping } from "./core/alias/weekAlias";

import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";

export default {
  components: { Form, Field },
  props: {
    modelValue: { type: String, default: "*/1 * * * *" },
    preserveStateOnSwitchToAdvanced: { type: Boolean, default: false },
    locale: { type: String, default: "ko" },
    customLocales: { type: Object, default: null },
    cronSyntax: {
      type: String,
      default: "quartz", // basic(리눅스/유닉스 크론 표현식) or quartz(스프링 스케줄러/쿼츠 크론 표현식)
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();

    const initialData = ref({
      minutes: {
        type: "minutes",
        minuteInterval: 1, // {{minuteInterval}} 분마다 반복
      },
      hourly: {
        type: "hourly",
        hourInterval: 1, // {{hourInterval}} 시간마다 반복
        minutes: 0, // {{minutes}} 분
      },
      daily: {
        type: "daily",
        dayInterval: 1, // {{dayInterval}} 일마다 반복
        hours: 0, // {{hours}} 시간
        minutes: 0, // {{minutes}} 분
      },
      weekly: {
        type: "weekly",
        minutes: 0,
        hours: 0,
        days: [],
      },
      monthly: {
        type: "monthly",
        hours: 0,
        minutes: 0,
        day: 1,
        month: [],
      },
      advanced: {
        type: "advanced",
        cronExpression: "0 0 0 1 */1 ?",
        isValid: true,
        validMessage: "",
      },
    });

    const dataset = ref({
      innerValue: "*/1 * * * *",
      editorData: Object.assign({}, initialData.value.minutes),
      currentFrequencyType: "minutes", // 빈도 타입
      i18n: null,
      dateTime: computed(() => {
        const hours = dataset.value.editorData.hours
          ?.toString()
          .padStart(2, "0");
        const minutes = dataset.value.editorData.minutes
          ?.toString()
          .padStart(2, "0");
        return `${hours}:${minutes}`;
      }),
      frequencyType: {
        minutes: "매분",
        hourly: "매시",
        daily: "매일",
        weekly: "매주",
        monthly: "매월",
        advanced: "직접입력 (Cron 표현식)",
      },
    });

    // validation schema
    const schema = yup.object().shape({
      minuteInterval: yup
        .number()
        .required("필수 입력값 입니다.")
        .typeError("0부터 59까지 숫자만 입력가능합니다.")
        .min(0, "0부터 59까지 숫자만 입력가능합니다.")
        .max(59, "0부터 59까지 숫자만 입력가능합니다."),
      hourInterval: yup
        .number()
        .required("필수 입력값 입니다.")
        .typeError("0부터 23까지 숫자만 입력가능합니다.")
        .min(0, "0부터 23까지 숫자만 입력가능합니다.")
        .max(23, "0부터 23까지 숫자만 입력가능합니다."),
      minutes: yup
        .number()
        .required("필수 입력값 입니다.")
        .typeError("0부터 59까지 숫자만 입력가능합니다.")
        .min(0, "0부터 59까지 숫자만 입력가능합니다.")
        .max(59, "0부터 59까지 숫자만 입력가능합니다."),
      dayInterval: yup
        .number()
        .required("필수 입력값 입니다.")
        .typeError("1부터 31까지 숫자만 입력가능합니다.")
        .min(1, "1부터 31까지 숫자만 입력가능합니다.")
        .max(31, "1부터 31까지 숫자만 입력가능합니다."),
      hours: yup
        .number()
        .required("필수 입력값 입니다.")
        .typeError("0부터 23까지 숫자만 입력가능합니다.")
        .min(0, "0부터 23까지 숫자만 입력가능합니다.")
        .max(23, "0부터 23까지 숫자만 입력가능합니다."),
    });

    //  handleSubmit, setFieldError
    const { errors } = useForm({
      validationSchema: schema,
    });

    const explanation = computed(() => {
      if (!dataset.value.innerValue) return "";

      const cronstrueLocale = toCronstrueLocale(props.locale);
      return cronstrue.toString(dataset.value.innerValue, {
        locale: cronstrueLocale,
      });
    });

    /**
     * 데이터 수정
     */
    watch(
      () => dataset.value.editorData,
      (changedData) => {
        console.log(
          "watch=dataset.value.editorData",
          dataset.value.editorData,
          changedData,
        );
        const nonReactiveData = JSON.parse(JSON.stringify(changedData));
        updateCronExpression(nonReactiveData);
      },
      { deep: true },
    );

    watch(
      () => props.modelValue,
      () => {
        console.log(
          "watch=props.modelValue",
          props.modelValue,
          dataset.value.innerValue,
        );
        if (props.modelValue === dataset.value.innerValue) {
          return;
        }
        loadDataFromExpression();
      },
    );

    /**
     * cront syntax가 변경된 경우 basic or quartz
     */
    watch(
      () => props.cronSyntax,
      () => {
        console.log("watch=props.cronSyntax", props.cronSyntax);
        updateCronExpression(JSON.parse(JSON.stringify(this.editorData)));
      },
    );

    /**
     * Cron 표현식으로 type 판단
     */
    const loadDataFromExpression = () => {
      const parsingData = parseExpression(props.modelValue);
      console.log("parsingData", parsingData);
      if (
        !Object.prototype.hasOwnProperty.call(
          dataset.value.frequencyType,
          parsingData.type,
        )
      ) {
        dataset.value.editorData = {
          type: "advanced",
          cronExpression: props.modelValue,
        };
        dataset.value.currentFrequencyType = "advanced";
        return;
      }
      dataset.value.editorData = { ...parsingData };
      dataset.value.currentFrequencyType = parsingData.type;
    };

    const updateCronExpression = (state) => {
      console.log("updateCronExpression", state);
      if (!isStateValid(state)) {
        dataset.value.innerValue = null;
        emit("updateCronExpression", null);
        return;
      }

      const cronExpression = buildExpression(props.cronSyntax, {
        ...state,
      });

      if (!isValidExpression(cronExpression)) {
        console.log("Expression is not Valid", cronExpression);
        console.warn("Expression is not Valid", cronExpression);
        dataset.value.innerValue = null;
        emit("updateCronExpression", null);

        let message = "";
        if (cronExpression === "") {
          message = "Cron표현식을 입력해 주세요.";
        } else {
          message = "Cron표현식을 잘못 입력하였습니다.";
        }

        if (state.type === "advanced") {
          dataset.value.editorData.isValid = false;
          dataset.value.editorData.validMessage = message;
        }
        emit("isValidExpression", false, message);
        return;
      }

      if (state.type === "advanced") {
        dataset.value.editorData.isValid = true;
        dataset.value.editorData.validMessage =
          "Cron표현식을 올바르게 입력하였습니다.";
      }

      dataset.value.innerValue = cronExpression;
      emit("updateCronExpression", cronExpression);
      emit("isValidExpression", true);
    };

    const isValidExpression = (cronExpression) => {
      let options =
        props.cronSyntax == "quartz"
          ? {
              seconds: true,
              allowBlankDay: true,
              alias: true,
            }
          : undefined;
      return cronValidator.isValidCron(cronExpression, options);
    };

    const event = {
      onSubmit: () => {
        console.log("onSubmit");
      },
      resetFrequency: () => {
        console.log("event.resetFrequency");
        const frequency = dataset.value.currentFrequencyType;
        if (props.preserveStateOnSwitchToAdvanced && frequency === "advanced") {
          dataset.value.editorData = {
            type: "advanced",
            cronExpression: dataset.value.innerValue,
          };
          return;
        }

        dataset.value.editorData = Object.assign(
          {},
          initialData.value[frequency],
        );
        updateCronExpression(initialData.value[frequency]);
      },
      setDateTime: (event) => {
        const time = event.target.value;
        const [hours, minutes] = time.split(":");
        dataset.value.editorData.hours = hours;
        dataset.value.editorData.minutes = minutes;
      },
      onClickDayOfWeek: (event) => {
        const dayOfWeek = event.target.value;
        dataset.value.editorData.days = proxy.$arrayUtils.addOrRemoveItem(
          dataset.value.editorData.days,
          dayOfWeek,
        );
      },
      onClickDayOfMonth: (event) => {
        const dayOfMonth = event.target.value;
        dataset.value.editorData.month = proxy.$arrayUtils.addOrRemoveItem(
          dataset.value.editorData.month,
          dayOfMonth,
        );
      },
    };

    onMounted(() => {
      console.log("onMounted=props.modelValue", props.modelValue);
      dataset.value.innerValue = props.modelValue;
      loadDataFromExpression();
    });

    return {
      dataset,
      event,
      explanation,
      isValidExpression,
      weekMapping,
      monthMapping,
      schema,
      errors,
    };
  },
};
</script>
