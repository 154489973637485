import api from "../http";

export default {
  getUserGroup: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/usergroup/${data.usergroupId}`,
      data,
    );
  },
  getUserGroupPage: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/usergroup`,
      data,
    );
  },
  getUserGroupList: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/usergroup/list`,
      data,
    );
  },
  getUserGroupMemberPage: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/usergroup/${data.usergroupId}/member`,
      data,
    );
  },
  getUserGroupMemberList: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/usergroup/${data.usergroupId}/member/list`,
      data,
    );
  },
  createUserGroup: (data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${data.organizationId}/usergroup`,
      data,
    );
  },
  updateUserGroup: (data = null) => {
    return api.getPromise(
      "PUT",
      `/portal/organization/${data.organizationId}/usergroup/${data.usergroupId}`,
      data,
    );
  },
  deleteUserGroup: (data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${data.organizationId}/usergroup/delete`,
      data,
    );
  },
  deleteMultipleUserGroup: (organizationId = null, data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${organizationId}/usergroup/delete/multiple`,
      data,
    );
  },
  updateUserGroupMemberInfo: (data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${data.organizationId}/usergroup/${data.usergroupId}/member/manage`,
      data,
    );
  },
};
