<template>
  <button
    type="button"
    class="btn btn-light ms-1"
    v-clipboard:copy="text"
    v-clipboard:success="event.onCopy"
    v-clipboard:error="event.onCopyError"
  >
    <i class="bx bx-copy align-middle"></i> 복사
  </button>
</template>

<script>
import { onMounted, getCurrentInstance } from "vue";
export default {
  props: ["text"],
  setup(props) {
    const { proxy } = getCurrentInstance();

    const event = {
      onCopy: () => {
        proxy.$awn.success("복사되었습니다.");
      },
      onCopyError: () => {
        proxy.$awn.alert("복사 중 에러가 발생하였습니다.");
      },
    };

    onMounted(() => {
      console.log(props.id);
    });

    return {
      event,
    };
  },
};
</script>
