import api from "../http";
export default {
  getCloudServiceProviderList: (data = null) => {
    return api.getPromise("GET", "/portal/cloudserviceprovider/list", data);
  },

  createCloudServiceProvider: (data = null) => {
    return api.getPromise("POST", "/portal/cloudserviceprovider", data);
  },
};
