<template>
  <div>
    <div class="table-responsive">
      <table class="table">
        <colgroup>
          <col class="w-20" />
          <col class="w-30" />
          <col class="w-20" />
          <col class="w-30" />
        </colgroup>
        <tbody>
          <tr>
            <th scope="col">프로젝트</th>
            <td scope="col">
              {{ dataset.chartItems.ProjectName }} ({{
                dataset.chartItems.ProjectId
              }})
            </td>
            <th scope="col">리전</th>
            <td>
              {{ dataset.regionType[dataset.chartItems.Region]?.codeName }}
            </td>
          </tr>
          <tr>
            <th>감지 시각</th>
            <td>
              {{
                dayjs(dataset.chartItems.DetectedDate).format(
                  "YYYY-MM-DD HH:mm",
                ) ?? "-"
              }}
            </td>
            <th>이상 비용 감지 금액</th>
            <td>
              {{
                typeof dataset.chartItems.DetectedCost === "number"
                  ? Math.round(dataset.chartItems.DetectedCost).withComma()
                  : 0
              }}
              원
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card">
      <div
        v-if="dataset.isChartLoading"
        class="loading-spinner"
      ></div>
      <div
        class="card-body m-2"
        v-if="!dataset.isChartLoading"
      >
        <apexchart
          type="line"
          height="350"
          ref="anomalyDetectionRef"
          v-bind:options="anomalyDetection.chartOptions"
          v-bind:series="anomalyDetection.series"
        ></apexchart>
      </div>
    </div>
    <div class="d-flex justify-content-start align-items-center gap-2">
      <span
        class="form-check-label text-truncate fw-bold d-flex align-items-center gap-1 mb-2"
      >
        <li class="bx bx-list-ul fs-5"></li>
        이상 비용 서비스 Top5</span
      >
    </div>
    <div>
      <!-- style="height: 500px; overflow-y: auto; overflow-x: hidden" -->
      <div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="bd-highlight"></div>
              <div class="card-body">
                <div>
                  <div class="col-sm-auto">
                    총
                    <span class="fw-bold">{{
                      dataset.chartItems.Reason?.length
                    }}</span
                    >건
                  </div>
                  <div class="table-responsive mt-2">
                    <table class="table mb-0 table-hover">
                      <thead class="table-secondary">
                        <tr>
                          <th>서비스</th>
                          <th class="text-end">
                            감지 시각 1시간 전의 금액(원)
                          </th>
                          <th class="text-end">감지 시각 금액(원)</th>
                          <th class="text-end">금액 차액(원)</th>
                          <th class="text-end">금액 증가율(%)</th>
                        </tr>
                      </thead>
                      <tbody v-if="dataset.chartItems.Reason?.length > 0">
                        <tr
                          v-for="item in dataset.chartItems.Reason"
                          v-bind:key="item"
                        >
                          <td>{{ item.Service }}</td>
                          <td class="text-end">
                            &#8361;{{ item.PrePointCost?.withComma() }}
                          </td>
                          <td class="text-end">
                            &#8361;{{ item.DetectionPointCost?.withComma() }}
                          </td>
                          <td class="text-end">
                            &#8361;{{ item.DiffCost?.withComma() }}
                          </td>
                          <td class="text-end">
                            <b>{{ item.IncreaseRate?.withComma() + "%" }}</b>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td
                            colspan="5"
                            class="text-center"
                          >
                            데이터가 존재하지 않습니다.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, ref } from "vue";
import anomalyApi from "@/api/cost/anomalyApi";
import codegroupApi from "@/api/portal/codegroupApi";
import dayjs from "dayjs-es";

export default {
  setup() {
    const { proxy } = getCurrentInstance();

    const dataset = ref({
      pageInfo: proxy.$pageUtils.getPageInfo(),
      chartItems: [],
      regionType: {},
      isChartLoading: true,
      projectSummary: [],
      projectShow: false,
      isIncrease: (riseRate) => {
        const _riseRate = riseRate.replace("%", "");
        return _riseRate >= 0;
      },
      // sliceData: 50,
    });

    const anomalyDetectionRef = ref(null);

    const anomalyDetection = {
      chartOptions: {
        chart: {
          height: 350,
          // zoom: {
          //   enabled: true,
          //   autoScaleYaxis: true,
          //   type: "x",
          // },
          toolbar: {
            tools: {
              download: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [5, 5],
          curve: "straight",
          dashArray: [5, 5],
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            show: true,
            formatter: function (value) {
              return value?.withComma();
            },
          },
          // max: function (max) {
          //   return 100 < max ? max + 100 : 100;
          // },
          tickAmount: 10,
        },
        tooltip: {
          y: [
            {
              formatter: function (val) {
                return Math.round(val).withComma() + "원";
              },
            },
            {
              formatter: function (val) {
                return Math.round(val).withComma() + "원";
              },
            },
          ],
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
      series: [],
    };

    const setChartData = () => {
      const chartData = dataset.value.chartItems.Data;
      // const detectedChartData = chartData.filter((item) => {
      //   return item.IsDetected;
      // });

      // const startDay = dayjs(detectedChartData[0].Date).startOf("day");
      // const startAddOneDay = startDay.add(1, "day");

      // const filterData = chartData.filter((item) => {
      //   return dayjs(item.Date).isBefore(startAddOneDay);
      // });

      // const sliceData = filterData.slice(
      //   filterData.length - dataset.value.sliceData,
      //   filterData.length,
      // );

      anomalyDetection.chartOptions = {
        ...anomalyDetection.chartOptions,
        xaxis: {
          categories: chartData.map((item) => {
            return dayjs(item.Date).format("MM-DD HH:mm");
          }),
          tickAmount: chartData?.length > 0 ? chartData.length / 4 : 1,
        },
        annotations: {
          xaxis: chartData
            .filter((item) => {
              return item.IsDetected;
            })
            .map((item) => {
              return {
                x: dayjs(item.Date).format("MM-DD HH:mm"),
                borderColor: "#FF4560",
                strokeDashArray: 0,
                label: {
                  borderColor: "#FF4560",
                  style: {
                    color: "#fff",
                    background: "#FF4560",
                  },
                  text: "감지 시각",
                  orientation: "horizontal",
                },
              };
            }),
        },
      };

      anomalyDetection.series = [
        {
          name: "실 데이터",
          data: chartData.map((item) => {
            return item.Data;
          }),
        },
        {
          name: "AI 예측 금액",
          data: chartData.map((item) => {
            return item.Prediction;
          }),
        },
      ];

      dataset.value.isChartLoading = false;
    };

    const initData = (uid) => {
      Promise.all([fnCodeMapByCodeGroupId(), fnGetAnomalyDetail(uid)]);
    };

    const fnGetAnomalyDetail = async (uid) => {
      const result = await anomalyApi.getAnomalyDetail(uid);
      if (result.isSuccessful) {
        dataset.value.chartItems = result.resultData;
        setChartData();
      }
    };

    const fnCodeMapByCodeGroupId = async () => {
      const result = await codegroupApi.getCodeMap("NHN_REGION_TYPE");
      if (result.isSuccessful) {
        dataset.value.regionType = result.resultData;
      }
    };

    onMounted(() => {
      const modalData = proxy.$modalUtils.getModalData();
      if (modalData.UID === undefined) {
        return false;
      }
      initData(modalData.UID);
      proxy.$emit(
        "footerButtonType",
        proxy.$modalUtils.modalFooterBtnType.TYPE2,
      );
    });

    return { dataset, anomalyDetection, dayjs, anomalyDetectionRef };
  },
};
</script>
<style scoped>
.cost-decrease-font {
  font-weight: bolder;
  color: #4ca3e4;
}
.cost-increase-font {
  font-weight: bolder;
  color: rgba(255, 0, 0, 0.89);
}
</style>
