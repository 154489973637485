<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="validationSchema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      v-for="(_item, _index) in dataset.invoiceList"
      :key="_index"
      class="col-12"
    >
      <div class="d-flex justify-content-between">
        <h5 class="card-title mb-8">프로젝트 : {{ _item.ProjectName }}</h5>
        <div class="d-flex justify-content-sm-end gap-2">
          <button
            type="button"
            class="btn btn-primary btn-sm"
            v-on:click.prevent="event.addRow(_index)"
          >
            <i class="bx bx-plus align-middle"></i> 할인 행 추가
          </button>
        </div>
      </div>
      <div>
        <div class="table-responsive mt-2">
          <table
            class="table table-bordered table-sm align-middle table-invoice"
          >
            <colgroup>
              <col class="w-20" />
              <col class="w-10" />
              <col class="w-5" />
              <col class="w-10" />
              <col class="w-5" />
            </colgroup>
            <thead class="table-secondary">
              <tr>
                <th class="text-center">할인 이름</th>
                <th class="text-center">과금 방식</th>
                <th class="text-center">수량</th>
                <th class="text-center">비용 / 비율</th>
                <th class="text-center">삭제</th>
              </tr>
            </thead>
            <tbody
              v-if="_item.DiscountService && _item.DiscountService.length > 0"
            >
              <tr
                v-for="(item, index) in _item.DiscountService"
                :key="index"
              >
                <td>
                  <Field
                    type="text"
                    :name="`invoiceList[${_index}].DiscountService[${index}].Name`"
                    :class="{
                      'is-invalid':
                        errors[
                          `invoiceList[${_index}].DiscountService[${index}].Name`
                        ],
                    }"
                    class="form-control"
                    maxlength="40"
                    v-model="item.Name"
                  />

                  <div class="invalid-feedback">
                    {{
                      errors[
                        `invoiceList[${_index}].DiscountService[${index}].Name`
                      ]
                    }}
                  </div>
                </td>
                <td>
                  <Field
                    as="select"
                    :name="`invoiceList[${_index}].DiscountService[${index}].PricingUnit`"
                    :class="{
                      'is-invalid':
                        errors[
                          `invoiceList[${_index}].DiscountService[${index}].PricingUnit`
                        ],
                    }"
                    class="form-select"
                    v-model="item.PricingUnit"
                  >
                    <option
                      value=""
                      disabled
                    >
                      선택
                    </option>
                    <option
                      v-for="unit in dataset.priceUnitList"
                      :key="unit.value"
                      :value="unit.value"
                    >
                      {{ unit.name }}
                    </option>
                  </Field>
                  <div class="invalid-feedback">
                    {{
                      errors[
                        `invoiceList[${_index}].DiscountService[${index}].PricingUnit`
                      ]
                    }}
                  </div>
                </td>
                <td>
                  <Field
                    type="number"
                    :name="`invoiceList[${_index}].DiscountService[${index}].Amount`"
                    :class="{
                      'is-invalid':
                        errors[
                          `invoiceList[${_index}].DiscountService[${index}].Amount`
                        ],
                    }"
                    class="text-end form-control"
                    v-model="item.Amount"
                  />
                  <div class="invalid-feedback">
                    {{
                      errors[
                        `invoiceList[${_index}].DiscountService[${index}].Amount`
                      ]
                    }}
                  </div>
                </td>
                <td>
                  <div class="input-group text-end">
                    <Field
                      type="number"
                      :name="`invoiceList[${_index}].DiscountService[${index}].Value`"
                      :class="{
                        'is-invalid':
                          errors[
                            `invoiceList[${_index}].DiscountService[${index}].Value`
                          ],
                      }"
                      class="form-control text-end"
                      v-model="item.Value"
                    />
                    <span
                      v-if="item.PricingUnit === 'VALUE'"
                      class="input-group-text"
                      >원</span
                    >
                    <span
                      v-if="item.PricingUnit === 'PCT'"
                      class="input-group-text"
                      >%</span
                    >
                    <div class="invalid-feedback">
                      {{
                        errors[
                          `invoiceList[${_index}].DiscountService[${index}].Value`
                        ]
                      }}
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <button
                    class="btn btn-danger btn-m"
                    v-on:click.prevent="event.deleteRow(_index, index)"
                  >
                    삭제
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td
                  colspan="5"
                  class="text-center"
                >
                  내역이 없습니다.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <br />
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";
export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      priceUnitList: [
        { value: "PCT", name: "비율" },
        { value: "VALUE", name: "고정금액" },
      ],
      alert: {
        isShow: false,
        message: "",
      },
      invoiceList: [],
    });

    const validationSchema = yup.object().shape({
      invoiceList: yup.array().of(
        yup.object().shape({
          DiscountService: yup.array().of(
            yup.object().shape({
              Name: yup
                .string()
                .max(40, "40자 이하로 입력해 주세요.")
                .required("할인명을 입력해주세요."),
              PricingUnit: yup.string().required("과금 방식을 선택해주세요."),
              Amount: yup
                .number()
                .typeError("수량을 입력해주세요.")
                .required("수량을 입력해주세요.")
                .min(0, "0 이상의 수를 입력해 주세요."),
              Value: yup
                .number()
                .typeError("비용/비율을 입력해주세요.")
                .required("비용/비율을 입력해주세요.")
                .min(0, "0 이상의 수를 입력해 주세요."),
            }),
          ),
        }),
      ),
    });

    const { errors } = useForm({
      validationSchema: validationSchema,
    });
    ``;
    const event = {
      onClickOk: () => {
        const validateResult = proxy.$refs.form.validate();

        return validateResult.then((result) => {
          if (!result.valid) {
            // console.log("valid 실패");
            return false;
          } else {
            // console.log("valid 통과");

            // 데이터 수정 로직
            dataset.value.invoiceList.forEach((result) => {
              result.DiscountService.forEach((service) => {
                service.Cost = 0;
              });
            });
            return dataset.value.invoiceList ?? [];
          }
        });
      },
      addRow: (_index) => {
        dataset.value.invoiceList[_index].DiscountService.push({
          Name: "",
          PricingUnit: "", // 필수
          Amount: "1",
          Cost: 0, // Back-End에서 계산
          Value: "1",
          ApplyLevel: "Project", // 필수
        });

        proxy.$refs.form.validate();
      },
      deleteRow: (_index, index) => {
        return dataset.value.invoiceList[_index].DiscountService.splice(
          index,
          1,
        );
      },
    };

    const initModalData = () => {
      dataset.value.invoiceList = proxy.$modalUtils.getModalData();
    };

    onMounted(() => {
      initModalData();
    });
    return {
      event,
      dataset,
      validationSchema,
      errors,
    };
  },
};
</script>

<style scoped>
table.table-invoice {
  margin-top: 0;
}

table.table-invoice tbody tr.invoice-summary td {
  background-color: #eff9ff;
  font-weight: bold;
}
</style>
