<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>

    <div class="row mb-4">
      <label
        for="tagName"
        class="col-form-label col-lg-2"
        >태그 이름</label
      >
      <div class="col-lg-10">
        <Field
          name="tagName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.tagName }"
          placeholder="계정 이름을 입력해 주세요."
          v-model="dataset.tag.tagName"
        />
        <div class="invalid-feedback">{{ errors.tagName }}</div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="tagDescription"
        class="col-form-label col-lg-2"
        >태그 설명</label
      >
      <div class="col-lg-10">
        <Field
          as="textarea"
          rows="5"
          name="tagDescription"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.tagDescription }"
          placeholder="태그 설명을 입력해 주세요."
          v-model="dataset.tag.tagDescription"
        />
        <div class="invalid-feedback">
          {{ errors.tagDescription }}
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { Form, Field, useForm } from "vee-validate";
import tagApi from "@/api/portal/tagApi";
import * as yup from "yup";
export default {
  components: { Form, Field },
  // setup(props, { emit }) {
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      providerList: [],
      accountTypeList: [],
      credentialFieldList: null,

      tag: {
        tagId: "",
        tagName: "",
        tagDescription: "",
      },
      ui: {
        modal: {
          id: "modalTagWrite",
          title: "태그 등록",
        },
      },
    });

    // validation schema
    const schema = yup.object().shape({
      tagName: yup
        .string()
        .max(40, "입력할 수 있는 글자수를 초과했습니다.")
        .required("태그이름은 필수 입력값 입니다."),
      tagDescription: yup
        .string()
        .max(200, "입력할 수 있는 글자수를 초과했습니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (values, actions) => {
        tagApi.createTag(dataset.value.tag).then((response) => {
          const successMessage = "생성 되었습니다.";
          proxy.$modalUtils.handleApiResponse(
            response,
            successMessage,
            actions,
            dataset.value.alert,
          );
        });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickOpenModal: () => {
        $(`#${dataset.value.ui.modal.id}`).modal("show");
      },
      onClickCloseModal: () => {
        proxy.$refs.form.resetForm(); // form init
        proxy.event.onClickErrorAlertClose(); // alert init
        $(`#${dataset.value.ui.modal.id}`).modal("hide"); // modal close
      },
      onClickErrorAlertClose: () => {
        // 필드 에러 외 에러메세지 close
        dataset.value.alert.isShow = false;
      },
      onClickProviderNameShowAccountType: () => {},
      onClickAccountTypeShowParameter: () => {},
    };

    onMounted(() => {});
    return { dataset, schema, event, errors };
  },
};
</script>
