import api from "../http";
export default {
  getTagList: (data = null) => {
    return api.getPromise("GET", `/portal/tag`, data);
  },
  getTagAllList: () => {
    return api.getPromise("GET", `/portal/tag/list`);
  },
  getTagDetail: (tagId = null, data = null) => {
    return api.getPromise("GET", `/portal/tag/${tagId}`, data);
  },
  createTag: (data = null) => {
    return api.getPromise("POST", "/portal/tag", data);
  },
  updateTag: (tagId = null, data = null) => {
    return api.getPromise("PUT", `/portal/tag/${tagId}`, data);
  },
  deleteTag: (data = null) => {
    return api.getPromise("POST", "/portal/tag/delete", data);
  },
  multipleDeleteTag: (data = null) => {
    return api.getPromise("POST", "/portal/tag/delete/multiple", data);
  },
};
