<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div class="d-flex justify-content-sm-end gap-2">
      <div class="me-auto bd-highlight d-flex gap-2"></div>
      <div>
        <div class="input-group">
          <select
            class="form-select form-select-sm w-125px"
            v-model="dataset.search.keywordType"
          >
            <option value="ResourceName">리소스 이름</option>
          </select>
          <input
            type="text"
            class="form-control form-control-sm w-200px"
            placeholder="검색어를 입력하세요"
            v-model="dataset.search.keyword"
          />
        </div>
      </div>
    </div>
    <div class="mt-2">
      <table class="table table-bordered table-sm table-invoice">
        <colgroup>
          <col class="w-5" />
          <col class="w-10" />
          <col class="w-20" />
          <!-- <col class="w-10" /> -->
          <col class="w-5" />
          <col class="w-10" />
          <col class="w-5" />
          <col class="w-5" />
          <col class="w-10" />
        </colgroup>
        <thead class="table-secondary text-center">
          <tr>
            <th scope="col">순번</th>
            <th scope="col">서비스</th>
            <th scope="col">리소스 이름</th>
            <!-- <th class="col">과금단위</th> -->
            <th class="col">사용량</th>
            <th class="col">단가 단위</th>
            <th class="col">단가(원)</th>
            <th class="col">
              <Popper
                arrow
                placement="top"
                content="계산식 : (사용량/단가 단위)*단가"
              >
                금액(원) <i class="bx bxs-info-circle font-size-16 mx-1"></i>
              </Popper>
            </th>
            <th class="col">초기화/삭제</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(item, index) in computedPagedList"
            v-bind:key="index"
          >
            <td>{{ startPageIndex + index + 1 }}</td>
            <td>
              <!-- <input
                type="text"
                class="form-control text-start"
                v-model="item.HomepageService"
                :disabled="item.DeleteYN"
              /> -->
              {{ item.HomepageService }}
            </td>
            <td
              :class="{
                'text-start': true,
                'custom-row-strikethrough': item.DeleteYN,
              }"
            >
              {{ item.ResourceName }}
            </td>
            <!-- <td
              :class="{
                'text-start': true,
                'custom-row-strikethrough': item.DeleteYN,
              }"
            >
              {{ item.CounterUiName }}
            </td> -->
            <!-- <td class="text-start">
                    {{ $stringUtils.stringEscape(item.ResourceName, 30) }}
                  </td> -->
            <td
              :class="{
                'custom-row-strikethrough': item.DeleteYN,
              }"
            >
              <!-- <input
                type="number"
                class="form-control text-end form-control-sm"
                v-model="item.Usage"
                :disabled="item.DeleteYN"
              /> -->
              {{ item.Usage }}
            </td>
            <td
              :class="{
                'custom-row-strikethrough': item.DeleteYN,
              }"
            >
              {{ item.Unit }} {{ item.UnitName }}
            </td>
            <td>
              <input
                type="number"
                class="form-control text-end form-control-sm"
                v-model="computedUnitPrice(item).value"
                :disabled="item.DeleteYN"
              />
            </td>
            <!-- <td>
              <input
                v-if="item.RegionTypeCode == `PJ1`"
                type="number"
                class="form-control text-end form-control-sm"
                v-model="item.ContractUnitPrice"
                :disabled="item.DeleteYN"
              />
              <input
                v-else-if="item.ContractYN"
                type="number"
                class="form-control text-end form-control-sm"
                v-model="item.ContractUnitPrice"
                :disabled="item.DeleteYN"
              />
              <input
                v-else-if="!item.ContractYN"
                type="number"
                class="form-control text-end form-control-sm"
                v-model="item.UnitPrice"
                :disabled="item.DeleteYN"
              />
            </td> -->

            <td
              :class="{
                'text-end': true,
                'custom-row-strikethrough': item.DeleteYN,
              }"
            >
              {{ computedPrice(item).withComma() }}
            </td>
            <td>
              <button
                v-if="!item.DeleteYN"
                @click="event.onClickReset(item.rownum)"
                class="btn btn-primary btn-sm align-middle mx-1"
              >
                초기화
              </button>
              <button
                v-if="!item.DeleteYN"
                @click="event.onClickDelete(item.rownum)"
                class="btn btn-danger btn-sm align-middle"
              >
                삭제
              </button>

              <button
                v-if="item.DeleteYN"
                @click="event.onClickCancelDelete(item.rownum)"
                class="btn btn-danger btn-sm align-middle"
              >
                삭제취소
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-lg-12">
          <ul class="pagination pagination-sm justify-content-center my-2">
            <li
              class="page-item"
              :class="
                startPage - dataset.pageInfo.defaultPages < 1
                  ? 'disabled'
                  : null
              "
            >
              <button
                v-on:click="event.onClickPageNo(1)"
                class="page-link"
              >
                <i class="mdi mdi-chevron-double-left"></i>
              </button>
            </li>
            <li
              class="page-item"
              :class="
                startPage - dataset.pageInfo.defaultPages < 1
                  ? 'disabled'
                  : null
              "
            >
              <a
                href="javascript: void(0);"
                class="page-link"
                v-on:click.prevent="event.onClickPrevGroupStartPage()"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              v-for="item in countInPages"
              :key="item"
              :class="
                startPage - 1 + item == dataset.pageInfo.page ? 'active' : null
              "
            >
              <button
                v-on:click="event.onClickPageNo(startPage - 1 + item)"
                class="page-link page-link-number"
              >
                {{ startPage - 1 + item }}
              </button>
            </li>
            <template v-if="countInPages === 0">
              <li class="page-item disabled">
                <button class="page-link page-link-number">1</button>
              </li>
            </template>
            <li
              class="page-item"
              :class="
                totalPage >= startPage + dataset.pageInfo.defaultPages
                  ? null
                  : 'disabled'
              "
            >
              <a
                href="javascript: void(0);"
                class="page-link"
                v-on:click.prevent="event.onClickNextGroupStartPage()"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="
                totalPage >= startPage + dataset.pageInfo.defaultPages
                  ? null
                  : 'disabled'
              "
            >
              <button
                v-on:click="event.onClickLastGroupStartPage()"
                class="page-link"
              >
                <i class="mdi mdi-chevron-double-right"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onBeforeMount, computed } from "vue";
import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";
export default {
  components: { Form },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      additionalService: {},
      invoice: {
        serviceType: "",
        selectedProjects: [],
        categoryName: "",
        additionalServiceName: "1",
        priceUnit: "",
        amount: "1",
        cost: "1",
      },
      invoiceList: [],
      rows: [{}],
      categoryList: [],
      isLoading: true,
      pageInfo: { page: 1, rowsPerPage: 5, defaultPages: 10 },
      search: { keywordType: "ResourceName", keyword: "" },
    });

    const schema = yup.object().shape({
      serviceType: yup.string().required("청구 기준은 필수 입력값 입니다."),
      additionalServiceName: yup
        .string()
        .required("부가 서비스 이름은 필수 입력값입니다."),
      amount: "",
      cost: "",
    });

    const getData = (data) => {
      dataset.value.invoice.selectedProjects = data;
    };

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onClickOk: () => {
        dataset.value.invoiceList.GroupedUsages.forEach((result) => {
          // 이용 금액 계산식 = 올림(올림(사용량/단가 단위) * 약정 할인 단가)
          result.ContractPrice = Math.ceil(
            Math.ceil(result.Usage / result.Unit) * result.ContractUnitPrice,
          );

          result.Price = Math.ceil(
            Math.ceil(result.Usage / result.Unit) * result.UnitPrice,
          );
        });
        // dataset.value.invoiceList.
        return dataset.value.invoiceList ?? [];
      },
      onClickDelete: (index) => {
        dataset.value.invoiceList.GroupedUsages[index].DeleteYN = true;
      },
      onClickCancelDelete: (index) => {
        dataset.value.invoiceList.GroupedUsages[index].DeleteYN = false;
      },
      onClickReset: (index) => {
        const usagesData = dataset.value.invoiceList.GroupedUsages[index];
        usagesData.ContractPrice = usagesData.OriContractPrice;
        usagesData.Price = usagesData.OriPrice;
        usagesData.ContractUnitPrice = usagesData.OriContractUnitPrice;
        usagesData.UnitPrice = usagesData.OriUnitPrice;
        usagesData.HomepageService = usagesData.OriHomepageService;
        usagesData.Usage = usagesData.OriUsage;
      },
      onClickPageNo: (page) => {
        dataset.value.pageInfo.page = page;
      },
      onClickPrevGroupStartPage: () => {
        let prevGroupStartPage =
          startPage.value - dataset.value.pageInfo.defaultPages;
        if (prevGroupStartPage < 1) {
          prevGroupStartPage = 1;
        }
        event.onClickPageNo(prevGroupStartPage);
      },
      onClickNextGroupStartPage: () => {
        let nextGroupStartPage =
          startPage.value + dataset.value.pageInfo.defaultPages;
        if (nextGroupStartPage > totalPage.value) {
          nextGroupStartPage =
            totalPage.value -
            ((totalPage.value - 1) % dataset.value.pageInfo.defaultPages);
        }
        event.onClickPageNo(nextGroupStartPage);
      },
      onClickLastGroupStartPage: () => {
        let lastGroupStartPage =
          Math.floor(
            (totalPage.value - 1) / dataset.value.pageInfo.defaultPages,
          ) *
            dataset.value.pageInfo.defaultPages +
          1;
        event.onClickPageNo(lastGroupStartPage);
      },
    };

    const initModalData = async () => {
      dataset.value.invoiceList = await proxy.$modalUtils.getModalData();
      if (dataset.value.invoiceList) {
        let rownum = 0;
        dataset.value.invoiceList?.GroupedUsages.forEach(
          (item) => (item.rownum = rownum++),
        );
      }
    };

    const computedPrice = (item) => {
      // 이용 금액 계산식 = 올림(올림(사용량/단가 단위) * 약정 할인 단가)
      if (item.RegionTypeCode == "PJ1") {
        return Math.ceil(
          Math.ceil(item.Usage / item.Unit) * item.ContractUnitPrice,
        );
      } else {
        return item.ContractYN
          ? Math.ceil(
              Math.ceil(item.Usage / item.Unit) * item.ContractUnitPrice,
            )
          : Math.ceil(Math.ceil(item.Usage / item.Unit) * item.UnitPrice);
      }
    };

    const computedUnitPrice = (item) => {
      return computed({
        get() {
          if (item.RegionTypeCode === "PJ1") {
            return item.ContractUnitPrice;
          } else {
            return item.ContractYN ? item.ContractUnitPrice : item.UnitPrice;
          }
        },
        set(value) {
          if (item.RegionTypeCode === "PJ1" || item.ContractYN) {
            item.ContractUnitPrice = value;
          } else {
            item.UnitPrice = value;
          }
        },
      });
    };

    // [grid] start index
    const startPageIndex = computed(() => {
      return (
        (dataset.value.pageInfo.page - 1) * dataset.value.pageInfo.rowsPerPage
      );
    });
    // [grid] start index
    const endPageIndex = computed(() => {
      return startPageIndex.value + dataset.value.pageInfo.rowsPerPage;
    });
    // [grid] search list
    const computedList = computed(() => {
      const list = dataset.value.invoiceList?.GroupedUsages;
      return list
        ? list.filter(
            (item) =>
              item[dataset.value.search.keywordType]
                .toString()
                .indexOf(dataset.value.search.keyword) > -1,
          )
        : [];
    });
    // [grid] page list
    const computedPagedList = computed(() => {
      return computedList.value.slice(startPageIndex.value, endPageIndex.value);
    });
    // [grid pagination] start page
    const startPage = computed(() => {
      let value =
        Math.floor(
          (dataset.value.pageInfo.page - 1) /
            dataset.value.pageInfo.defaultPages,
        ) *
          dataset.value.pageInfo.defaultPages +
        1;
      return value;
    });
    // [grid pagination] current page
    const countInPages = computed(() => {
      let currPages = totalPage.value - (startPage.value - 1);
      if (currPages < dataset.value.pageInfo.defaultPages) {
        return currPages;
      } else {
        return dataset.value.pageInfo.defaultPages;
      }
    });
    // [grid pagination] total pages
    const totalPage = computed(() => {
      let _totalPage = Math.floor(
        computedList.value.length / dataset.value.pageInfo.rowsPerPage,
      );
      if (computedList.value.length % dataset.value.pageInfo.rowsPerPage) {
        _totalPage += 1;
      }

      if (_totalPage != 0 && _totalPage < dataset.value.pageInfo.page) {
        event.onClickPageNo(1);
      }
      return _totalPage;
    });

    onBeforeMount(() => {
      initModalData();
      // proxy.$awn.asyncBlock(
      // initModalData(),
      // null,
      // null,
      // "데이터 로딩 중입니다.<br/>잠시만 기다려 주세요.",
      // );
    });
    return {
      event,
      dataset,
      schema,
      errors,
      getData,
      computedPrice,
      computedPagedList,
      startPageIndex,
      startPage,
      countInPages,
      totalPage,
      computedList,
      computedUnitPrice,
    };
  },
};
</script>

<style scoped>
table.table-invoice {
  margin-top: 0;
}

table.table-invoice tbody tr.invoice-summary td {
  background-color: #eff9ff;
  font-weight: bold;
}
</style>
