<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="userType"
        class="col-form-label col-lg-2"
        >사용자 유형</label
      >
      <div class="col-lg-10">
        <div
          v-for="(item, index) in dataset.userTypeList"
          v-bind:key="item"
          class="form-check form-check-inline font-size-16"
        >
          <input
            class="form-check-input"
            type="radio"
            name="userType"
            v-bind:id="`userType${index}`"
            v-bind:checked="item.code === dataset.user.userType"
            v-bind:value="item.code"
            disabled
          />
          <label
            class="form-check-label font-size-13"
            v-bind:for="`userType${index}`"
            >{{ item.name }}</label
          >
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="loginId"
        class="col-form-label col-lg-2"
        >로그인 ID</label
      >
      <div class="col-lg-10">
        <div class="input-group">
          <input
            id="loginId"
            name="loginId"
            type="text"
            class="form-control"
            v-bind:value="dataset.user.loginId"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="fullName"
        class="col-form-label col-lg-2"
        >사용자 이름</label
      >
      <div class="col-lg-10">
        <Field
          name="fullName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.fullName }"
          placeholder="사용자 이름을 입력해 주세요."
          v-model="dataset.user.fullName"
        />
        <div class="invalid-feedback">{{ errors.fullName }}</div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="email"
        class="col-form-label col-lg-2"
        >이메일</label
      >
      <div class="col-lg-10">
        <Field
          name="email"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.email }"
          placeholder="이메일 주소를 입력해 주세요."
          v-model="dataset.user.email"
        />
        <div class="invalid-feedback">{{ errors.email }}</div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import userApi from "@/api/portal/userApi";
import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";

export default {
  components: { Form, Field },
  props: ["rowData"],
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      user: {
        userId: "",
        userType: "",
        loginId: "",
        fullName: "",
        password: "",
        confirmPassword: "",
        email: "",
      },
      userTypeList: [],
    });

    // validation schema
    const schema = yup.object().shape({
      fullName: yup.string().required("이름은 필수 입력값 입니다."),
      email: yup
        .string()
        .required("이메일은 필수 입력값 입니다.")
        .email("이메일이 유효하지 않습니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (values, actions) => {
        userApi
          .updateUser(dataset.value.user.userId, dataset.value.user)
          .then((response) => {
            const successMessage = "수정 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              actions,
              dataset.value.alert,
            );
          });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        // 필드 에러 외 에러메세지 close
        dataset.value.alert.isShow = false;
      },
    };

    const getUserTypeList = () => {
      userApi.getUserTypeList().then((response) => {
        if (response.isSuccessful) {
          dataset.value.userTypeList = response.resultData;
        }
      });
    };

    // 모달 데이터 초기화
    const initModalData = () => {
      dataset.value.user = proxy.$modalUtils.getModalData();
    };

    onMounted(() => {
      getUserTypeList();
      initModalData();
    });
    return { dataset, schema, event, errors };
  },
};
</script>
