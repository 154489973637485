export default {
  name: "cost",
  path: "/cost",
  component: () => import("@/views/index"),
  redirect: { name: "dashboard" },
  meta: {
    group: "cost",
    isView: true,
  },
  children: [
    {
      name: "dashboard",
      path: "dashboard",
      meta: {
        pageName: "상세",
      },
      component: () => import("@/views/cost/dashboard/dashboard"),
    },
    {
      name: "price",
      path: "price",
      meta: {
        pageName: "가격 목록",
      },
      component: () => import("@/views/cost/price/price"),
    },
    {
      name: "report",
      path: "report",
      redirect: { name: "reportList" },
      component: () => import("@/views/index"),
      children: [
        {
          name: "reportList",
          path: "list",
          meta: {
            pageName: "리포트 목록",
          },
          component: () => import("@/views/cost/report/reportList"),
        },
        {
          name: "reportView",
          path: ":uid",
          meta: {
            pageName: "리포트 상세",
          },
          component: () => import("@/views/cost/report/reportView"),
          props: true,
        },
        {
          name: "reportWrite",
          path: "write",
          meta: {
            pageName: "리포트 생성",
          },
          component: () => import("@/views/cost/report/reportWrite"),
        },
      ],
    },
  ],
};
