<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <!-- prettier-ignore -->
    <!-- prettier-ignore -->
    <div class="row mb-4">
      <div class="alert alert-info mb-0 d-flex justify-content-left" role="alert">
        <p class="me-1 mdi mdi-alert-circle-outline"></p>
        <pre>{{ $t("info.save.password") }}</pre>
      </div>
    </div>
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="loginId"
        class="col-form-label col-lg-2"
        >로그인 ID</label
      >
      <div class="col-lg-10">
        <div class="input-group">
          <input
            id="loginId"
            name="loginId"
            type="text"
            class="form-control"
            v-bind:value="dataset.user.loginId"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="fullName"
        class="col-form-label col-lg-2"
        >사용자 이름</label
      >
      <div class="col-lg-10">
        <input
          id="loginId"
          name="loginId"
          type="text"
          class="form-control"
          v-bind:value="dataset.user.fullName"
          disabled
        />
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="newPassword"
        class="col-form-label col-lg-2"
        >새 비밀번호</label
      >
      <div class="col-lg-10">
        <Field
          name="newPassword"
          type="password"
          class="form-control"
          :class="{ 'is-invalid': errors.newPassword }"
          placeholder="새 비밀번호를 입력해 주세요."
          v-model="dataset.user.newPassword"
        />
        <div class="invalid-feedback">{{ errors.newPassword }}</div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="newConfirmPassword"
        class="col-form-label col-lg-2"
        >새 비밀번호 확인</label
      >
      <div class="col-lg-10">
        <Field
          name="newConfirmPassword"
          type="password"
          class="form-control"
          :class="{ 'is-invalid': errors.newConfirmPassword }"
          placeholder="새 비밀번호 확인값을 입력해 주세요."
          v-model="dataset.user.newConfirmPassword"
        />
        <div class="invalid-feedback">{{ errors.newConfirmPassword }}</div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import userApi from "@/api/portal/userApi";
import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";

export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      user: {
        userType: "",
        loginId: "",
        fullName: "",
        newPassword: "",
        newConfirmPassword: "",
        email: "",
      },
      userTypeList: [],
    });

    // validation schema
    const schema = yup.object().shape({
      newPassword: yup.string().required("비밀번호는 필수 입력값 입니다."),
      newConfirmPassword: yup
        .string()
        .oneOf([yup.ref("newPassword"), null], "비밀번호가 일치 하지 않습니다.")
        .required("비밀번호 확인은 필수 입력값 입니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (values, actions) => {
        userApi
          .restPassword(dataset.value.user.userId, dataset.value.user)
          .then((response) => {
            const successMessage = "비밀번호가 초기화 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              actions,
              dataset.value.alert,
            );
          });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        // 필드 에러 외 에러메세지 close
        dataset.value.alert.isShow = false;
      },
    };

    // 모달 데이터 초기화
    const initModalData = () => {
      dataset.value.user = proxy.$modalUtils.getModalData();
    };

    onMounted(() => {
      initModalData();
    });

    return {
      dataset,
      schema,
      event,
      errors,
    };
  },
};
</script>
