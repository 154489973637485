import api from "../http";

export default {};
const getRoleList = (data = null) => {
  return api.getPromise("GET", `/portal/role/list`, data);
};

const getRolePage = (data = null) => {
  return api.getPromise("GET", `/portal/role`, data);
};

const getRole = (data = null) => {
  return api.getPromise("GET", `/portal/role/${data.roleId}`);
};

const createRole = (data = null) => {
  return api.getPromise("POST", `/portal/role`, data);
};

const updateRole = (data = null) => {
  return api.getPromise("PUT", `/portal/role/${data.roleId}`, data);
};

const deleteRole = (data = null) => {
  return api.getPromise("POST", `/portal/role/delete`, data);
};

const multipleDeleteRole = (data = null) => {
  return api.getPromise("POST", `/portal/role/delete/multiple`, data);
};

const getRoleTypeList = () => {
  return api.getPromise("GET", `/portal/role/type/list`);
};

const createRolePrivilege = (data = null) => {
  return api.getPromise("POST", `/portal/role/${data.roleId}/privilege`, data);
};

const deleteRolePrivilege = (data = null) => {
  return api.getPromise(
    "POST",
    `/portal/role/${data.roleId}/privilege/delete`,
    data,
  );
};

const getRolePrivilegeList = (data = null) => {
  return api.getPromise("GET", `/portal/role/${data.roleId}/privilege`, data);
};
export {
  getRoleList,
  getRolePage,
  createRole,
  getRole,
  updateRole,
  deleteRole,
  getRoleTypeList,
  createRolePrivilege,
  deleteRolePrivilege,
  getRolePrivilegeList,
  multipleDeleteRole,
};
