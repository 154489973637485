import api from "../http";
export default {
  getJobPage: (data = null) => {
    return api.getPromise("GET", `/portal/scheduler/job`, data);
  },
  getJob: (jobId, data = null) => {
    return api.getPromise("GET", `/portal/scheduler/job/${jobId}`, data);
  },
  runJob: (jobId) => {
    return api.getPromise("POST", `/portal/scheduler/job/${jobId}/run`);
  },
  getJobSchedule: (jobId) => {
    return api.getPromise("GET", `/portal/scheduler/job/${jobId}/schedule`);
  },
  updateJobSchedule: (jobId, data = null) => {
    return api.getPromise(
      "PUT",
      `/portal/scheduler/job/${jobId}/schedule`,
      data,
    );
  },
  updateJobScheduleEnable: (jobId) => {
    return api.getPromise(
      "PUT",
      `/portal/scheduler/job/${jobId}/schedule/enable`,
    );
  },
  updateJobScheduleDisable: (jobId) => {
    return api.getPromise(
      "PUT",
      `/portal/scheduler/job/${jobId}/schedule/disable`,
    );
  },
  updateJobExecutionEnable: (jobId) => {
    return api.getPromise(
      "PUT",
      `/portal/scheduler/job/${jobId}/execution/enable`,
    );
  },
  updateJobExecutionDisable: (jobId) => {
    return api.getPromise(
      "PUT",
      `/portal/scheduler/job/${jobId}/execution/disable`,
    );
  },
  getJobExecutionPage: (jobId, data = null) => {
    return api.getPromise(
      "GET",
      `/portal/scheduler/job/${jobId}/execution`,
      data,
    );
  },
  getExecutionPage: (data = null) => {
    return api.getPromise("GET", `/portal/scheduler/execution`, data);
  },
  getJobExecution: (executionId, data = null) => {
    return api.getPromise(
      "GET",
      `/portal/scheduler/execution/${executionId}`,
      data,
    );
  },
  getJobExecutionOutput: (executionId, data = null) => {
    return api.getPromise(
      "GET",
      `/portal/scheduler/execution/${executionId}/output`,
      data,
    );
  },
};
