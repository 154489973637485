<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeId"
        class="col-form-label col-lg-2"
        >그룹코드 ID</label
      >
      <div class="col-lg-10">
        <div class="input-group">
          <Field
            id="codegroupId"
            name="codegroupId"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.codegroupId }"
            placeholder="그룹코드 ID를 입력해 주세요."
            v-model="dataset.code.codegroupId"
            autocomplete="off"
            maxlength="36"
            disabled
          />
          <div class="invalid-feedback">
            {{ errors.codegroupId }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeId"
        class="col-form-label col-lg-2"
        >코드 ID</label
      >
      <div class="col-lg-10">
        <div class="input-group">
          <Field
            id="codeId"
            name="codeId"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.codeId }"
            placeholder="코드 ID를 입력해 주세요."
            v-model.trim="dataset.code.codeId"
            autocomplete="off"
            maxlength="36"
            disabled
          />

          <div class="invalid-feedback">
            {{ errors.codeId }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeName"
        class="col-form-label col-lg-2"
        >코드 이름</label
      >
      <div class="col-lg-10">
        <Field
          name="codeName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.codeName }"
          placeholder="코드 이름을 입력해주세요"
          v-model.trim="dataset.code.codeName"
          maxlength="40"
        />
        <div class="invalid-feedback">
          {{ errors.codeName }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeDescription"
        class="col-form-label col-lg-2"
        >코드 설명</label
      >
      <div class="col-lg-10">
        <Field
          as="textarea"
          rows="5"
          name="codeDescription"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.codeDescription }"
          placeholder="코드 설명을 입력해주세요"
          v-model="dataset.code.codeDescription"
          maxlength="200"
        />
        <div class="invalid-feedback">
          {{ errors.codeDescription }}
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <label
        for="codeAttributeName1"
        class="form-check-label col-lg-2"
        >클라우드서비스</label
      >
      <div class="col-lg-10">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input
              class="form-check-input"
              type="radio"
              name="codeAttributeName1"
              :value="true"
              v-model="dataset.code.codeAttributeName1"
              :checked="
                dataset.code.codeAttributeName1 === 'true' ? true : false
              "
            />예</label
          >
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label"
            ><input
              class="form-check-input"
              type="radio"
              name="codeAttributeName1"
              :value="false"
              v-model="dataset.code.codeAttributeName1"
              :checked="
                dataset.code.codeAttributeName1 !== 'true' ? true : false
              "
            />아니오</label
          >
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <label
        class="form-check-label col-lg-2"
        for="useYn"
        >사용여부</label
      >

      <div class="d-flex col-lg-10">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input
              class="form-check-input"
              type="radio"
              name="useYn"
              v-model="dataset.code.useYn"
              :value="true"
            />예</label
          >
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label"
            ><input
              class="form-check-input"
              type="radio"
              name="useYn"
              v-model="dataset.code.useYn"
              :value="false"
            />아니오</label
          >
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { useStore } from "vuex";

import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";

import customerCodeApi from "@/api/portal/customerCodeApi";

export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const { getters } = useStore();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      codegroupId: "PROJECT_GROUP",
      code: {
        codegroupId: "",
        codeId: "",
        codeName: "",
        codeDescription: "",
        codeAttributeName1: "",
        useYn: "",
      },
    });

    const schema = yup.object().shape({
      codeName: yup
        .string()
        .min(1, "1글자 이상 40 글자 이하로 입력해주세요")
        .max(40, "1글자 이상 40글자 이하로 입력해주세요")
        .required("코드 이름은 필수 입력값 입니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (value, actions) => {
        customerCodeApi
          .update(
            getters["user/activeOrganization"].organizationId,
            dataset.value.codegroupId,
            dataset.value.code.codeId,
            dataset.value.code,
          )
          .then((response) => {
            const successMessage = "수정 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              actions,
              dataset.value.alert,
            );
          });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        dataset.value.alert.isShow = false;
      },
    };

    const initModalData = () => {
      dataset.value.code = proxy.$modalUtils.getModalData();
    };

    onMounted(() => {
      initModalData();
    });
    return { event, dataset, errors, schema };
  },
};
</script>
