import api from "../http";
export default {
  getCloudAccountList: (data = null) => {
    return api.getPromise("GET", `/portal/cloudaccount`, data);
  },

  getCloudAccount: (cloudaccountId = null, data = null) => {
    return api.getPromise(
      "GET",
      `/portal/cloudaccount/${cloudaccountId}`,
      data,
    );
  },

  createCloudaccount: (data = null) => {
    return api.getPromise("POST", `/portal/cloudaccount`, data);
  },

  updateCloudaccount: (cloudaccountId = null, data = null) => {
    return api.getPromise(
      "PUT",
      `/portal/cloudaccount/${cloudaccountId}`,
      data,
    );
  },

  updateCloudaccountIsActive: (cloudaccountId = null, data = null) => {
    return api.getPromise(
      "PUT",
      `/portal/cloudaccount/active/${cloudaccountId}`,
      data,
    );
  },

  deleteCloudaccount: (data = null) => {
    return api.getPromise("POST", `/portal/cloudaccount/delete`, data);
  },

  multipleDeleteCloudaccount: (data = null) => {
    return api.getPromise("POST", `/portal/cloudaccount/delete/multiple`, data);
  },

  getCloudaccountType: (data = null) => {
    return api.getPromise("GET", `/portal/cloudaccount/auth/type/list`, data);
  },

  // const getLoginCredentialField = (data) => {
  //   return api.getPromise("GET", `/portal/cloudaccount/auth/resource/list`, data);
  // };
  getLoginCredentialFieldList: (data) => {
    return api.getPromise("GET", `/portal/cloudaccount/auth/field/list`, data);
  },
};
