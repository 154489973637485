import api from "../http";

const getMessagePage = (data = null) => {
  return api.getPromise("GET", `/portal/message`, data);
};

const getMessageList = (data = null) => {
  return api.getPromise("GET", `/portal/message`, data);
};

const getMessage = (messageId = null, data = null) => {
  return api.getPromise("GET", `/portal/message/${messageId}`, data);
};

const getMessageMap = () => {
  return api.getPromise("GET", `/portal/message/lang/all`);
};

const createMessage = (data = null) => {
  return api.getPromise("POST", `/portal/message`, data);
};

const updateMessage = (messageId = null, data = null) => {
  return api.getPromise("PUT", `/portal/message/${messageId}`, data);
};

const deleteMessage = (data = null) => {
  return api.getPromise("POST", `/portal/message/delete`, data);
};

const refreshMessage = () => {
  return api.getPromise("POST", `/portal/message/refresh`);
};

const mutipleDeleteMessage = (data = null) => {
  return api.getPromise("POST", `/portal/message/delete/multiple`, data);
};

export {
  getMessagePage,
  getMessageList,
  getMessage,
  getMessageMap,
  createMessage,
  updateMessage,
  deleteMessage,
  refreshMessage,
  mutipleDeleteMessage,
};
