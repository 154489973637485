<template>
  <div
    v-if="dataset.isLoading"
    class="loading-spinner"
  ></div>
  <template v-if="!dataset.isLoading">
    <Form
      v-on:submit="event.onSubmit"
      v-bind:validation-schema="schema"
      v-slot="{ errors }"
      ref="form"
    >
      <div
        class="row mb-4"
        v-show="dataset.alert.isShow"
      >
        <div
          class="alert alert-danger alert-dismissible fade mb-0"
          :class="{ show: dataset.alert.isShow }"
          role="alert"
        >
          <i class="mdi mdi-alert-circle-outline me-2"></i>
          {{ dataset.alert.message }}
          <button
            type="button"
            class="btn-close"
            v-on:click="event.onClickErrorAlertClose()"
          ></button>
        </div>
      </div>
      <div class="row mb-4">
        <label
          for="customerId"
          class="col-form-label col-lg-2"
          >고객사 ID</label
        >
        <div class="col-lg-10">
          <div class="input-group">
            <Field
              id="customerId"
              name="customerId"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.customerId }"
              placeholder="고객사 ID를 입력해 주세요."
              v-model.trim="dataset.customer.customerId"
              autocomplete="off"
              maxlength="36"
              disabled
            />
            <div class="input-group-append">
              <copyButton :text="dataset.customer.customerId"></copyButton>
            </div>
            <div class="invalid-feedback">
              {{ errors.customerId }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label
          for="customerName"
          class="col-form-label col-lg-2"
          >고객사 이름</label
        >
        <div class="col-lg-10">
          <Field
            name="customerName"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.customerName }"
            placeholder="고객사 이름을 입력해주세요"
            v-model.trim="dataset.customer.customerName"
            maxlength="40"
          />
          <div class="invalid-feedback">
            {{ errors.customerName }}
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label
          class="form-check-label col-lg-2"
          for="useYn"
          >사용여부</label
        >

        <div class="d-flex col-lg-10">
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input"
                type="radio"
                name="useYn"
                v-model="dataset.customer.useYn"
                :value="true"
              />예</label
            >
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label"
              ><input
                class="form-check-input"
                type="radio"
                name="useYn"
                v-model="dataset.customer.useYn"
                :value="false"
              />아니오</label
            >
          </div>
        </div>
      </div>
    </Form>
  </template>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";

import copyButton from "@/components/btn/copyButton";

import codeApi from "@/api/portal/codeApi";
import customerApi from "@/api/portal/customerApi";

export default {
  components: { Form, Field, copyButton },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      customer: {
        customerId: "",
        customerName: "",
        customerDescription: "",
      },
      customerTypeList: [],
      isLoading: true, // 로딩중
    });

    // validation schema
    const schema = yup.object().shape({
      customerId: yup
        .string()
        .min(1, "1글자 이상 36글자 이하로 입력해주세요")
        .max(36, "1글자 이상 36글자 이하로 입력해주세요")
        .required("고객사 ID는 필수 입력값 입니다."),
      customerName: yup
        .string()
        .min(1, "1글자 이상 40 글자 이하로 입력해주세요")
        .max(40, "1글자 이상 40글자 이하로 입력해주세요")
        .required("이름은 필수 입력값 입니다."),
      customerDescription: yup
        .string()
        .min(0)
        .max(200, "200글자 이하로 입력해주세요"),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (value, actions) => {
        customerApi.updateCustomer(dataset.value.customer).then((response) => {
          const successMessage = "수정 되었습니다.";
          proxy.$modalUtils.handleApiResponse(
            response,
            successMessage,
            actions,
            dataset.value.alert,
          );
        });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        // 필드 에러 외 에러메세지 close
        dataset.value.alert.isShow = false;
      },
    };

    const initModalData = () => {
      dataset.value.customer = proxy.$modalUtils.getModalData();
    };

    const getCodeListForCustomerType = async () => {
      let response = await codeApi.getCodeListByCodegroupId(
        "ORGANIZATION_TYPE",
      );
      if (response.isSuccessful) {
        dataset.value.customerTypeList = response.resultData.map((item) => {
          return {
            id: item.codeId,
            name: item.codeName,
            isDisabled: item.codeAttributeName1 === "true",
          };
        });
        dataset.value.isLoading = false;
      }
    };

    onMounted(() => {
      getCodeListForCustomerType();
      initModalData();
    });
    return { event, dataset, errors, schema };
  },
};
</script>
