export default {
  name: "asset",
  path: "/asset",
  component: () => import("@/views/index"),
  redirect: { name: "assetDashboard" },
  meta: {
    group: "asset",
    isView: true,
  },
  children: [
    {
      name: "assetDashboard",
      path: "dashboard",
      meta: {
        pageName: "상세",
      },
      component: () => import("@/views/asset/dashboard/assetDashboard"),
    },
    {
      name: "assetSearch",
      path: "search",
      component: () => import("@/views/index"),
      redirect: { name: "assetSearchList" },
      children: [
        {
          name: "assetSearchList",
          path: "list",
          meta: {
            title: "자원 검색",
            pageName: "자원 목록",
          },
          component: () => import("@/views/asset/search/assetSearchList"),
        },
        {
          name: "assetSearchView",
          path: ":assetId",
          meta: { 
            title: "자원 상세",
            pageName: "자원 상세",
          },
          component: () => import("@/views/asset/search/assetSearchView"),
          children: [
            {
              name: "assetSearchViewDetails",
              path: "details",
              meta: {
                title: "자원 상세-개요",
              },
              component: () =>
                import(
                  "@/views/asset/search/components/assetSearchViewDetails"
                ),
            },
            {
              name: "assetSearchViewGraph",
              path: "graph",
              meta: {
                title: "자원 상세-그래프",
              },
              component: () =>
                import("@/views/asset/search/components/assetSearchViewGraph"),
            },
            {
              name: "assetSearchViewHistory",
              path: "history",
              meta: {
                title: "자원 상세-구성 변경 이력",
              },
              component: () => import("@/views/asset/search/components/assetSearchViewHistory"),
            },
          ],
        },
      ],
    },
  ],
};
