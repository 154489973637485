export default {
  name: "taggroup",
  path: "/taggroup",
  meta: {
    group: "tag",
    isView: true,
  },
  redirect: { name: "taggroupList" },
  component: () => import("@/views/index"),
  children: [
    {
      name: "taggroupList",
      path: "list",
      meta: {
        title: "태그그룹 목록",
        pageName: "태그그룹 목록",
      },
      component: () => import("@/views/taggroup/taggroupList"),
    },
    {
      name: "taggroupView",
      path: ":taggroupId",
      meta: {
        title: "태그그룹 상세 조회",
        pageName: "태그그룹 상세",
      },
      component: () => import("@/views/taggroup/taggroupView"),
      children: [
        {
          name: "taggroupViewDetail",
          path: "details",
          meta: {
            title: "태그그룹 상세 - 개요",
          },
          component: () => import("@/views/taggroup/components/taggroupViewDetail"),
        },
        {
          name: "taggroupViewTagList",
          path: "tag",
          meta: {
            title: "태그그룹 상세 - 태그목록",

          },
          component: () => import("@/views/taggroup/components/taggroupViewTagList"),
        },
      ],
    },
  ],
};
