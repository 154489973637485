import api from "../http";

export default {
  getOrganizationList: (data = null) => {
    return api.getPromise("GET", `/portal/organization/list`, data);
  },

  getOrganizationPage: (data = null) => {
    return api.getPromise("GET", `/portal/organization`, data);
  },

  getOrganizationHierarchy: (organizationId = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${organizationId}/hierarchy`,
    );
  },

  getOrganization: (id = null) => {
    return api.getPromise("GET", `/portal/organization/${id}`);
  },

  createOrganization: (data = null) => {
    return api.getPromise("POST", `/portal/organization`, data);
  },

  updateOrganization: (data = null) => {
    return api.getPromise(
      "PUT",
      `/portal/organization/${data.organizationId}`,
      data,
    );
  },

  deleteOrganization: (data = null) => {
    return api.getPromise("POST", `/portal/organization/delete`, data);
  },

  createOrganizationMember: (data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${data.organizationId}/member`,
      data,
    );
  },

  deleteOrganizationMember: (data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${data.organizationId}/member/delete`,
      data,
    );
  },

  updateOrganizationMemberInfo: (data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${data.organizationId}/member/manage`,
      data,
    );
  },

  getOrganizationMemberPage: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/member`,
      data,
    );
  },

  getOrganizationMemberList: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/member/list`,
      data,
    );
  },

  deleteMultipleOrganization: (data = null) => {
    return api.getPromise("POST", `/portal/organization/delete/multiple`, data);
  },

  getMyOrganizationMemberList: (data = null) => {
    return api.getPromise("GET", `/portal/organization/member/me`, data);
  },

  getOrganizationMemberListByUserId: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/member/${data.userId}`,
      data,
    );
  },
};
