<template>
  <div
    v-if="dataset.isLoading"
    class="loading-spinner"
  ></div>
  <template v-if="!dataset.isLoading">
    <Form
      v-on:submit="event.onSubmit"
      v-bind:validation-schema="schema"
      v-slot="{ errors }"
      ref="form"
    >
      <div
        class="row mb-4"
        v-show="dataset.alert.isShow"
      >
        <div
          class="alert alert-danger alert-dismissible fade mb-0"
          :class="{ show: dataset.alert.isShow }"
          role="alert"
        >
          <i class="mdi mdi-alert-circle-outline me-2"></i>
          {{ dataset.alert.message }}
          <button
            type="button"
            class="btn-close"
            v-on:click="event.onClickErrorAlertClose()"
          ></button>
        </div>
      </div>
      <div class="row mb-4">
        <label
          for="projectName"
          class="col-form-label col-lg-2"
          >프로젝트 구분
          <Popper arrow>
            <i class="bx bxs-info-circle font-size-16 mx-1"></i>
            <!-- prettier-ignore -->
            <template  #content>
              <div class="w-500px">
                프로젝트를 분류하기 위해 설정합니다.<br/>
                프로젝트 구분 코드 관리 기능을 통해 코드를 관리합니다.<br/>
                프로젝트 구분값을 설정하면 청구서 비용 리포트에서 그룹화된 결과를 확인할 수 있습니다.
              </div>
            </template>
          </Popper>
        </label>
        <div class="col-lg-10">
          <div class="form-group">
            <Field
              v-model="dataset.project.projectGroup"
              name="projectGroup"
              as="select"
              class="form-select"
              :class="{ 'is-invalid': errors.projectGroup }"
            >
              <option
                value=""
                disabled
              >
                프로젝트 구분 선택
              </option>
              <option
                v-for="item in dataset.projectGroupList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </Field>
            <div class="invalid-feedback">
              {{ errors.projectGroup }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label
          for="projectName"
          class="col-form-label col-lg-2"
          >프로젝트 유형
          <Popper arrow>
            <i class="bx bxs-info-circle font-size-16 mx-1"></i>
            <!-- prettier-ignore -->
            <template  #content>
              <div class="w-500px">
                프로젝트 유형은 다음과 같이 구분됩니다.<br/>
                - 직접등록 : 빌링시스템에 직접 등록한 프로젝트<br/>
                - NHN Cloud : 스케줄러에 의해 자동 생성된 NHN 클라우드 프로젝트
              </div>
            </template>
          </Popper>
        </label>
        <div class="col-lg-10">
          <div
            v-for="(item, index) in dataset.projectTypeList"
            :key="item"
            class="form-check form-check-inline"
            :class="{ 'is-invalid': errors.projectType }"
          >
            <label
              class="form-check-label"
              :for="`projectType${index}`"
              ><Field
                class="form-check-input"
                type="radio"
                name="projectType"
                :id="`projectType${index}`"
                v-model="dataset.project.projectType"
                :class="{
                  'is-invalid': errors.projectType,
                }"
                :value="item.id"
                :disabled="item.isDisabled"
                checked
              />{{ item.name }}</label
            >
          </div>
          <div class="invalid-feedback">
            {{ errors.projectType }}
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label
          for="projectName"
          class="col-form-label col-lg-2"
          >프로젝트 이름</label
        >
        <div class="col-lg-10">
          <Field
            name="projectName"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.projectName }"
            placeholder="프로젝트 이름을 입력해주세요"
            v-model="dataset.project.projectName"
            maxlength="40"
          />
          <div class="invalid-feedback">
            {{ errors.projectName }}
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label
          for="projectDescription"
          class="col-form-label col-lg-2"
          >프로젝트 설명</label
        >
        <div class="col-lg-10">
          <Field
            as="textarea"
            rows="5"
            name="projectDescription"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.projectDescription }"
            placeholder="프로젝트 설명을 입력해 주세요"
            v-model="dataset.project.projectDescription"
            maxlength="200"
          />
          <div class="invalid-feedback">
            {{ errors.projectDescription }}
          </div>
        </div>
      </div>
    </Form>
  </template>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { useStore } from "vuex";
import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";

import projectApi from "@/api/portal/projectApi";
import codegroupApi from "@/api/portal/codegroupApi";
import customerCodeApi from "@/api/portal/customerCodeApi";

export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const { getters } = useStore();

    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      project: {
        projectName: "",
        projectGroup: "",
        projectType: "",
        projectOriginName: "",
        projectDescription: "",
      },
      projectGroupList: [],
      projectTypeList: [],
      isLoading: true, // 로딩중
    });

    // validation schema
    const schema = yup.object().shape({
      projectGroup: yup.string().required("조직 구분은 필수 입력값 입니다."),
      projectType: yup.string().required("조직 유형은 필수 입력값 입니다."),
      projectName: yup
        .string()
        .min(1, "1글자 이상 40 글자 이하로 입력해주세요")
        .max(40, "1글자 이상 40글자 이하로 입력해주세요")
        .required("이름은 필수 입력값 입니다."),
      projectDescription: yup
        .string()
        .min(0)
        .max(200, "200글자 이하로 입력해주세요"),
    });

    //  handleSubmit, setFieldError
    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (values, actions) => {
        dataset.value.project.projectOriginName =
          dataset.value.project.projectName;
        projectApi
          .createProject(
            getters["user/activeOrganization"].organizationId,
            dataset.value.project,
          )
          .then((response) => {
            const successMessage = "생성 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              actions,
              dataset.value.alert,
            );
          });
      },
      onClickOk: (/*values*/) => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        // 필드 에러 외 에러메세지 close
        dataset.value.alert.isShow = false;
      },
    };

    const initCode = async () => {
      const projectGroupPromise = customerCodeApi.getList(
        getters["user/activeCustomer"].customerId,
        "PROJECT_GROUP",
      );
      const projectTypePromise = codegroupApi.getCodeList("PROJECT_TYPE");

      const [projectGroupResult, projectTypeResult] = await Promise.all([
        projectGroupPromise,
        projectTypePromise,
      ]);

      if (projectGroupResult.isSuccessful) {
        dataset.value.projectGroupList = projectGroupResult.resultData.map(
          (item) => {
            return {
              id: item.codeId,
              name: item.codeName,
              isDisabled: item.codeAttributeName1 === "true",
            };
          },
        );
      }

      if (projectTypeResult.isSuccessful) {
        dataset.value.projectTypeList = projectTypeResult.resultData.map(
          (item) => {
            return {
              id: item.codeId,
              name: item.codeName,
              isDisabled: item.codeAttributeName1 === "true",
            };
          },
        );
      }

      dataset.value.isLoading = false;
    };

    onMounted(() => {
      initCode();
    });

    return {
      dataset,
      event,
      schema,
      errors,
    };
  },
};
</script>
