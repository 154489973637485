import api from "@/api/http";
export default {
  getAnomaly: (data = null) => {
    return api.getCostPromise("GET", `/anomaly`, data, null, true);
  },
  getAnomalyOverview: (data = null) => {
    return api.getCostPromise("GET", "/anomaly/overview", data);
  },
  getAnomalyDetail: (uid, data = null) => {
    return api.getCostPromise("GET", `/anomaly/detail/${uid}`, data);
  },
  getAnomalySetting: (data = null) => {
    return api.getCostPromise("GET", "/anomaly/setting", data);
  },
  createAnomalySetting: (data = null) => {
    return api.getCostPromise("POST", "/anomaly/setting", data);
  },
  getAnomalySettingDetail: (uid, data = null) => {
    return api.getCostPromise("GET", `/anomaly/setting/${uid}`, data);
  },
  updateAnomalySetting: (uid, data = null) => {
    return api.getCostPromise("PUT", `/anomaly/setting/${uid}`, data);
  },
  deleteAnomalySetting: (uid, data = null) => {
    return api.getCostPromise("DELETE", `/anomaly/setting/${uid}`, data);
  },
  getDummyAnomalyList: (data = null) => {
    return api.getDummyPromise("GET", "/www/dummy/anomalyPage.json", data);
  },
  getDummyAnomalySettingList: (data = null) => {
    return api.getDummyPromise(
      "GET",
      "/www/dummy/anomalySettingPage.json",
      data,
    );
  },
  getDummyAnomalyDetailChart: (uid, data = null) => {
    return api.getDummyPromise(
      "GET",
      "/www/dummy/anomalyDetailChart.json",
      data,
    );
  },
  getDummyAnomalyOverviewChart: (data = null) => {
    return api.getDummyPromise(
      "GET",
      "/www/dummy/anomalyOverviewChart.json",
      data,
    );
  },
  getDummyAnomalySettingDetail: (uid, data = null) => {
    return api.getDummyPromise(
      "GET",
      "/www/dummy/anomalySettingDetail.json",
      data,
    );
  },
};
