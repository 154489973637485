<template>
  <div
    v-if="dataset.isShow"
    id="commModal"
    class="modal fade"
    v-bind:class="{ show: dataset.isShow }"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    style="display: block"
    role="dialog"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      v-bind:class="{
        [modalOptions.size]: true,
        'modal-dialog-scrollable': modalOptions.isScrollable,
      }"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title fw-semibold"
            id="staticBackdropLabel"
          >
            {{ modalTitle }}
            <!-- <slot name="modal-title"></slot> -->
          </h5>
          <button
            type="button"
            class="btn-close"
            v-on:click="event.onClickCloseModal()"
          ></button>
        </div>
        <div class="modal-body">
          <!-- <component v-bind:is="componentvue"></component> // for dynamic import-->

          <!-- prettier-ignore -->
          <div>
            <itemChangeModal v-if="modalName === 'itemChangeModal'" v-bind:data="dataset.data" ref="itemChangeModal" />
            <itemChangeModal v-if="modalName === 'modalItemChangeTaggroupTag'" v-bind:data="dataset.data" ref="modalItemChangeTaggroupTag" />

            <modalUserWrite v-if="modalName === 'modalUserWrite'" v-bind:data="dataset.data" ref="modalUserWrite" />
            <modalUserEdit v-if="modalName === 'modalUserEdit'" v-bind:data="dataset.data" ref="modalUserEdit" />
            <modalUserResetPassword v-if="modalName === 'modalUserResetPassword'" v-bind:data="dataset.data" ref="modalUserResetPassword" />

            <modalCustomerEdit v-if="modalName === 'modalCustomerEdit'" v-bind:data="dataset.data" ref="modalCustomerEdit" />

            <modalOrganizationWrite v-if="modalName === 'modalOrganizationWrite'" v-bind:data="dataset.data" ref="modalOrganizationWrite" />
            <modalOrganizationEdit v-if="modalName === 'modalOrganizationEdit'" v-bind:data="dataset.data" ref="modalOrganizationEdit" />

            <modalRoleWrite v-if="modalName === 'modalRoleWrite'" v-bind:data="dataset.data" ref="modalRoleWrite" />
            <modalRoleEdit v-if="modalName === 'modalRoleEdit'" v-bind:data="dataset.data" ref="modalRoleEdit" />

            <modalUserGroupWrite v-if="modalName === 'modalUserGroupWrite'" v-bind:data="dataset.data" ref="modalUserGroupWrite" />
            <modalUserGroupEdit v-if="modalName === 'modalUserGroupEdit'" v-bind:data="dataset.data" ref="modalUserGroupEdit" />

            <modalWorkGroupWrite v-if="modalName === 'modalWorkGroupWrite'" v-bind:data="dataset.data" ref="modalWorkGroupWrite" />
            <modalWorkGroupEdit v-if="modalName === 'modalWorkGroupEdit'" v-bind:data="dataset.data" ref="modalWorkGroupEdit" />

            <modalCloudaccountWrite v-if="modalName === 'modalCloudaccountWrite'" ref="modalCloudaccountWrite" />
            <modalCloudaccountEdit v-if="modalName === 'modalCloudaccountEdit'" ref="modalCloudaccountEdit" />

            <modalMessageWrite v-if="modalName === 'modalMessageWrite'" ref="modalMessageWrite" />
            <modalMessageEdit v-if="modalName === 'modalMessageEdit'" ref="modalMessageEdit" />

            <modalTagEdit v-if="modalName === 'modalTagEdit'" ref="modalTagEdit" />
            <modalTagWrite v-if="modalName === 'modalTagWrite'" ref="modalTagWrite" />

            <modalTagGroupWrite v-if="modalName === 'modalTagGroupWrite'" ref="modalTagGroupWrite" />
            <modalTagGroupEdit v-if="modalName === 'modalTagGroupEdit'" ref="modalTagGroupEdit" />
            <modalAdditionalServiceCategoryWrite v-if="modalName === 'modalAdditionalServiceCategoryWrite'" ref="modalAdditionalServiceCategoryWrite" />
            <modalAdditionalServiceCategoryEdit v-if="modalName === 'modalAdditionalServiceCategoryEdit'" ref="modalAdditionalServiceCategoryEdit" />

            <modalProjectWrite v-if="modalName === 'modalProjectWrite'" ref="modalProjectWrite" />
            <modalProjectEdit v-if="modalName === 'modalProjectEdit'" ref="modalProjectEdit" />

            <modalProjectgroupWrite v-if="modalName === 'modalProjectgroupWrite'" ref="modalProjectgroupWrite" />
            <modalProjectgroupEdit v-if="modalName === 'modalProjectgroupEdit'" ref="modalProjectgroupEdit" />

            <modalProjectSummary v-if="modalName === 'modalProjectSummary'" ref="modalProjectSummary" v-on:footerButtonType="event.changeFooterButtonType" />
            <modalBudgetSetting v-if="modalName === 'modalBudgetSetting'" ref="modalBudgetSetting" v-on:footerButtonType="event.changeFooterButtonType" />
            
            <modalMypage v-if="modalName === 'modalMypage'" ref="modalMypage" v-on:footerButtonType="event.changeFooterButtonType" />
            
            <modalPrivilegeWrite v-if="modalName === 'modalPrivilegeWrite'" ref="modalPrivilegeWrite" />
            <modalPrivilegeEdit v-if="modalName === 'modalPrivilegeEdit'" ref="modalPrivilegeEdit" />
            
            <modalCodeGroupWrite v-if="modalName === 'modalCodeGroupWrite'" ref="modalCodeGroupWrite" />
            <modalCodeGroupEdit v-if="modalName === 'modalCodeGroupEdit'" ref="modalCodeGroupEdit" />
            
            <modalCodeWrite v-if="modalName === 'modalCodeWrite'" ref="modalCodeWrite" />
            <modalCodeEdit v-if="modalName === 'modalCodeEdit'" ref="modalCodeEdit" />
            
            <modalScheduleSetting v-if="modalName === 'modalScheduleSetting'" ref="modalScheduleSetting" />
            <modalScheduleEnabled v-if="modalName === 'modalScheduleEnabled'" ref="modalScheduleEnabled" />
            <modalExecutionEnabled v-if="modalName === 'modalExecutionEnabled'" ref="modalExecutionEnabled" />
            <!--  v-bind:param="modalData" -->
            <modalInvoiceProjectWrite v-if="modalName === 'modalInvoiceProjectWrite'" ref="modalInvoiceProjectWrite" />
            <modalInvoiceManagerWrite v-if="modalName === 'modalInvoiceManagerWrite'" ref="modalInvoiceManagerWrite" />
            <modalInvoiceAddServiceWrite v-if="modalName ==='modalInvoiceAddServiceWrite'" ref="modalInvoiceAddServiceWrite" />
            <modalInvoiceDiscountServiceWrite v-if="modalName === 'modalInvoiceDiscountServiceWrite'" ref="modalInvoiceDiscountServiceWrite" />
            <modalInvoiceReceiverWrite v-if="modalName === 'modalInvoiceReceiverWrite'" ref="modalInvoiceReceiverWrite" />
            <modalInvoiceAddServiceEdit v-if="modalName ==='modalInvoiceAddServiceEdit'" ref="modalInvoiceAddServiceEdit" />
            <modalInvoiceDiscountServiceEdit v-if="modalName ==='modalInvoiceDiscountServiceEdit'" ref="modalInvoiceDiscountServiceEdit"/>
            <modalInvoiceCloudServiceEdit v-if="modalName ==='modalInvoiceCloudServiceEdit'" ref="modalInvoiceCloudServiceEdit"/>
            <modalInvoiceExtraNoteWrite v-if="modalName === 'modalInvoiceExtraNoteWrite'" ref="modalInvoiceExtraNoteWrite"/>
            <modalInvoiceEdit v-if="modalName === 'modalInvoiceEdit'" ref="modalInvoiceEdit"/>
            <modalInvoiceHistoryCloudServiceDetail v-if="modalName === 'modalInvoiceHistoryCloudServiceDetail'" ref="modalInvoiceHistoryCloudServiceDetail" v-on:footerButtonType="event.changeFooterButtonType"/>
            <modalInvoiceCloudServiceDetail v-if="modalName === 'modalInvoiceCloudServiceDetail'" ref="modalInvoiceCloudServiceDetail" v-on:footerButtonType="event.changeFooterButtonType"/>
            <modalOptimizationIntelligenceViewDetail v-if="modalName === 'modalOptimizationIntelligenceViewDetail'" ref="modalOptimizationIntelligenceViewDetail" v-on:footerButtonType="event.changeFooterButtonType"/>
            <modalAnomalyAlarmSettingWrite v-if="modalName === 'modalAnomalyAlarmSettingWrite'" ref="modalAnomalyAlarmSettingWrite" />
            <modalAnomalyAlarmSettingEdit v-if="modalName === 'modalAnomalyAlarmSettingEdit'" ref="modalAnomalyAlarmSettingEdit" />
            <modalAnomalyDetectViewDetail v-if="modalName === 'modalAnomalyDetectViewDetail'" ref="modalAnomalyDetectViewDetail" v-on:footerButtonType="event.changeFooterButtonType"/>
          </div>
        </div>
        <div
          v-if="footerButtonType == $modalUtils.modalFooterBtnType.TYPE1"
          class="modal-footer"
        >
          <button
            type="button"
            class="btn btn-secondary waves-effect waves-light"
            v-on:click="event.onClickCloseModal()"
          >
            <i class="bx bx-block font-size-16 align-middle me-2"></i> 취소
          </button>
          <button
            type="button"
            class="btn btn-primary waves-effect waves-light"
            v-on:click="event.onClickOkModal()"
          >
            <i class="bx bx-check-double font-size-16 align-middle me-2"></i>
            확인
          </button>
        </div>
        <div
          v-else
          class="modal-footer"
        >
          <button
            type="button"
            class="btn btn-secondary waves-effect waves-light"
            v-on:click="event.onClickCloseModal()"
          >
            <i class="bx bx-block font-size-16 align-middle me-2"></i> 닫기
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="dataset.isShow"
    class="modal-backdrop fade"
    v-bind:class="{ show: dataset.isShow }"
  ></div>
</template>
<script>
// import { computed, ref, watch } from "vue"; // for dynamic import
import {
  computed,
  onMounted,
  ref,
  getCurrentInstance,
  // onBeforeMount,
} from "vue";
import { useStore } from "vuex";

import itemChangeModal from "@/components/common/vues/itemChange.vue";

import modalCloudaccountWrite from "@/views/cloudaccount/components/modalCloudaccountWrite";
import modalCloudaccountEdit from "@/views/cloudaccount/components/modalCloudaccountEdit";

import modalUserWrite from "@/views/user/components/modalUserWrite.vue";
import modalUserEdit from "@/views/user/components/modalUserEdit.vue";
import modalUserResetPassword from "@/views/user/components/modalUserResetPassword.vue";

import modalCustomerEdit from "@/views/customer/components/modalCustomerEdit";

import modalOrganizationWrite from "@/views/organization/components/modalOrganizationWrite";
import modalOrganizationEdit from "@/views/organization/components/modalOrganizationEdit";

import modalRoleWrite from "@/views/role/components/modalRoleWrite";
import modalRoleEdit from "@/views/role/components/modalRoleEdit";

import modalUserGroupWrite from "@/views/usergroup/components/modalUserGroupWrite";
import modalUserGroupEdit from "@/views/usergroup/components/modalUserGroupEdit";

import modalWorkGroupWrite from "@/views/workgroup/components/modalWorkGroupWrite";
import modalWorkGroupEdit from "@/views/workgroup/components/modalWorkGroupEdit";

import modalMessageWrite from "@/views/message/components/modalMessageWrite";
import modalMessageEdit from "@/views/message/components/modalMessageEdit";

import modalTagWrite from "@/views/tag/components/modalTagWrite";
import modalTagEdit from "@/views/tag/components/modalTagEdit";

import modalTagGroupWrite from "@/views/taggroup/components/modalTagGroupWrite";

import modalTagGroupEdit from "@/views/taggroup/components/modalTagGroupEdit";

import modalProjectWrite from "@/views/project/components/modalProjectWrite";
import modalProjectEdit from "@/views/project/components/modalProjectEdit";

import modalProjectgroupWrite from "@/views/customercode/components/modalProjectgroupWrite";
import modalProjectgroupEdit from "@/views/customercode/components/modalProjectgroupEdit";
import modalAdditionalServiceCategoryWrite from "@/views/customercode/components/modalAdditionalServiceCategoryWrite";
import modalAdditionalServiceCategoryEdit from "@/views/customercode/components/modalAdditionalServiceCategoryEdit";

import modalMypage from "@/views/mypage/components/modalMypage";
import modalProjectSummary from "@/views/cost/dashboard/components/modalProjectSummary";
import modalBudgetSetting from "@/views/cost/dashboard/components/modalBudgetSetting";

import modalPrivilegeWrite from "@/views/privilege/components/modalPrivilegeWrite";
import modalPrivilegeEdit from "@/views/privilege/components/modalPrivilegeEdit";

import modalCodeGroupWrite from "@/views/code/components/modalCodeGroupWrite";
import modalCodeGroupEdit from "@/views/code/components/modalCodeGroupEdit";

import modalCodeWrite from "@/views/code/components/modalCodeWrite";
import modalCodeEdit from "@/views/code/components/modalCodeEdit";

import modalScheduleSetting from "@/views/scheduler/job/components/modalScheduleSetting";
import modalScheduleEnabled from "@/views/scheduler/job/components/modalScheduleEnabled";
import modalExecutionEnabled from "@/views/scheduler/job/components/modalExecutionEnabled";

import modalInvoiceProjectWrite from "@/views/invoice/setting/components/modalInvoiceProjectWrite";
import modalInvoiceManagerWrite from "@/views/invoice/setting/components/modalInvoiceManagerWrite";
import modalInvoiceAddServiceWrite from "@/views/invoice/setting/components/modalInvoiceAddServiceWrite.vue";
import modalInvoiceDiscountServiceWrite from "@/views/invoice/setting/components/modalInvoiceDiscountServiceWrite.vue";

import modalInvoiceReceiverWrite from "@/views/invoice/publish/components/modalInvoiceReceiverWrite";
import modalInvoiceAddServiceEdit from "@/views/invoice/publish/components/modalInvoiceAddServiceEdit.vue";
import modalInvoiceDiscountServiceEdit from "@/views/invoice/publish/components/modalInvoiceDiscountServiceEdit.vue";
import modalInvoiceCloudServiceEdit from "@/views/invoice/publish/components/modalInvoiceCloudServiceEdit.vue";
import modalInvoiceExtraNoteWrite from "@/views/invoice/publish/components/modalInvoiceExtraNoteWrite.vue";
import modalInvoiceEdit from "@/views/invoice/publish/components/modalInvoiceEdit.vue";
import modalInvoiceHistoryCloudServiceDetail from "@/views/invoice/history/components/modalInvoiceHistoryCloudServiceDetail";
import modalInvoiceCloudServiceDetail from "@/views/invoice/publish/components/modalInvoiceCloudServiceDetail.vue";
import modalOptimizationIntelligenceViewDetail from "@/views/optimization/components/modalOptimizationIntelligenceViewDetail";
import modalAnomalyAlarmSettingWrite from "@/views/anomaly/components/modalAnomalyAlarmSettingWrite";
import modalAnomalyAlarmSettingEdit from "@/views/anomaly/components/modalAnomalyAlarmSettingEdit";
import modalAnomalyDetectViewDetail from "@/views/anomaly/components/modalAnomalyDetectViewDetail";

export default {
  components: {
    itemChangeModal,
    modalCloudaccountWrite,
    modalCloudaccountEdit,
    modalUserWrite,
    modalUserEdit,
    modalUserResetPassword,
    modalCustomerEdit,
    modalOrganizationWrite,
    modalOrganizationEdit,
    modalRoleWrite,
    modalRoleEdit,
    modalUserGroupWrite,
    modalUserGroupEdit,
    modalWorkGroupWrite,
    modalWorkGroupEdit,
    modalMessageWrite,
    modalMessageEdit,
    modalTagWrite,
    modalTagEdit,
    modalTagGroupWrite,
    modalTagGroupEdit,
    modalProjectWrite,
    modalProjectEdit,
    modalProjectgroupWrite,
    modalProjectgroupEdit,
    modalAdditionalServiceCategoryWrite,
    modalAdditionalServiceCategoryEdit,
    modalMypage,
    modalProjectSummary,
    modalPrivilegeWrite,
    modalPrivilegeEdit,
    modalCodeGroupWrite,
    modalCodeGroupEdit,
    modalCodeWrite,
    modalCodeEdit,
    modalScheduleSetting,
    modalScheduleEnabled,
    modalExecutionEnabled,
    modalInvoiceProjectWrite,
    modalInvoiceManagerWrite,
    modalInvoiceAddServiceWrite,
    modalInvoiceDiscountServiceWrite,
    modalInvoiceReceiverWrite,
    modalInvoiceAddServiceEdit,
    modalInvoiceDiscountServiceEdit,
    modalInvoiceCloudServiceEdit,
    modalInvoiceExtraNoteWrite,
    modalInvoiceEdit,
    modalInvoiceHistoryCloudServiceDetail,
    modalInvoiceCloudServiceDetail,
    modalOptimizationIntelligenceViewDetail,
    modalAnomalyAlarmSettingWrite,
    modalAnomalyAlarmSettingEdit,
    modalAnomalyDetectViewDetail,
    modalBudgetSetting,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const { getters } = useStore();
    const modalTitle = computed(() => getters["modal/modalTitle"]);
    const modalName = computed(() => getters["modal/modalName"]);
    const modalData = computed(() => getters["modal/modalData"]);
    let footerButtonType = ref(proxy.$modalUtils.modalFooterBtnType.TYPE1);
    /* modal-xl, modal-lg, modal-sm */
    const modalOptions = computed(() => getters["modal/modalOptions"]);
    const dataset = ref({
      isShow: computed(() => (getters["modal/modalName"] ? true : false)),
      data: computed(() => getters["modal/modalData"]),
      callback: computed(() => getters["modal/callback"]),
    });

    const event = {
      onClickCloseModal: () => {
        proxy.$modalUtils.handleCloseModal();
        footerButtonType.value = proxy.$modalUtils.modalFooterBtnType.TYPE1;
      },
      onClickOpenModal: () => {
        dataset.value.isShow = true;
      },
      onClickOkModal: () => {
        const res = proxy.$refs[modalName.value].event.onClickOk
          ? proxy.$refs[modalName.value].event.onClickOk()
          : null;
        if (dataset.value.callback) {
          if (res instanceof Promise) {
            // promise일 경우
            res
              .then((result) => {
                if (result) {
                  dataset.value.callback(result);
                } else {
                  dataset.value.callback();
                }
              })
              .catch((error) => {
                dataset.value.callback();
              });
          } else if (res !== undefined && res !== null) {
            dataset.value.callback(res);
          } else {
            dataset.value.callback();
          }
        }
      },
      changeFooterButtonType: (btnType) => {
        footerButtonType.value = btnType;
      },
    };

    /*
    // for dynamic import
    const componentvue = ref(null);

    watch(
      () => getters["modal/modalName"],
      async () => {
        if (getters["modal/modalName"]) {
          const modalKey = window.location.hash.replace(/^#\/([^/]*).+/, "$1");
          // prettier-ignore
          // await import(`@/views/${modalKey}/components/${getters["modal/modalName"]}.vue`).catch(() => {});

          // prettier-ignore
          const vue = await import(`@/views/${modalKey}/components/${getters["modal/modalName"]}.vue`);

          componentvue.value = vue?.default ?? vue;
        } else {
          componentvue.value = null;
        }
      },
    );
    return { modalName, modalData, componentvue }; */

    onMounted(() => {});
    return {
      modalTitle,
      modalName,
      modalData,
      dataset,
      event,
      modalOptions,
      footerButtonType,
    };
  },
};
</script>
