import api from "../http";

export default {
  getUser: (userId = null) => {
    return api.getPromise("GET", `/portal/user/${userId}`);
  },

  getUserList: (data = null) => {
    return api.getPromise("GET", `/portal/user/list`, data);
  },

  getUserPage: (data = null) => {
    return api.getPromise("GET", `/portal/user`, data);
  },

  getUserTypeList: () => {
    return api.getPromise("GET", `/portal/user/type/list`);
  },

  createUser: (data = null) => {
    return api.getPromise("POST", `/portal/user`, data);
  },

  updateUser: (userId = null, data = null) => {
    return api.getPromise("PUT", `/portal/user/${userId}`, data);
  },

  deleteUser: (data = null) => {
    return api.getPromise("POST", `/portal/user/delete`, data);
  },

  deleteMultipleUser: (data = null) => {
    return api.getPromise("POST", `/portal/user/delete/multiple`, data);
  },

  restPassword: (userId = null, data = null) => {
    return api.getPromise("PUT", `/portal/user/${userId}/reset-password`, data);
  },

  getUserMe: () => {
    return api.getPromise("GET", `/portal/user/me`);
  },

  updateUserMe: (data = null) => {
    return api.getPromise("PUT", `/portal/user/me`, data);
  },

  changePassword: (data = null) => {
    return api.getPromise("PUT", `/portal/user/me/change-password`, data);
  },
};
