import api from "../http";

const customerCodeApi = {
  getList: (customerId, codegroupId) => {
    return api.getPromise(
      "GET",
      `/portal/customer/${customerId}/codegroup/${codegroupId}/code/list`,
    );
  },
  getMap: (customerId, codegroupId) => {
    return api.getPromise(
      "GET",
      `/portal/customer/${customerId}/codegroup/${codegroupId}/code/map`,
    );
  },
  create: (customerId, codegroupId, data = null) => {
    return api.getPromise(
      "POST",
      `/portal/customer/${customerId}/codegroup/${codegroupId}/code`,
      data,
    );
  },
  update: (customerId, codegroupId, codeId, data = null) => {
    return api.getPromise(
      "PUT",
      `/portal/customer/${customerId}/codegroup/${codegroupId}/code/${codeId}`,
      data,
    );
  },
  deleteMultiple: (customerId, codegroupId, data) => {
    return api.getPromise(
      "POST",
      `/portal/customer/${customerId}/codegroup/${codegroupId}/code/delete/multiple`,
      data,
    );
  },
  getDuplicate: (customerId, codegroupId, codeId) => {
    const data = {
      codeId,
    };
    return api.getPromise(
      "GET",
      `/portal/customer/${customerId}/codegroup/${codegroupId}/code/duplicate`,
      data,
    );
  },
  sort: (customerId, codegroupId, data = null) => {
    return api.getPromise(
      "POST",
      `/portal/customer/${customerId}/codegroup/${codegroupId}/code/sort`,
      data,
    );
  },
};

export default {
  ...customerCodeApi,
};
