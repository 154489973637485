import * as ERROR from "@/error";
import store from "@/store/index";

/*
- vue 에러 global 설정
*/
export default {
  install(app) {
    app.config.warnHandler = (err, vm /* , info */) => {
      // 일부 디펜던시 오류 무시(해결 불가)
      if (vm?.$.type.name) {
        if (vm?.$.type.name && vm?.$.type.name.indexOf("treeselect") < 0) {
          console.warn(err);
        }
      } else {
        console.warn(err);
      }
    };
    app.config.errorHandler = (err /* , vm, info */) => {
      console.error(err); // debug mode only
      if (err.name === ERROR.AuthenticationError().name) {
        // vue에서 throw new AuthenticationError()가 난 경우
        store
          .dispatch("user/fetchClearLoginSession")
          .then(() => (window.location = store.getters["user/loginPage"]));
        // store.dispatch('user/fetchClearLoginSession').then(() => window.location = "http://localhost:5500" + store.getters['user/loginPage']);
      } else {
        //default
      }

      // Perform actions like logging, notifying, or fallback UI rendering
    };
  },
};
