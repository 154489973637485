<template>
  <div>
    <!-- ========== Left Sidebar Start ========== -->
    <div class="vertical-menu">
      <div
        data-simplebar
        class="h-100"
      >
        <!--- Sidemenu -->
        <div id="sidebar-menu">
          <!-- Left Menu Start -->
          <ul
            class="metismenu list-unstyled"
            id="side-menu"
          >
            <li
              v-for="menu in userRoleMenuTree?.children"
              :key="menu.menuId"
            >
              <a
                class="cursor-pointer"
                :class="{ 'has-arrow': 'FOLDER' === menu.menuType }"
                :data-url="menu.menuUrl"
                :aria-disabled="
                  'FOLDER' === menu.menuType && menu.children.length === 0
                "
                @click="event.onClickMenu"
              >
                <i
                  class="pointer-events-none"
                  :class="menu.icon"
                />
                <span class="pointer-events-none">{{ menu.menuName }}</span>
              </a>
              <ul>
                <li
                  v-for="child in menu.children"
                  :key="child.menuId"
                  :class="{
                    'mm-active fw-semibold': event.hasActiveChild(child),
                  }"
                >
                  <a
                    class="cursor-pointer"
                    :data-url="child.menuUrl"
                    @click="event.onClickMenu"
                  >
                    <i
                      class="pointer-events-none"
                      :class="child.icon"
                    />
                    <span class="pointer-events-none">{{
                      child.menuName
                    }}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- Sidebar -->
      </div>
    </div>
    <!-- Left Sidebar End -->
  </div>
</template>
<script>
import { getCurrentInstance } from "vue";
import { useStore } from "vuex";
import MetisMenu from "metismenujs";

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const { getters } = useStore();

    proxy.$nextTick(() => {
      const el = document.getElementById("side-menu");
      MetisMenu.attach(el);
    });

    const userRoleMenuTree = getters["user/roleMenuTree"];

    const event = {
      hasActiveChild: (child) => {
        // 현재 라우트 경로와 일치하는 경로 목록
        const currentMatchedPathList = proxy.$route.matched.map(
          (route) => route.path,
        );
        // 현재 라우트 경로와 메뉴 URL 일치 여부
        const hasMatchedPath = new RegExp(`^${child?.menuUrl}(?=\/|$)`).exec(
          // 주어진 경로 목록에서 중복을 제거하고 필터링된 최종 경로를 반환
          proxy.$urlUtils.filteredRoutePath(currentMatchedPathList),
        );
        if (hasMatchedPath) {
          // DOM 렌더링 이후 부모 메뉴 활성화
          proxy.$nextTick(() => {
            const activeChildLiEl = document.querySelector(
              "li.mm-active.fw-semibold",
            );
            // li parent ul show
            const parentMenuUlEl = activeChildLiEl?.closest("ul");
            parentMenuUlEl?.classList.add("mm-collapse", "mm-show");
            // ul parent li active
            const parentMenuLiEl = parentMenuUlEl?.closest("li");
            parentMenuLiEl?.classList.add("mm-active");
          });
        }

        return hasMatchedPath;
      },
      onClickMenu: (event) => {
        const { url } = event.target.dataset;
        if (url) {
          proxy.$router.push({ path: url });
        }
      },
    };

    return {
      isProduction: process.env.NODE_ENV === "production",
      userRoleMenuTree,
      event,
    };
  },
};
</script>
