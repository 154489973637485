export default {
  name: "cloudaccount",
  path: "/cloudaccount",
  component: () => import("@/views/index"),
  redirect: { name: "cloudaccountList" },
  meta: {
    group: "asset-collector",
    isView: true,
  },
  children: [
    {
      name: "cloudaccountList",
      path: "list",
      meta: {
        title: "클라우드계정 목록 조회",
        pageName: "클라우드계정 목록",
      },
      component: () => import("@/views/cloudaccount/cloudaccountList"),
    },
    {
      name: "cloudaccountView",
      path: ":cloudaccountId",
      component: () => import("@/views/cloudaccount/cloudaccountView"),
      meta: {
        title: "클라우드계정 상세 조회",
        pageName: "클라우드계정 상세",
      },
      children: [
        {
          name: "cloudaccountViewDetail",
          path: "details",
          meta: {
            title: "클라우드계정 상세-개요",
          },
          component: () =>
            import(
              "@/views/cloudaccount/components/cloudaccountViewDetail.vue"
            ),
        },
        {
          name: "cloudaccountViewLoginParameter",
          path: "authfield",
          meta: {
            title: "클라우드계정 로그인 정보 상세",
          },
          component: () =>
            import(
              "@/views/cloudaccount/components/cloudaccountViewLoginParameter.vue"
            ),
        },
      ],
    },
  ],
};
