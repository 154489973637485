export default {
  name: "code",
  path: "/code",
  component: () => import("@/views/index"),
  redirect: { name: "codeList" },
  meta: {
    group: "portal",
    isView: true,
  },
  children: [
    {
      name: "codeList",
      path: "list",
      meta: {
        title: "코드 목록",
        pageName: "코드 목록",
      },
      component: () => import("@/views/code/codeList"),
    },
    {
      name: "codeView",
      // path: ":codeGroupId?/:codeId",
      path: ":codeGroupId/:codeId",
      meta: {
        title: "코드 상세",
        pageName: "코드 상세",
      },
      component: () => import("@/views/code/codeView"),
      children: [
        {
          name: "codeViewDetails",
          path: "details",
          meta: {
            title: "코드 상세-개요",
          },
          component: () => import("@/views/code/components/codeViewDetails"),
        },
      ],
    },
  ],
};
