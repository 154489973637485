export default {
  name: "system",
  path: "/system",
  component: () => import("@/views/index"),
  redirect: { name: "systemsettingView" },
  meta: {
    group: "system",
    isView: true,
  },
  children: [
    {
      name: "systemsettingView",
      path: "setting",
      meta: {
        title: "시스템 설정",
        pageName: "시스템 설정",
      },
      component: () => import("@/views/systemsetting/systemsettingView"),
    },
  ],
};
