export default {
  name: "customer",
  path: "/customer",
  component: () => import("@/views/index"),
  redirect: {
    name: "customerViewDetails",
  },
  meta: {
    group: "portal",
    isView: true,
  },
  children: [
    {
      name: "customerView",
      path: "main",
      meta: {
        title: "고객사 상세",
        pageName: "고객사 상세",
      },
      component: () => import("@/views/customer/customerView"),
      children: [
        {
          name: "customerViewDetails",
          path: "details",
          meta: {
            title: "고객사 기본 정보",
          },
          component: () =>
            import("@/views/customer/components/customerViewDetails"),
        },
      ],
    },
  ],
};
