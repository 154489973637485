export default {
  name: "scheduler",
  path: "/scheduler",
  component: () => import("@/views/index"),
  redirect: { name: "schedulerList" },
  meta: {
    group: "scheduler",
    isView: true,
  },
  children: [
    {
      name: "job",
      path: "job",
      component: () => import("@/views/index"),
      redirect: { name: "schedulerJobList" },
      children: [
        {
          name: "schedulerJobList",
          path: "list",
          meta: {
            pageName: "Job 목록",
          },
          component: () => import("@/views/scheduler/job/schedulerJobList"),
        },
        {
          name: "schedulerJobView",
          path: ":jobId",
          component: () => import("@/views/scheduler/job/schedulerJobView"),
          redirect: { name: "schedulerJobViewDetails" },
          children: [
            {
              name: "schedulerJobViewDetails",
              path: "detail",
              meta: {
                pageName: "Job 상세",
              },
              component: () => import("@/views/scheduler/job/components/schedulerJobViewDetails"),
            },
            {
              name: "schedulerJobViewExecution",
              path: "execution",
              component: () => import("@/views/scheduler/job/components/schedulerJobViewExecution"),
            },
          ],
        },
      ],
    },
    {
      name: "execution",
      path: "execution",
      component: () => import("@/views/index"),
      redirect: { name: "schedulerExecutionList" },
      children: [
        {
          name: "schedulerExecutionList",
          path: "list",
          meta: {
            pageName: "실행 이력 목록",
          },
          component: () => import("@/views/scheduler/execution/schedulerExecutionList"),
        },
        {
          name: "schedulerExecutionView",
          path: ":executionId",
          meta: {
            pageName: "실행 이력 상세",
          },
          component: () => import("@/views/scheduler/execution/schedulerExecutionView"),
          redirect: { name: "schedulerExecutionViewDetails" },
          children: [
            {
              name: "schedulerExecutionViewDetails",
              path: "detail",
              component: () => import("@/views/scheduler/execution/components/schedulerExecutionViewDetails"),
            },
            {
              name: "schedulerExecutionViewLog",
              path: "log",
              component: () => import("@/views/scheduler/execution/components/schedulerExecutionViewLog"),
            },
          ],
        },
      ],
    },
  ],
};
