<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="usergroupName"
        class="col-form-label col-lg-2"
        >사용자그룹 이름</label
      >
      <div class="col-lg-10">
        <Field
          name="usergroupName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.usergroupName }"
          placeholder="사용자그룹 이름을 입력해주세요"
          v-model="dataset.userGroup.usergroupName"
          maxlength="40"
        />
        <div class="invalid-feedback">
          {{ errors.usergroupName }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="usergroupDescription"
        class="col-form-label col-lg-2"
        >사용자그룹 설명</label
      >
      <div class="col-lg-10">
        <Field
          as="textarea"
          rows="10"
          name="usergroupDescription"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.usergroupDescription }"
          placeholder="사용자그룹 설명을 입력해 주세요"
          v-model="dataset.userGroup.usergroupDescription"
          maxlength="200"
        />
        <div class="invalid-feedback">
          {{ errors.usergroupDescription }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        class="form-check-label col-lg-2"
        for="useYn"
        >사용여부</label
      >

      <div class="d-flex col-lg-10">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input
              class="form-check-input"
              type="radio"
              name="useYn"
              v-model="dataset.userGroup.useYn"
              :value="true"
            />예</label
          >
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label"
            ><input
              class="form-check-input"
              type="radio"
              name="useYn"
              v-model="dataset.userGroup.useYn"
              :value="false"
            />아니오</label
          >
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { Form, Field, useForm } from "vee-validate";
import usergroupApi from "@/api/portal/usergroupApi";
import * as yup from "yup";
export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      userGroup: {
        organizationId: "",
        usergroupId: "",
        usergroupName: "",
        usergroupDescription: "",
      },
    });

    // validation schema
    const schema = yup.object().shape({
      usergroupName: yup
        .string()
        .min(1, "1글자 이상 40 글자 이하로 입력해주세요")
        .max(40, "1글자 이상 40글자 이하로 입력해주세요")
        .required("이름은 필수 입력값 입니다."),
      usergroupDescription: yup
        .string()
        .min(0)
        .max(200, "200글자 이하로 입력해주세요"),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (value, actions) => {
        usergroupApi
          .updateUserGroup(dataset.value.userGroup)
          .then((response) => {
            const successMessage = "수정 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              actions,
              dataset.value.alert,
            );
          });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        // 필드 에러 외 에러메세지 close
        dataset.value.alert.isShow = false;
      },
    };

    const initModalData = () => {
      dataset.value.userGroup = proxy.$modalUtils.getModalData();
    };

    onMounted(() => {
      initModalData();
    });
    return { event, dataset, errors, schema };
  },
};
</script>
