export default {
  name: "auditlog",
  path: "/auditlog",
  component: () => import("@/views/index"),
  redirect: { name: "auditLogList" },
  meta: {
    group: "portal",
    isView: true,
  },
  children: [
    {
      name: "auditLogList",
      path: "list",
      meta: {
        title: "감사로그 목록 조회",
        pageName: "감사로그 목록",
      },
      component: () => import("@/views/auditlog/auditLogList"),
    },
    {
      name: "auditLogView",
      path: ":auditLogId",
      meta: {
        title: "감사로그 상세 조회",
        pageName: "감사로그 목록",
      },
      component: () => import("@/views/auditlog/auditLogView"),
      children: [
        {
          name: "auditLogViewDetails",
          path: "detail",
          meta: { title: "감사로그 상세 - 기본정보" },
          component: () =>
            import("@/views/auditlog/components/auditLogViewDetails"),
        },
      ],
    },
  ],
};
