<template>
  <!-- content start-->

  <Form
    v-on:submit="event.onSubmitUpdateMessage"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div class="row mb-4">
      <label
        for="messageId"
        class="col-form-label col-lg-2"
        >메세지 ID</label
      >
      <div class="col-lg-10">
        <Field
          type="text"
          name="messageId"
          id="messageId"
          v-model="dataset.message.messageId"
          :class="{ 'is-invalid': errors.messageId }"
          class="form-control"
          placeholder="메세지 ID를 입력해 주세요."
          disabled
        />
        <div class="invalid-feedback">{{ errors.messageId }}</div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="messageKoName"
        class="col-form-label col-lg-2"
        >메세지(한글)</label
      >
      <div class="col-lg-10">
        <Field
          as="textarea"
          name="messageKoName"
          id="messageKoName"
          v-model="dataset.message.messageKoName"
          :class="{ 'is-invalid': errors.messageKoName }"
          class="form-control"
          placeholder="메세지(한글)를 입력해 주세요."
          rows="4"
        />
        <div class="invalid-feedback">{{ errors.messageKoName }}</div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="messageEnName"
        class="col-form-label col-lg-2"
        >메세지(영문)</label
      >
      <div class="col-lg-10">
        <Field
          as="textarea"
          id="messageEnName"
          name="messageEnName"
          v-model="dataset.message.messageEnName"
          class="form-control"
          :class="{ 'is-invalid': errors.messageEnName }"
          placeholder="메세지(영문)를 입력해 주세요."
          rows="4"
        />
        <div class="invalid-feedback">{{ errors.messageEnName }}</div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="messageDescription"
        class="col-form-label col-lg-2"
        >메세지 설명</label
      >
      <div class="col-lg-10">
        <Field
          as="textarea"
          name="messageDescription"
          id="messageDescription"
          v-model="dataset.message.messageDescription"
          class="form-control"
          placeholder="메세지 설명을 입력해 주세요."
          rows="4"
        />
        <div class="invalid-feedback">
          {{ errors.messageDescription }}
        </div>
      </div>
    </div>
  </Form>
  <!-- data -->

  <!-- content end-->
</template>
<script>
import { getCurrentInstance, ref, onMounted } from "vue";
import { Form, Field, useForm } from "vee-validate";
import * as messageApi from "@/api/portal/messageApi";
import * as Yup from "yup";

export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    let dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      message: {
        messageId: "",
        messageKoName: "",
        messageEnName: "",
        messageDescription: "",
      },
    });

    const schema = Yup.object({
      messageId: Yup.string().required("메세지ID는 필수 입력사항입니다."),
      messageKoName: Yup.string()
        .required("메시지(한글)는 필수 입력사항입니다.")
        .max(2000, "메시지(한글)는 최대 2000글자 입니다."),
      messageEnName: Yup.string().max(
        2000,
        "메시지(영문)는 최대 2000글자 입니다.",
      ),
      messageDescription: Yup.string().max(
        200,
        "메세지 설명은 최대 200글자 입니다.",
      ),
    });

    /*     const checkValid=()=>{
      valid.isValid({
        messageId: dataset.value.message.messageId,
        messageKoName: dataset.value.message.messageKoName,
        // messageEnName: dataset.value.message.messageEnName,
        messageDescription: dataset.value.message.messageDescription

      }).then((validResult)=>{
        if(validResult) createMessage(dataset.value.message);
      }).catch(()=>{
        return false;
      })
    } */
    const { errors } = useForm({
      validationSchema: schema,
    });
    const event = {
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onSubmitUpdateMessage: (values, actions) => {
        messageApi
          .updateMessage(dataset.value.message.messageId, dataset.value.message)
          .then((response) => {
            const successMessage = "수정 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              actions,
              dataset.value.alert,
            );
          });
      },
    };

    // const getMessageView = async (messageId) => {
    //   let result = await messageApi.getMessage(messageId);
    //   if (result != null) {
    //     dataset.value.message = result.resultData;
    //   }
    // };

    onMounted(() => {
      // getMessageView(proxy.$route.query.messageId);
      initDataset();
    });

    const initDataset = () => {
      dataset.value.message = proxy.$modalUtils.getModalData();
    };

    return {
      dataset,
      event,
      schema,
      errors,
      initDataset,
    };
  },
};
</script>
