export default {
  name: "customercodeView",
  path: "/customercode",
  meta: {
    group: "organization",
    isView: true,
  },
  component: () => import("@/views/customercode/customercodeView"),
  redirect: { name: "customercodeViewProjectgroup" },
  children: [
    {
      name: "customercodeViewProjectgroup",
      path: "projectgroup",
      meta: {
        title: "프로젝트그룹 관리",
        pageName: "프로젝트그룹 관리",
      },
      component: () =>
        import("@/views/customercode/components/customercodeViewProjectgroup"),
    },
    {
      name: "customercodeViewAdditionalService",
      path: "addon",
      meta: {
        title: "부가서비스 카테고리 관리",
        pageName: "부가서비스 카테고리 관리",
      },
      component: () =>
        import(
          "@/views/customercode/components/customercodeViewAdditionalService"
        ),
    },
  ],
};
