export default {
  name: "rolemenu",
  path: "/rolemenu",
  component: () => import("@/views/index"),
  redirect: { name: "roleMenuList" },
  meta: {
    group: "portal",
    isView: true,
  },
  children: [
    {
      name: "roleMenuList",
      path: "list",
      meta: {
        title: "역할 메뉴 관리 목록",
        pageName: "역할 메뉴 관리",
      },
      component: () => import("@/views/roleMenu/roleMenuList"),
    }
  ],
};
