<template>
  <div
    v-if="dataset.isLoading"
    class="loading-spinner"
  ></div>
  <template v-if="!dataset.isLoading">
    <DataGrid
      v-bind:headers="dataset.headers"
      v-bind:items="dataset.items"
      v-bind:subPageInfo="dataset.pageInfo"
      v-bind:refresh="false"
      v-bind:body-text-direction="'center'"
      v-bind:loading="dataset.isLoading"
      v-bind:onSelectItems="(item) => (dataset.selectItems = item)"
      v-bind:gridType="`add`"
    >
    </DataGrid>
  </template>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import DataGrid from "@/components/common/vues/grid/itemSelectDataGrid.vue";

export default {
  components: {
    DataGrid,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      headers: [
        { text: "사용자 이름", value: "userName" },
        {
          text: "이메일",
          value: "email",
        },
      ],
      isLoading: true,
      pageInfo: proxy.$pageUtils.getPageInfo({ size: 100 }),
      items: [],
      activeOrganizationId:
        proxy.$store.getters["user/activeOrganization"].organizationId,
      selectItems: [],
    });

    const event = {
      onClickOk: () => {
        return dataset.value.selectItems ?? [];
      },
    };

    const initModalData = () => {
      dataset.value.items = proxy.$modalUtils.getModalData();
      dataset.value.items.forEach((item) => {
        item.controlRowValue = item.userId;
      });
      dataset.value.isLoading = false;
    };

    onMounted(() => {
      initModalData();
    });

    return { event, dataset };
  },
};
</script>
