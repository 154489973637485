import { createRouter, createWebHashHistory } from "vue-router";
import { useCookies } from "vue3-cookies";

import store from "@/store";
import urlUtils from "@/utils/common/urlUtils";
import modalUtils from "@/utils/common/modalUtils";

import cost from "./cost";
import invoice from "./invoice";
import optimization from "./optimization";
import anomaly from "./anomaly";
import user from "./user";
import role from "./role";
import roleMenu from "./roleMenu";
import message from "./message";
import customer from "./customer";
import organization from "./organization";
import organizationmember from "./organizationmember";
import project from "./project";
import workgroup from "./workgroup";
import privilege from "./privilege";
import menu from "./menu";
import cloudaccount from "./cloudaccount";
import taggroup from "./taggroup";
import tag from "./tag";
import usergroup from "./usergroup";
import asset from "./asset";
import scheduler from "./scheduler";
import codeGroup from "./codeGroup";
import code from "./code";
import auditlog from "./auditlog";
import customercode from "./customercode";
import systemsetting from "./systemsetting";

import sample from "./sample";

const anyPathMatch = "/:pathMatch(.*)*";

const routes = [
  {
    name: "root",
    path: "/",
    meta: {
      pageName: "home",
    },
    beforeEnter: (to, from, next) => {
      // 사용자 역할에서 접근 가능한 메뉴 목록 중 가장 첫번째로 발견된 메뉴로 Redirect
      const targetUserRoleMenu = findFirstAccessibleUserRoleMenu();
      if (targetUserRoleMenu) {
        return next(targetUserRoleMenu?.menuUrl);
      } else {
        return next();
      }
    },
    component: () => import("@/views/index"),
  },
  // 일치하는 라우팅 경로가 없으면 404 페이지 반환
  {
    name: "error",
    path: anyPathMatch,
    meta: {
      pageName: "404",
    },
    component: () => import("@/views/error-404"),
  },
];

routes.push(cost);
routes.push(invoice);
routes.push(optimization);
routes.push(anomaly);
routes.push(user);
routes.push(role);
routes.push(roleMenu);
routes.push(message);
routes.push(customer);
routes.push(organization);
routes.push(organizationmember);
routes.push(customercode);
routes.push(project);
routes.push(workgroup);
routes.push(privilege);
routes.push(menu);
routes.push(cloudaccount);
routes.push(tag);
routes.push(taggroup);
routes.push(usergroup);
routes.push(asset);
routes.push(codeGroup);
routes.push(code);
routes.push(auditlog);
routes.push(scheduler);
routes.push(systemsetting);

if (process.env.NODE_ENV !== "production") {
  routes.push(sample);
}

const router = createRouter({ history: createWebHashHistory(), routes });

const { cookies } = useCookies();

/**
 * 사용자 인증 및 역할 기반 접근 제어에 따른 라우트 가드 함수.
 *
 * 1. 인증되지 않은 사용자(비 로그인 상태)는 통과시킨다.
 *    - 인증되지 않은 사용자는 App.vue 에서 throw AuthenticationError() 발생, app.config.errorHandler 에서 로그인 페이지로 이동
 * 2. 대상 경로가 정의된 경로들과 일치하는지 확인하고, 일치하지 않으면 통과시킨다.
 * 3. 사용자의 역할에 연결된 메뉴 URL 기준으로 대상 경로에 대한 사용자의 권한이 있는지 검증한다.
 * 4. 사용자가 대상 경로에 대한 권한이 있으면 라우팅을 승인하고, 그렇지 않으면 경고 메시지를 표시하고 이전 경로로 되돌린다.
 *
 * @param {Object} to - 대상 라우트 객체.
 * @param {Object} from - 현재 라우트 객체.
 * @param {Function} next - 라우트 네비게이션을 제어하는 콜백 함수.
 */
const requireAuth = (to, from, next) => {
  // 사용자 로그인되지 않은 경우 통과
  const isLoggedIn = store.getters["user/isLoggedIn"] && cookies.get("token");
  if (!isLoggedIn) {
    return next();
  }
  // 대상 경로가 유효하지 않은 경로(404)를 포함하는지 여부
  const targetMatchedPathList = to.matched.map((route) => route.path);
  const hasNoMatchedPath = targetMatchedPathList.some((path) => {
    return new RegExp(anyPathMatch).exec(path);
  });
  // 대상 경로가 인증 제외된 경로이거나 유효하지 않은 경로이면 우회
  if (hasNoMatchedPath) {
    return next();
  }
  // 라우팅 접근 제어
  switch (true) {
    // 대상 경로와 일치되는 사용자 역할 메뉴 URL 있으면 라우팅 승인
    case hasAuthorizedUserRoleMenuUrlPath(targetMatchedPathList): {
      return next();
    }
    // 사용자 역할 메뉴 URL 하나도 없으면
    case hasNoUserRoleMenuList(): {
      modalUtils.handleConfirmation(
        "모든 메뉴 접근 권한이 없습니다.<br>담당자에게 연락바랍니다.",
      );
      return next();
    }
    default: {
      modalUtils.handleConfirmation("메뉴 접근 권한이 없습니다.");
      return next(from);
    }
  }
};

// 사용자 역할 메뉴 중 가장 첫번째로 발견된 메뉴
const findFirstAccessibleUserRoleMenu = () => {
  const userRoleMenuList = store.getters["user/roleMenuList"];
  return userRoleMenuList
    ?.sort((prev, next) => {
      const prevUpperMenu = userRoleMenuList.find(
        (menu) => menu?.menuId === prev.upperMenuId,
      );
      const nextUpperMenu = userRoleMenuList.find(
        (menu) => menu?.menuId === next.upperMenuId,
      );
      return prevUpperMenu?.menuOrder - nextUpperMenu?.menuOrder;
    })
    .find((menu) => {
      return menu?.menuType === "SINGLE" && menu?.menuUrl;
    });
};

// 사용자 역할 메뉴 URL 확인 함수
const hasAuthorizedUserRoleMenuUrlPath = (targetMatchedPathList) => {
  return store.getters["user/roleMenuList"]?.some((menu) => {
    const regex = new RegExp(`^${menu?.menuUrl}(?=\/|$)`);
    return regex.exec(urlUtils.filteredRoutePath(targetMatchedPathList));
  });
};

// 사용자가 역할 메뉴 리스트를 가지고 있는지 확인하는 함수
const hasNoUserRoleMenuList = () => {
  return !store.getters["user/roleMenuList"]?.length > 0;
};

router.beforeEach(requireAuth);

export default router;
