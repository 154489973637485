export default {
  name: "role",
  path: "/role",
  component: () => import("@/views/index"),
  redirect: { name: "roleList" },
  meta: {
    group: "portal",
    isView: true,
  },
  children: [
    {
      name: "roleList",
      path: "list",
      meta: {
        title: "역할 목록",
        pageName: "역할 목록",
      },
      component: () => import("@/views/role/roleList"),
    },
    {
      name: "roleView",
      path: ":roleId",
      meta: {
        title: "역할 상세",
        pageName: "역할 상세",
      },
      component: () => import("@/views/role/roleView"),
      children: [
        {
          name: "roleViewDetails",
          path: "details",
          meta: {
            title: "역할 상세-개요",
          },
          component: () => import("@/views/role/components/roleViewDetails"),
        },
        {
          name: "roleViewPrivilegeManage",
          path: "privilege",
          meta: {
            title: "권한 관리",
          },
          component: () => import("@/views/role/components/roleViewPrivilegeManage"),
        },
      ],
    },
  ],
};
