<template>
  <div>
    <Form
      v-on:submit="event.onSubmit"
      v-bind:validation-schema="schema"
      v-slot="{ errors }"
      ref="form"
    >
      <div
        class="row mb-4"
        v-show="dataset.alert.isShow"
      >
        <div
          class="alert alert-danger alert-dismissible fade mb-0"
          :class="{ show: dataset.alert.isShow }"
          role="alert"
        >
          <i class="mdi mdi-alert-circle-outline me-2"></i>
          {{ dataset.alert.message }}
          <button
            type="button"
            class="btn-close"
            v-on:click="event.onClickErrorAlertClose()"
          ></button>
        </div>
      </div>
      <div class="row mb-4 mt-4">
        <label
          for="accountName"
          class="col-form-label col-lg-2"
          >클라우드계정 이름</label
        >
        <div class="col-lg-10">
          <Field
            name="accountName"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.accountName }"
            placeholder="계정 이름을 입력해 주세요."
            v-model="dataset.cloudaccount.accountName"
          />
          <div class="invalid-feedback">{{ errors.accountName }}</div>
        </div>
      </div>
      <div class="row mb-4">
        <label
          for="accountDescription"
          class="col-form-label col-lg-2"
          >클라우드계정 설명</label
        >
        <div class="col-lg-10">
          <Field
            as="textarea"
            rows="3"
            name="accountDescription"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.accountDescription }"
            placeholder="계정 설명을 입력해 주세요."
            v-model="dataset.cloudaccount.accountDescription"
          />
          <div class="invalid-feedback">
            {{ errors.accountDescription }}
          </div>
        </div>
      </div>

      <div class="row mb-4 mt-3">
        <label
          for="providerId"
          class="col-form-label col-lg-2"
          >클라우드 제공사</label
        >
        <div class="col-lg-10">
          <div
            v-for="(item, index) in dataset.providerList"
            v-bind:key="item"
            class="form-check form-check-inline font-size-16"
            v-bind:class="{ 'is-invalid': errors.providerId }"
          >
            <Field
              class="form-check-input"
              type="radio"
              name="providerId"
              v-bind:id="`providerId${index}`"
              v-model="dataset.cloudaccount.cloudserviceproviderId"
              v-bind:class="{
                'is-invalid': errors.providerId,
              }"
              v-bind:value="item.cloudserviceproviderId"
              v-on:change="event.onClickProviderNameShowAccountType()"
              checked
            />
            <label
              class="form-check-label font-size-13"
              v-bind:for="`providerId${index}`"
              >{{ item.cloudserviceproviderName }}</label
            >
          </div>
          <div class="invalid-feedback">
            {{ errors.cloudserviceproviderId }}
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label
          for="accountType"
          class="col-form-label col-lg-2"
          >계정 유형</label
        >
        <div
          v-if="!dataset.cloudaccount.cloudserviceproviderId"
          class="col-lg-10 text-muted my-auto"
        >
          <p class="my-auto">클라우드 제공사를 선택해 주세요</p>
        </div>
        <div class="col-lg-10">
          <div
            v-for="(item, index) in dataset.accountTypeList"
            v-bind:key="item"
            class="form-check form-check-inline"
            v-bind:class="{ 'is-invalid': errors.accountType }"
          >
            <Field
              class="form-check-input"
              type="radio"
              name="accountType"
              v-bind:id="`accountType${index}`"
              v-model="dataset.cloudaccount.accountType"
              v-bind:class="{ 'is-invalid': errors.accountType }"
              v-on:change="event.onClickAccountTypeShowParameter()"
              v-bind:value="item.accountType"
              checked
            />
            <label
              class="form-check-label"
              v-bind:for="`accountType${index}`"
              >{{ item.accountType }}</label
            >
          </div>
          <div class="invalid-feedback">{{ errors.accountType }}</div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-lg-2">
          <label class="col-form-label">인증 정보</label>
        </div>

        <div
          class="col-lg-10 text-muted"
          v-if="!dataset.cloudaccount.accountType"
        >
          <p>계정 유형을 선택해 주세요</p>
        </div>
        <div
          class="col-lg-10 text-muted"
          v-else
        >
          <select
            class="form-select"
            v-model="dataset.currentResourceTypeCode"
          >
            <option
              v-for="(item, index) in dataset.credentialFieldList"
              :key="index"
              :value="item.resourceTypeCode"
            >
              {{ item.resourceTypeName }}
            </option>
          </select>
          <div
            class="mt-3"
            v-for="(item, index) in dataset.credentialFieldList"
            :key="index"
          >
            <div
              v-show="item.resourceTypeCode === dataset.currentResourceTypeCode"
            >
              <div class="p-4 border">
                <table class="table mb-0">
                  <thead>
                    <tr class="align-middle">
                      <th class="text-center w-10">순번</th>
                      <th>필드명</th>
                      <th class="text-center w-70">값</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(_item, _index) in item.resourceFieldList"
                      :key="_index"
                    >
                      <td class="text-center">
                        {{ _index + 1 }}
                      </td>
                      <td>{{ _item.field }}</td>

                      <td>
                        <div v-if="_item.memberList">
                          <div
                            class="d-inline p-2 ps-0"
                            v-for="(region, i) in _item.memberList"
                            v-bind:key="i"
                          >
                            <Field
                              class="form-check-input"
                              type="radio"
                              name="region"
                              v-bind:id="`region_${region.value}`"
                              v-bind:value="region.value"
                              v-model="_item.value"
                              checked
                            />
                            <label
                              class="form-check-label"
                              v-bind:for="`region_${region.value}`"
                              >{{ region.name }}</label
                            >
                          </div>
                        </div>
                        <div v-else>
                          <input
                            v-bind:disabled="
                              item.resourceTypeCode !==
                              dataset.currentResourceTypeCode
                            "
                            type="text"
                            v-model="_item.value"
                            class="form-control"
                            placeholder="값을 입력해 하세요."
                          />
                        </div>
                        <pre class="form-text text-muted w-100">{{
                          _item.description
                        }}</pre>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import cloudaccountApi from "@/api/portal/cloudaccountApi";
import cloudserviceproviderApi from "@/api/portal/cloudserviceproviderApi";
import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";

export default {
  components: { Form, Field },
  setup() {
    const { getters } = useStore();
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      providerList: [],
      accountTypeList: [],
      credentialFieldList: [],
      regionList: ["KR1", "KR2", "JP1"],
      cloudaccount: {
        customerId: getters["user/activeCustomer"].customerId,
        cloudserviceproviderId: "",
        accountType: "",
        accountName: "",
        accountDescription: "",
        credentialRequest: {
          credential: [],
        },
      },
      currentResourceTypeCode: "",
      ui: {
        modal: {
          id: "modalCloudaccountWrite",
          title: "클라우드계정 등록",
        },
        tab: {
          currentTab: "detail",
          list: [
            { name: "기본정보", id: "detail" },
            { name: "인증정보", id: "loginField" },
          ],
        },
      },
    });

    // validation schema
    const schema = yup.object().shape({
      providerId: yup.string().required("클라우드제공사는 필수 입력값 입니다."),
      accountType: yup.string().required("계정 유형은 필수 입력값 입니다."),
      accountName: yup.string().required("계정 이름은 필수 입력값 입니다."),
      accountDescription: yup
        .string()
        .max(200, "입력할 수 있는 글자수를 초과했습니다."),
      // regionType: yup.string().required("리전타입은 필수 입력 값입니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (values, actions) => {
        dataset.value.cloudaccount.credentialRequest.credential =
          setCloudaccountBeforeCreate();
        dataset.value.cloudaccount.accountType =
          dataset.value.cloudaccount.accountType.toLowerCase();
        cloudaccountApi
          .createCloudaccount(dataset.value.cloudaccount)
          .then((response) => {
            const successMessage = "생성 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              actions,
              dataset.value.alert,
            );
            // emit("initDataset");
          });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      // onClickOpenModal: () => {
      //   $(`#${dataset.value.ui.modal.id}`).modal("show");
      // },
      // onClickCloseModal: () => {
      //   dataset.value.credentialFieldList = null;
      //   dataset.value.accountTypeList = [];
      //   proxy.$refs.form.resetForm(); // form init
      //   proxy.event.onClickErrorAlertClose(); // alert init
      //   $(`#${dataset.value.ui.modal.id}`).modal("hide"); // modal close
      // },
      onClickErrorAlertClose: () => {
        // 필드 에러 외 에러메세지 close
        dataset.value.alert.isShow = false;
      },
      onClickProviderNameShowAccountType: () => {
        getCloudAccountTypeList();
      },
      onClickAccountTypeShowParameter: () => {
        filterLoginFieldByAccountType();
      },
      onClickTabChange: (event, tabName) => {
        event.stopPropagation();
        dataset.value.ui.tab.currentTab = tabName;
      },
      onChangeRegion: (region, value) => {
        region = value;
      },
    };

    const setCredentialFormInput = (loginField) => {
      if (loginField) {
        loginField.forEach((f) => {
          f.resourceFieldList.forEach((r) => {
            r.value = "";
          });
        });
      }
      return loginField;
    };
    // const transferVaultSecret = () => {
    //   return setCloudaccountBeforeCreate();
    // };
    const setCloudaccountBeforeCreate = () => {
      let credentialFieldList = dataset.value.credentialFieldList.filter(
        (e) => e.resourceTypeCode === dataset.value.currentResourceTypeCode,
      );
      dataset.value.cloudaccount.fieldType =
        credentialFieldList[0].resourceTypeCode;
      dataset.value.cloudaccount.fieldTypeName =
        credentialFieldList[0].resourceTypeName;
      return credentialFieldList.map((r) => {
        return {
          resourceTypeName: r.resourceTypeName ?? r.resourceName,
          resourceFieldList: setCredentialFieldListItem(r.resourceFieldList),
          resourceTypeCode: r.resourceTypeCode,
        };
      });
    };

    const filterLoginFieldByAccountType = () => {
      // dataset.value.cloudaccount.credentialFieldList = [];
      dataset.value.accountTypeList.forEach((e) => {
        if (e.accountType === dataset.value.cloudaccount.accountType) {
          dataset.value.currentResourceTypeCode =
            e.accountFieldList[0].resourceTypeCode;
          dataset.value.credentialFieldList = setCredentialFormInput(
            e.accountFieldList,
          );
          // dataset.value.cloudaccount.credentialRequest.type =
          //   dataset.value.cloudaccount.accountType;
        }
      });
    };
    const setCredentialFieldListItem = (fieldList) => {
      const field = fieldList.filter((item) => {
        return item.field === "TENANT_ID";
      });
      dataset.value.cloudaccount.tenantId = field[0]?.value;

      return fieldList.map((e) => {
        return {
          field: e.field,
          isHeader: e.isHeader,
          value: e.value,
        };
      });
    };
    const getCloudAccountTypeList = () => {
      let params = {
        cloudserviceproviderId:
          dataset.value.cloudaccount.cloudserviceproviderId,
        accounType: "",
        isParameter: true,
      };
      cloudaccountApi.getLoginCredentialFieldList(params).then((response) => {
        dataset.value.accountTypeList = [];
        if (response.isSuccessful) {
          dataset.value.accountTypeList = response.resultData.field;
        }
      });
    };
    const getCloudServiceProvider = () => {
      cloudserviceproviderApi
        .getCloudServiceProviderList({ useYn: true })
        .then((response) => {
          if (response.isSuccessful) {
            dataset.value.providerList = response.resultData;
          }
        });
    };

    onMounted(() => {
      getCloudServiceProvider();
    });
    return { dataset, schema, event, errors };
  },
};
</script>
