<template>
  <div
    class="com-alert"
    v-show="handle.isShow"
  >
    <div class="custom-modal custom-modal-sm custom-modal-light">
      <div class="d-flex justify-content-end mx-3 mt-3">
        <button
          type="button"
          class="btn-close"
          v-on:click="onClickBtnClose"
        ></button>
      </div>
      <div
        v-show="handle.isAlert"
        class="custom-modal-contents"
      >
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <div class="custom-modal-body custom-modal-body-center">
              <div class="row">
                <div class="col-lg-12">
                  <div class="text-center">
                    <h1 class="display-7 fw-medium">
                      <i class="bx bx-error text-primary display-3"></i><br />
                      {{ handle.msg.error }}
                    </h1>
                    <h4 class="text-uppercase">{{ handle.msg.message }}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="custom-modal-footer custom-modal-footer-center">
              <button
                type="button"
                class="btn btn-primary waves-effect waves-light"
                v-on:click="onClickBtnClose"
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="handle.isConfirm"
        class="custom-modal-contents"
      >
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <div class="custom-modal-body custom-modal-body-center">
              <div class="row">
                <div class="col-lg-12">
                  <div class="text-center">
                    <h1 class="display-7 fw-medium">
                      <i class="bx bx-error text-primary display-3"></i><br />
                      {{ handle.msg.error }}
                    </h1>
                    <h4 class="text-uppercase">{{ handle.msg.message }}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="custom-modal-footer custom-modal-footer-center">
              <button
                type="button"
                class="btn btn-secondary waves-effect waves-light"
                v-on:click="onClickBtnClose"
              >
                닫기
              </button>
              <button
                type="button"
                class="btn btn-primary waves-effect waves-light"
                v-on:click="onClickBtnConfirm"
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  setup() {
    let callbackFunc;
    let callbackData;
    let handle = ref({
      isShow: false,
      isAlert: false,
      isConfirm: false,
      msg: {
        error: "",
        status: "500",
        message: "시스템 오류.<br/>이용에 불편을 드려 죄송합니다.",
      },
    });
    const fnShowAlert = (msg) => {
      handle.value.isShow = true;
      handle.value.isAlert = true;
      handle.value.isConfirm = false;
      handle.value.msg = msg;
    };
    const fnShowConfirm = (msg, callback) => {
      handle.value.isShow = true;
      handle.value.isAlert = false;
      handle.value.isConfirm = true;
      handle.value.msg = msg;
      callbackFunc = callback;
      callbackData = msg.callbackData;
    };
    const onClickBgClose = () => {
      setTimeout(() => {
        handle.value.isShow = false;
        handle.value.msg = "시스템 오류. 이용에 불편을 드려 죄송합니다.";
      }, 800);
    };
    const onClickBtnConfirm = () => {
      callbackFunc(callbackData);
      onClickBtnClose();
    };

    const onClickBtnClose = () => {
      handle.value.isShow = false;
      handle.value.msg = "시스템 오류. 이용에 불편을 드려 죄송합니다.";
    };
    return {
      handle,
      onClickBtnClose,
      fnShowAlert,
      fnShowConfirm,
      onClickBtnConfirm,
      onClickBgClose,
    };
  },
};
</script>
