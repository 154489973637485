export default{
    name: "menu",
    path: "/menu",
    component: () => import("@/views/index"),
    redirect: { name: "menuList" },
    meta: {
      group: "portal",
      isView: true,
    },
    children: [
        {
            name: "menuList",
            path: "list",
            meta: {
                title: "메뉴 목록 조회",
                pageName: "메뉴 관리",
            },
            component: () => import("@/views/menu/menuList")
        }
    ]
}