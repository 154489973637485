export default {
  name: "organization",
  path: "/organization",
  component: () => import("@/views/index"),
  redirect: { name: "organizationList" },
  meta: {
    group: "portal",
    isView: true,
  },
  children: [
    {
      name: "organizationList",
      path: "list",
      meta: {
        title: "조직 목록",
        pageName: "조직 목록",
      },
      component: () => import("@/views/organization/organizationList"),
    },
    {
      name: "organizationView",
      path: ":organizationId",
      meta: {
        title: "조직 상세",
        pageName: "조직 상세",
      },
      component: () => import("@/views/organization/organizationView"),
      children: [
        {
          name: "organizationViewDetails",
          path: "details",
          meta: {
            title: "조직 상세-개요",
          },
          component: () => import("@/views/organization/components/organizationViewDetails"),
        },
        {
          name: "organizationViewUserManage",
          path: "user",
          meta: {
            title: "사용자 관리",
          },
          component: () => import("@/views/organization/components/organizationViewUserManage"),
        },
      ],
    },
  ],
};
