<template>
  <div
    v-if="dataset.isLoading"
    class="loading-spinner"
  ></div>
  <template v-if="!dataset.isLoading">
    <div class="d-flex justify-content-sm-end gap-2">
      <div>
        <div class="input-group">
          <select
            class="form-select form-select-sm w-80px"
            v-model="dataset.search.projectGroup"
            @change="event.onChangeSearch"
          >
            <option value="">프로젝트 구분</option>
            <option
              v-for="item in dataset.codeMapList"
              :key="item.codeId"
              :value="item.codeId"
            >
              {{ item.codeName }}
            </option>
          </select>
        </div>
      </div>

      <div>
        <div class="input-group">
          <select
            class="form-select form-select-sm w-125px"
            v-model="dataset.search.keywordType"
          >
            <option value="projectName">프로젝트 이름</option>
            <option value="projectId">프로젝트 ID</option>
          </select>
          <input
            type="search"
            class="form-control form-control-sm w-200px"
            id="searchInput"
            placeholder="검색어를 입력하세요"
            v-model="dataset.search.keyword"
            v-on:keydown.enter="event.onChangeSearch($event)"
          />
        </div>
      </div>
    </div>
    <DataGrid
      v-bind:headers="dataset.headers"
      v-bind:items="dataset.items"
      v-bind:subPageInfo="dataset.pageInfo"
      v-bind:refresh="false"
      v-bind:body-text-direction="'center'"
      v-bind:loading="dataset.isLoading"
      v-bind:onSelectItems="(item) => (dataset.selectItems = item)"
      v-bind:gridType="`add`"
      v-bind:customHeader="true"
    >
    </DataGrid>
  </template>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { useStore } from "vuex";
import DataGrid from "@/components/common/vues/grid/itemSelectDataGrid.vue";
import customerCodeApi from "@/api/portal/customerCodeApi";
export default {
  components: {
    DataGrid,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const { getters } = useStore();
    const dataset = ref({
      headers: [
        {
          text: "프로젝트 구분",
          value: "projectGroupName",
          textAlign: "text-center",
        },
        {
          text: "프로젝트ID",
          value: "projectId",
          textAlign: "text-center",
        },
        {
          text: "프로젝트 이름",
          value: "projectName",
          textAlign: "text-center",
        },
        {
          text: "유형",
          value: "projectTypeName",
          textAlign: "text-center",
        },
      ],
      isLoading: true,
      pageInfo: proxy.$pageUtils.getPageInfo({ size: 100 }),
      items: [],
      selectItems: [],
      search: {
        projectGroup: "",
        keywordType: "projectName",
        keyword: "",
      },
      codeMap: {},
      codeMapList: [],
      copyItems: [],
    });

    const event = {
      onClickOk: () => {
        return dataset.value.selectItems ?? [];
      },
      onChangeSearch: () => {
        const newGroup = dataset.value.search.projectGroup;
        const newKeyWordType = dataset.value.search.keywordType;
        const newKeyWord = dataset.value.search.keyword;

        dataset.value.items = dataset.value.copyItems.filter((item) => {
          if (newGroup === "") {
            return item[newKeyWordType]
              .toLowerCase()
              .includes(newKeyWord.toLowerCase());
          }
          return (
            item.projectGroup === newGroup &&
            item[newKeyWordType]
              .toLowerCase()
              .includes(newKeyWord.toLowerCase())
          );
        });
      },
    };

    const initModalData = () => {
      dataset.value.items = proxy.$modalUtils.getModalData();
      dataset.value.items.forEach((item) => {
        item.controlRowValue = item.projectId;
      });
      dataset.value.copyItems = proxy.$objectUtils.deepCopy(
        dataset.value.items,
      );
      dataset.value.isLoading = false;
    };

    const fnGetCodeMapByCodeGroupId = async () => {
      const result = await customerCodeApi.getMap(
        getters["user/activeCustomer"].customerId,
        "PROJECT_GROUP",
      );
      if (result.isSuccessful) {
        dataset.value.codeMap = result.resultData;
        dataset.value.codeMapList = Object.values(result.resultData);
      }
    };

    onMounted(async () => {
      await fnGetCodeMapByCodeGroupId();
      initModalData();
    });

    return { event, dataset };
  },
};
</script>
