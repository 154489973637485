<template>
  <!-- Nav ta start -->
  <div>
    <Form
      v-on:submit="event.onSubmit"
      v-bind:validation-schema="schema"
      v-slot="{ errors }"
      ref="form"
    >
      <div
        class="row mb-4"
        v-show="dataset.alert.isShow"
      >
        <div
          class="alert alert-danger alert-dismissible fade mb-0"
          :class="{ show: dataset.alert.isShow }"
          role="alert"
        >
          <i class="mdi mdi-alert-circle-outline me-2"></i>
          {{ dataset.alert.message }}
          <button
            type="button"
            class="btn-close"
            v-on:click="event.onClickErrorAlertClose()"
          ></button>
        </div>
      </div>

      <div class="row mb-4 mt-4">
        <label
          for="accountName"
          class="col-form-label col-lg-2"
          >클라우드계정 이름</label
        >
        <div class="col-lg-10">
          <Field
            name="accountName"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.accountName }"
            placeholder="계정 이름을 입력해 주세요."
            v-model="dataset.cloudaccount.accountName"
          />
          <div class="invalid-feedback">{{ errors.accountName }}</div>
        </div>
      </div>
      <div class="row mb-4">
        <label
          for="accountDescription"
          class="col-form-label col-lg-2"
          >클라우드계정 설명</label
        >
        <div class="col-lg-10">
          <Field
            as="textarea"
            rows="3"
            name="accountDescription"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.accountDescription }"
            placeholder="계정 설명을 입력해 주세요."
            v-model="dataset.cloudaccount.accountDescription"
          />
          <div class="invalid-feedback">
            {{ errors.accountDescription }}
          </div>
        </div>
      </div>

      <div class="row mb-4 mt-3">
        <span
          for="providerId"
          class="col-lg-2"
          >클라우드 제공사</span
        >
        <div class="col-lg-10">
          <div
            v-for="(item, index) in dataset.providerList"
            v-bind:key="item"
            class="form-check form-check-inline"
            v-bind:class="{ 'is-invalid': errors.providerId }"
          >
            <label
              class="form-check-label"
              v-bind:for="`providerId${index}`"
            >
              <Field
                class="form-check-input"
                type="radio"
                name="cloudserviceproviderId"
                v-bind:id="`cloudserviceproviderId${index}`"
                v-model="dataset.cloudaccount.cloudserviceproviderId"
                v-bind:class="{
                  'is-invalid': errors.cloudserviceproviderId,
                }"
                :disabled="
                  item.cloudserviceproviderId !==
                  dataset.cloudaccount.cloudserviceproviderId
                "
                v-bind:value="item.cloudserviceproviderId"
                checked
              />{{ item.cloudserviceproviderName }}</label
            >
          </div>
          <div class="invalid-feedback">
            {{ errors.cloudserviceproviderId }}
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <span
          for="accountType"
          class="col-lg-2"
          >계정 유형</span
        >

        <div class="col-lg-10">
          <div
            v-for="(item, index) in dataset.accountTypeList"
            v-bind:key="item"
            class="form-check form-check-inline"
            v-bind:class="{ 'is-invalid': errors.accountType }"
          >
            <label
              class="form-check-label"
              :for="`accountType${index}`"
              ><Field
                class="form-check-input"
                type="radio"
                name="accountType"
                :id="`accountType${index}`"
                v-model="dataset.cloudaccount.accountType"
                :class="{ 'is-invalid': errors.accountType }"
                :value="item.accountType"
                checked
                :disabled="
                  item.accountType !== dataset.cloudaccount.accountType
                "
              />{{ item.accountType }}</label
            >
          </div>
          <div class="invalid-feedback">{{ errors.accountType }}</div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-lg-2">
          <label class="col-form-label">인증 정보</label>
        </div>
        <div class="col-lg-10 text-muted">
          <select
            class="form-select"
            v-model="dataset.currentFieldTypeCode"
            disabled
          >
            <option
              v-for="(item, index) in dataset.credentialFieldList"
              :key="index"
              :value="item.resourceTypeCode"
              v-show="item.resourceTypeCode === dataset.currentFieldTypeCode"
            >
              {{ item.resourceTypeName }}
            </option>
          </select>
          <div
            class="mt-3"
            v-for="(item, index) in dataset.credentialFieldList"
            :key="index"
          >
            <div
              v-show="item.resourceTypeCode === dataset.currentFieldTypeCode"
            >
              <div class="p-4 border">
                <table class="table mb-0">
                  <thead>
                    <tr class="align-middle">
                      <th class="text-center w-10">순번</th>
                      <th>필드명</th>
                      <th class="text-center w-70">값</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="mb-1 p-1"
                      v-for="(_item, _index) in item.resourceFieldList"
                      :key="_index"
                    >
                      <td class="text-center">
                        {{ _index + 1 }}
                      </td>
                      <td>{{ _item.field }}</td>

                      <td>
                        <div v-if="_item.memberList">
                          <div
                            class="d-inline p-2 ps-0"
                            v-for="(region, i) in _item.memberList"
                            v-bind:key="i"
                          >
                            <label
                              class="form-check-label"
                              v-bind:for="`region_${region.value}`"
                              ><Field
                                class="form-check-input"
                                type="radio"
                                name="region"
                                v-bind:id="`region_${region.value}`"
                                v-bind:value="region.value"
                                v-model="_item.value"
                                checked
                              />{{ region.name }}</label
                            >
                          </div>
                        </div>
                        <div v-else>
                          <input
                            v-bind:disabled="
                              item.resourceTypeCode !==
                              dataset.currentFieldTypeCode
                            "
                            type="text"
                            v-model="_item.value"
                            class="form-control"
                            placeholder="값을 입력해 하세요."
                          />
                        </div>
                        <pre class="form-text text-muted w-100">{{
                          _item.description
                        }}</pre>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import cloudaccountApi from "@/api/portal/cloudaccountApi";
import cloudserviceproviderApi from "@/api/portal/cloudserviceproviderApi";
import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";
// import { useStore } from "vuex";
export default {
  props: ["rowData"],
  components: { Form, Field },
  setup() {
    // const { getters } = useStore();
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      providerList: [],
      accountTypeList: [],
      credentialFieldList: null,
      cloudaccount: {
        cloudserviceproviderId: "",
        accountType: "",
        accountName: "",
        accountDescription: "",
        credentialResponseResult: {},
        cloudaccountId: "",
        customerId: "",
      },
      credentialRequest: {
        credential: [],
      },
      currentFieldTypeCode: "", // 클라우드 계정은 하나의 fieldType만 선택 가능.
      ui: {
        modal: {
          id: "modalCloudaccountEdit",
          title: "클라우드계정 수정",
        },
        tab: {
          currentTab: "detail",
          list: [
            { name: "기본정보", id: "detail" },
            { name: "인증정보", id: "loginField" },
          ],
        },
      },
    });

    /**
     * const { cloudaccount, credentialRequest } = dataset.value
     * { cloudaccount: { ...cloudaccount,  credentialRequest: credentialRequest }
     *
     */
    const schema = yup.object().shape({
      accountType: yup.string().required("계정 유형은 필수 입력값 입니다."),
      accountName: yup.string().required("계정 이름은 필수 입력값 입니다."),
      accountDescription: yup
        .string()
        .max(200, "입력할 수 있는 글자수를 초과했습니다."),
      cloudserviceproviderId: yup
        .string()
        .required("클라우드제공사는 필수 입력값 입니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: async (values, actions) => {
        let toLower = dataset.value.cloudaccount.accountType.toLowerCase();
        dataset.value.cloudaccount.accountType = toLower;
        setCloudaccountBeforeUpdate();
        let data = {
          accountName: dataset.value.cloudaccount.accountName,
          accountDescription: dataset.value.cloudaccount.accountDescription,
          cloudaccountId: dataset.value.cloudaccount.cloudaccountId,
          accountType: dataset.value.cloudaccount.accountType,
          cloudserviceproviderId:
            dataset.value.cloudaccount.cloudserviceproviderId,
          credentialRequest: dataset.value.credentialRequest,
          customerId: dataset.value.cloudaccount.customerId,
          tenantId: dataset.value.cloudaccount.tenantId,
          fieldType: dataset.value.cloudaccount.fieldType,
          fieldTypeName: dataset.value.cloudaccount.fieldTypeName,
        };
        cloudaccountApi
          .updateCloudaccount(dataset.value.cloudaccount.cloudaccountId, data)
          .then((response) => {
            const successMessage = "수정 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              actions,
              dataset.value.alert,
            );
          });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickCloseModal: () => {
        dataset.value.credentialFieldList = null;
        dataset.value.accountTypeList = [];
        proxy.$refs.form.resetForm(); // form init
        proxy.event.onClickErrorAlertClose(); // alert init
        // $(`#${dataset.value.ui.modal.id}`).modal("hide"); // modal close
      },
      onClickErrorAlertClose: () => {
        dataset.value.alert.isShow = false;
      },
      onClickProviderNameShowAccountType: () => {
        getCloudAccountTypeList();
      },
      onClickTabChange: (tabName) => {
        dataset.value.ui.tab.currentTab = tabName;
      },
    };

    const filterLoginFieldByAccountType = () => {
      dataset.value.credentialFieldList = [];
      dataset.value.accountTypeList?.forEach((e) => {
        if (e.accountType === dataset.value.cloudaccount.accountType) {
          dataset.value.credentialFieldList = setCredentialFormInput(
            e.accountFieldList,
          );
        }
      });
    };

    const setCredentialInput = () => {
      let credentialList = dataset.value.credentialFieldList.filter(
        (e) => e.resourceTypeCode === dataset.value.currentFieldTypeCode,
      );
      dataset.value.cloudaccount.fieldType = credentialList[0].resourceTypeCode;
      dataset.value.cloudaccount.fieldTypeName =
        credentialList[0].resourceTypeName;
      return credentialList.map((r) => {
        return {
          resourceTypeName: r.resourceTypeName ?? r.resourceName,
          resourceFieldList: setCredentialFieldListItem(r.resourceFieldList),
          resourceTypeCode: r.resourceTypeCode,
        };
      });
    };

    const setCloudaccountBeforeUpdate = () => {
      dataset.value.credentialRequest.credential = setCredentialInput();
    };

    const setCredentialFieldListItem = (fieldList) => {
      const field = fieldList.filter((item) => {
        return item.field === "TENANT_ID";
      });
      dataset.value.cloudaccount.tenantId = field[0]?.value;

      return fieldList.map((e) => {
        return { field: e.field, isHeader: e.isHeader, value: e.value };
      });
    };

    const setCredentialFormInput = (accountFieldList) => {
      // vault에 저장된 자격증명(credential)
      let credential =
        dataset.value.cloudaccount.credentialResponseResult.data.credential?.find(
          (item) =>
            item.resourceTypeCode === dataset.value.currentFieldTypeCode,
        );

      // cloudaccount 필드 정보
      let accountField = accountFieldList?.find(
        (item) => item.resourceTypeCode == dataset.value.currentFieldTypeCode,
      );

      if (!credential || !accountField) {
        return [];
      }

      accountField.resourceFieldList.map((accountItem) => {
        let matchedCredentialItem = credential.resourceFieldList.find(
          (credentialItem) => {
            return credentialItem.field === accountItem.field;
          },
        );
        accountItem.value = matchedCredentialItem?.value || "";
      });
      return [accountField];
    };

    const initModalData = () => {
      dataset.value.cloudaccount = proxy.$modalUtils.getModalData();
      proxy.$modalUtils.getModalData() && getCloudAccount();
    };
    const getCloudAccountTypeList = async (isParameter) => {
      let params = {
        cloudserviceproviderId:
          dataset.value.cloudaccount.cloudserviceproviderId,
        accountType: "",
        isParameter: isParameter ?? true,
      };
      let response = await cloudaccountApi.getLoginCredentialFieldList(params);
      if (response.isSuccessful) {
        dataset.value.accountTypeList = [];
        dataset.value.accountTypeList = response.resultData.field;
        filterLoginFieldByAccountType();
      }
    };

    const getCloudServiceProvider = async () => {
      let result = await cloudserviceproviderApi.getCloudServiceProviderList({
        useYn: true,
      });
      if (result.isSuccessful) {
        dataset.value.providerList = result.resultData;
      }
    };

    const getCloudAccount = () => {
      let param = {
        customerId: dataset.value.cloudaccount.customerId,
        cloudserviceproviderId:
          dataset.value.cloudaccount.cloudserviceproviderId,
      };
      cloudaccountApi
        .getCloudAccount(dataset.value.cloudaccount.cloudaccountId, param)
        .then((response) => {
          if (response.isSuccessful) {
            dataset.value.cloudaccount = response.resultData;
            dataset.value.currentFieldTypeCode =
              dataset.value.cloudaccount.credentialResponseResult.data.credential[0].resourceTypeCode;
            dataset.value.cloudaccount.accountType =
              dataset.value.cloudaccount.accountType.toUpperCase();
            getCloudAccountTypeList();
          }
        });
    };

    onMounted(() => {
      getCloudServiceProvider();
      initModalData();
    });
    return { dataset, schema, event, errors };
  },
};
</script>
