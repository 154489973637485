export const monthMapping = {
  JAN: 1,
  FEB: 2,
  MAR: 3,
  APR: 4,
  MAY: 5,
  JUN: 6,
  JUL: 7,
  AUG: 8,
  SEP: 9,
  OCT: 10,
  NOV: 11,
  DEC: 12,
};

export function toMonthNumber(alias) {
  let number = monthMapping[alias];
  if (number == undefined) {
    throw new Error("unhandled alias " + alias);
  }
  return number;
}

export function toMonthAlias(num) {
  let alias = Object.keys(monthMapping).find((k) => monthMapping[k] === num);
  if (alias == undefined) {
    throw new Error(`unhandled number ${num}`);
  }
  return alias;
}

export function isMonthAlias(s) {
  return Object.keys(monthMapping).includes(s);
}
