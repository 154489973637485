<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>
    <div
      class="row mb-4"
      :class="!dataset.duplicateCodeGroupId ? 'was-validated' : ''"
    >
      <label
        for="codegroupId"
        class="col-form-label col-lg-2"
        >그룹코드 ID</label
      >
      <div class="col-lg-10">
        <div class="input-group">
          <Field
            id="codegroupId"
            name="codegroupId"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.codegroupId }"
            placeholder="그룹코드 ID를 입력해 주세요."
            v-model.trim="dataset.codeGroup.codegroupId"
            autocomplete="off"
            maxlength="36"
            v-on:keydown.enter="event.onClickDuplicateCodeGroup()"
          />
          <div class="input-group-append">
            <button
              class="btn btn-primary ms-1"
              type="button"
              v-on:click="event.onClickDuplicateCodeGroup()"
            >
              <i class="bx bx-check-double font-size-16 align-middle me-1" />
              중복 확인
            </button>
          </div>
          <div
            :class="
              dataset.duplicateCodeGroupId
                ? 'invalid-feedback'
                : 'valid-feedback'
            "
          >
            {{ errors.codegroupId }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codegroupName"
        class="col-form-label col-lg-2"
        >그룹코드 이름</label
      >
      <div class="col-lg-10">
        <Field
          name="codegroupName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.codegroupName }"
          placeholder="그룹코드 이름을 입력해주세요"
          v-model.trim="dataset.codeGroup.codegroupName"
          maxlength="40"
        />
        <div class="invalid-feedback">
          {{ errors.codegroupName }}
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted, watch } from "vue";
import { Form, Field, useForm } from "vee-validate";
import codeGroupApi from "@/api/portal/codegroupApi";
import * as yup from "yup";
export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      codeGroup: {
        codegroupId: "",
        codegroupName: "",
      },
      prevCodeGroupId: "",
      duplicateCodeGroupId: true,
    });

    const schema = yup.object().shape({
      codegroupId: yup
        .string()
        .min(1, "1글자 이상 36글자 이하로 입력해주세요")
        .max(36, "1글자 이상 36글자 이하로 입력해주세요")
        .required("코드그룹 ID는 필수 입력값 입니다."),
      codegroupName: yup
        .string()
        .min(1, "1글자 이상 40 글자 이하로 입력해주세요")
        .max(40, "1글자 이상 40글자 이하로 입력해주세요")
        .required("코드그룹 이름은 필수 입력값 입니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: async (value, actions) => {
        if (
          dataset.value.prevCodeGroupId !==
            dataset.value.codeGroup.codegroupId ||
          dataset.value.duplicateCodeGroupId
        ) {
          proxy.$awn.warning("중복체크를 해주세요.");
          return false;
        }

        codeGroupApi
          .createCodeGroup(dataset.value.codeGroup)
          .then((response) => {
            const successMessage = "생성 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              actions,
              dataset.value.alert,
            );
          });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        dataset.value.alert.isShow = false;
      },
      onClickDuplicateCodeGroup: async () => {
        dataset.value.prevCodeGroupId = dataset.value.codeGroup.codegroupId;
        fnDuplicateCodeGroup();
      },
    };

    const fnDuplicateCodeGroup = async () => {
      const rs = await codeGroupApi.getDuplicateCodeGroup({
        codegroupId: dataset.value.codeGroup.codegroupId,
      });
      try {
        if (rs.isSuccessful) {
          dataset.value.duplicateCodeGroupId = rs.resultData;
          rs.resultData
            ? setFieldError("codegroupId", "이미 존재하는 아이디입니다.")
            : setFieldError("codegroupId", "사용 가능한 코드입니다.");
        }
      } catch (error) {
        console.log(error);
      }
    };

    const setFieldError = (id, message) => {
      proxy.$refs.form.setFieldError(id, message);
    };

    watch(
      () => dataset.value.codeGroup.codegroupId,
      (/* value */) => {
        dataset.value.duplicateCodeGroupId = true;
        setFieldError("codegroupId", "");
      },
    );

    onMounted(() => {});
    return { event, dataset, errors, schema };
  },
};
</script>
