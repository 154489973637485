<template>
  <div>
    <div class="row my-1">
      <div class="col-sm-auto">
        총 <span class="fw-bold"> {{ items.length }}</span> 건
      </div>
      <div class="col-sm">
        <div class="d-flex justify-content-sm-end gap-1">
          <div
            v-if="refresh"
            class="form-group"
          >
            <a
              href="#!"
              class="btn btn-light btn-sm"
              v-on:click.prevent="event.onClickRefresh()"
              ><i class="mdi mdi-refresh">새로고침</i></a
            >
          </div>
          <div class="col-sm">
            <div class="d-flex justify-content-sm-end gap-2">
              <div class="form-group">
                <select
                  class="form-select form-select-sm"
                  v-model="dataset.searchField"
                >
                  <template
                    v-for="header in headers"
                    v-bind:key="header"
                  >
                    <option
                      v-if="
                        !['actions', '_actions', 'customHeader'].includes(
                          header.value,
                        )
                      "
                      v-bind:value="header.value"
                    >
                      {{ header.text }}
                    </option>
                  </template>
                </select>
              </div>
              <div class="form-group">
                <input
                  type="search"
                  class="form-control form-control-sm"
                  id="searchInput"
                  placeholder="검색어를 입력하세요"
                  v-model="dataset.searchValue"
                />
              </div>
            </div>
          </div>
          <div
            class="form-group"
            v-if="changeRowsPerPage"
          >
            <select
              class="form-select form-select-sm"
              v-on:change="event.onChangeRowsPerPage"
            >
              <option
                v-for="item in dataset.rowsPerPageOptions"
                :key="item"
                :selected="item === dataset.rowsPerPageActiveOption"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <Vue3EasyDataTable
      ref="dataTableRef"
      v-bind:headers="headers"
      v-model:items-selected="dataset.itemsSelected"
      v-bind:items="items"
      v-bind:rows-per-page="pageInfo.size"
      v-bind:hide-footer="true"
      v-bind:loading="loading"
      v-on:click-row="showRow"
      alternating
      buttons-pagination
      table-class-name="customize-table"
      :header-item-class-name="headerItemClassNameFunction"
      :body-item-class-name="bodyItemClassNameFunction"
      v-bind:theme-color="`#125de6`"
      v-bind:border-cell="borderCell"
      :search-field="dataset.searchField"
      :search-value="dataset.searchValue"
    >
      <template
        v-for="(_, slot) in $slots"
        v-slot:[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope || {}"
        />
      </template>
    </Vue3EasyDataTable>
    <DataGridPagination
      v-bind:pageInfo="pageInfo"
      v-on:changePageNumber="event.changePageNumber"
    ></DataGridPagination>
  </div>
</template>
<script>
import "vue3-easy-data-table/dist/style.css";
import Vue3EasyDataTable from "vue3-easy-data-table";
import { ref, computed, watchEffect, watch } from "vue";

// Component
import DataGridPagination from "@/components/common/vues/grid/clientSideDataGridPagination.vue";

export default {
  components: { Vue3EasyDataTable, DataGridPagination },
  props: {
    headers: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    itemsSelected: {
      type: Array,
      required: false,
      default: null,
    },
    subPageInfo: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Boolean,
      required: false,
      default: false,
    },
    borderCell: {
      type: Boolean,
      required: false,
      default: false,
    },
    bodyTextDirection: {
      type: String,
      required: false,
      default: "left",
    },
    changeRowsPerPage: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const dataTableRef = ref(null);

    const dataset = ref({
      rowsPerPageOptions: [5, 10, 20, 50, 100],
      rowsPerPageActiveOption: computed(
        () => dataTableRef.value?.rowsPerPageActiveOption,
      ),
      emptyMessage: "데이터가 존재하지 않습니다.",
      itemsSelected: props.itemsSelected,
      searchField: "",
      searchValue: "",
    });
    const pageInfo = ref({
      page: computed(() => dataTableRef.value?.currentPaginationNumber),
      size: props.subPageInfo.size,
      totalPage: computed(() => dataTableRef.value?.maxPaginationNumber),
      totalSize: "",
    });
    // event
    const event = {
      onClickRefresh: () => {
        dataTableRef.value.currentPaginationNumber = 1;
        emit("changePage");
      },
      changePageNumber: (paginationNumber) => {
        dataTableRef.value.updatePage(paginationNumber);
      },
      onChangeRowsPerPage: (e) => {
        event.changeRowsPerPage(e.target.value);
      },
      changeRowsPerPage: (e) => {
        dataTableRef.value.updateRowsPerPageActiveOption(e);
      },
    };

    const showRow = (item) => {
      emit("showRow", item);
    };

    const bodyItemClassNameFunction = (column /* , rowNumber */) => {
      let className = "text-left";
      props.headers.forEach((item) => {
        if (item.textAlign) {
          if (item.value == column) {
            className = item.textAlign;
          }
        }
      });
      // if (column === 'price') return 'text-right';
      return className;
    };

    const headerItemClassNameFunction = (header /* header rowNumber */) => {
      let className = "text-left";
      if (header.textAlign) {
        className = header.textAlign;
      }
      return className;
    };

    watchEffect(() => {
      dataset.value.itemsSelected = props.itemsSelected;
      if (dataTableRef.value) {
        event.changePageNumber(props.subPageInfo.page);
        event.changeRowsPerPage(props.subPageInfo.size);
      }
      if (Object.keys(props.headers).length > 0) {
        dataset.value.searchField = props.headers[0].value;
      }
    });

    watch(
      () => dataset.value.itemsSelected,
      (value) => {
        emit("changeItemsSelected", value);
      },
      { deep: true },
    );

    return {
      showRow,
      dataset,
      pageInfo,
      event,
      dataTableRef,
      headerItemClassNameFunction,
      bodyItemClassNameFunction,
    };
  },
};
</script>
<style>
@import "/www/css/vue3-easy-data-table-custom.css";
</style>
