<template>
  <div class="d-flex justify-content-sm-end gap-2">
    <div class="me-auto bd-highlight d-flex gap-2"></div>
    <div>
      <div class="input-group">
        <select
          class="form-select form-select-sm w-125px"
          v-model="dataset.search.keywordType"
        >
          <option value="ResourceName">리소스 이름</option>
          <option value="Usage">사용량</option>
          <option value="ContractUnitPrice">단가</option>
        </select>
        <input
          type="text"
          class="form-control form-control-sm w-200px"
          placeholder="검색어를 입력하세요"
          v-model="dataset.search.keyword"
        />
      </div>
    </div>
  </div>
  <div class="mt-2">
    <table class="table table-bordered table-sm table-invoice">
      <colgroup>
        <col class="w-5" />
        <col class="w-20" />
        <col class="w-25" />
        <!-- <col class="w-10" /> -->
        <col class="w-10" />
        <col class="w-10" />
        <col class="w-10" />
        <col class="w-10" />
        <col class="w-10" />
      </colgroup>
      <thead class="table-secondary text-center">
        <tr>
          <th scope="col">순번</th>
          <th scope="col">서비스</th>
          <th scope="col">리소스 이름</th>
          <!-- <th class="col">과금단위</th> -->
          <th class="col">사용량</th>
          <th class="col">단가 단위</th>
          <th class="col">단가(원)</th>
          <th class="col">
            <Popper
              arrow
              placement="top"
              content="계산식 : (사용량/단가 단위)*단가"
            >
              금액(원) <i class="bx bxs-info-circle font-size-16 mx-1"></i>
            </Popper>
          </th>
          <th class="col">
            <Popper
              arrow
              placement="top"
              content="단위 가격 할인율"
            >
              할인율(%) <i class="bx bxs-info-circle font-size-16 mx-1"></i>
            </Popper>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="text-center"
          v-for="(item, index) in computedPagedList"
          v-bind:key="index"
        >
          <td>{{ startPageIndex + index + 1 }}</td>
          <td>
            <!-- <input
                type="text"
                class="form-control text-start"
                v-model="item.HomepageService"
                :disabled="item.DeleteYN"
              /> -->
            {{ item.HomepageService }}
          </td>
          <td
            :class="{
              'text-start': true,
              'custom-row-strikethrough': item.DeleteYN,
            }"
          >
            {{ item.ResourceName }}
          </td>
          <td>
            {{ item.Usage }}
          </td>
          <td
            :class="{
              'custom-row-strikethrough': item.DeleteYN,
            }"
          >
            {{ item.Unit }} {{ item.UnitName }}
          </td>
          <td
            :class="{
              'text-end': true,
              'custom-row-strikethrough': item.DeleteYN,
            }"
          >
            <Popper
              arrow
              placement="top"
            >
              {{ computeUnitPrice(item) }}
              <!-- prettier-ignore -->
              <template #content>
                <div class="d-flex justify-content-between">
                  <span class="mx-1">기존 단가 :</span>
                  <span>{{ item.UnitPrice.withComma() }}원</span>
                </div>
                <div class="d-flex justify-content-between text-primary">
                  <span class="mx-1">적용 단가 :</span>
                  <span>{{ computeUnitPrice(item).withComma() }}원</span>
                </div>
              </template>
            </Popper>
          </td>

          <td
            :class="{
              'text-end': true,
              'custom-row-strikethrough': item.DeleteYN,
            }"
          >
            <Popper
              arrow
              placement="top"
            >
              {{ computedPrice(item).withComma() }}
              <!-- prettier-ignore -->
              <template #content>
                <div class="d-flex justify-content-between">
                  <span class="mx-1">이용 금액 :</span>
                  <span>{{ item.Price.withComma() }}원</span>
                </div>
                <div class="d-flex justify-content-between">
                  <span class="mx-1">할인 금액 :</span>
                  <span>{{ (item.Price - computedPrice(item)).withComma() }}원</span>
                </div>
                <div class="d-flex justify-content-between text-primary">
                  <span class="mx-1">최종 금액 :</span>
                  <span >{{ computedPrice(item).withComma() }}원</span>
                </div>
              </template>
            </Popper>
          </td>
          <td
            :class="{
              'text-end': true,
              'custom-row-strikethrough': item.DeleteYN,
            }"
          >
            {{ computedDiscountRate(item) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col-lg-12">
        <ul class="pagination pagination-sm justify-content-center my-2">
          <li
            class="page-item"
            :class="
              startPage - dataset.pageInfo.defaultPages < 1 ? 'disabled' : null
            "
          >
            <button
              v-on:click="event.onClickPageNo(1)"
              class="page-link"
            >
              <i class="mdi mdi-chevron-double-left"></i>
            </button>
          </li>
          <li
            class="page-item"
            :class="
              startPage - dataset.pageInfo.defaultPages < 1 ? 'disabled' : null
            "
          >
            <a
              href="javascript: void(0);"
              class="page-link"
              v-on:click.prevent="event.onClickPrevGroupStartPage()"
            >
              <i class="mdi mdi-chevron-left"></i>
            </a>
          </li>
          <li
            class="page-item"
            v-for="item in countInPages"
            :key="item"
            :class="
              startPage - 1 + item == dataset.pageInfo.page ? 'active' : null
            "
          >
            <button
              v-on:click="event.onClickPageNo(startPage - 1 + item)"
              class="page-link page-link-number"
            >
              {{ startPage - 1 + item }}
            </button>
          </li>
          <template v-if="countInPages === 0">
            <li class="page-item disabled">
              <button class="page-link page-link-number">1</button>
            </li>
          </template>
          <li
            class="page-item"
            :class="
              totalPage >= startPage + dataset.pageInfo.defaultPages
                ? null
                : 'disabled'
            "
          >
            <a
              href="javascript: void(0);"
              class="page-link"
              v-on:click.prevent="event.onClickNextGroupStartPage()"
            >
              <i class="mdi mdi-chevron-right"></i>
            </a>
          </li>
          <li
            class="page-item"
            :class="
              totalPage >= startPage + dataset.pageInfo.defaultPages
                ? null
                : 'disabled'
            "
          >
            <button
              v-on:click="event.onClickLastGroupStartPage()"
              class="page-link"
            >
              <i class="mdi mdi-chevron-double-right"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, getCurrentInstance, onBeforeMount, computed } from "vue";

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      additionalService: {},
      invoice: {
        serviceType: "",
        selectedProjects: [],
        categoryName: "",
        additionalServiceName: "1",
        priceUnit: "",
        amount: "1",
        cost: "1",
      },
      invoiceList: [],
      rows: [{}],
      categoryList: [],
      isLoading: true,
      pageInfo: { page: 1, rowsPerPage: 5, defaultPages: 10 },
      search: { keywordType: "ResourceName", keyword: "" },
    });

    const getData = (data) => {
      dataset.value.invoice.selectedProjects = data;
    };

    const event = {
      onClickOk: () => {
        dataset.value.invoiceList.Usage.forEach((result) => {
          result.ContractPrice = Math.ceil(
            (result.Usage * result.ContractUnitPrice) / result.Unit,
            -2,
          );
        });
        // dataset.value.invoiceList.
        return dataset.value.invoiceList ?? [];
      },
      onClickReset: (index) => {
        const usagesData = dataset.value.invoiceList.Usage[index];
        usagesData.ContractPrice = usagesData.OriContractPrice;
        usagesData.ContractUnitPrice = usagesData.OriContractUnitPrice;
        usagesData.HomepageService = usagesData.OriHomepageService;
        usagesData.Usage = usagesData.OriUsage;
      },
      onClickPageNo: (page) => {
        dataset.value.pageInfo.page = page;
      },
      onClickPrevGroupStartPage: () => {
        let prevGroupStartPage =
          startPage.value - dataset.value.pageInfo.defaultPages;
        if (prevGroupStartPage < 1) {
          prevGroupStartPage = 1;
        }
        event.onClickPageNo(prevGroupStartPage);
      },
      onClickNextGroupStartPage: () => {
        let nextGroupStartPage =
          startPage.value + dataset.value.pageInfo.defaultPages;
        if (nextGroupStartPage > totalPage.value) {
          nextGroupStartPage =
            totalPage.value -
            ((totalPage.value - 1) % dataset.value.pageInfo.defaultPages);
        }
        event.onClickPageNo(nextGroupStartPage);
      },
      onClickLastGroupStartPage: () => {
        let lastGroupStartPage =
          Math.floor(
            (totalPage.value - 1) / dataset.value.pageInfo.defaultPages,
          ) *
            dataset.value.pageInfo.defaultPages +
          1;
        event.onClickPageNo(lastGroupStartPage);
      },
    };

    const initModalData = async () => {
      dataset.value.invoiceList = await proxy.$modalUtils.getModalData();
      if (dataset.value.invoiceList) {
        let rownum = 0;
        dataset.value.invoiceList?.Usage.forEach(
          (item) => (item.rownum = rownum++),
        );
      }
    };

    const computeUnitPrice = (item) => {
      if (item.RegionTypeCode == "PJ1") {
        return item.ContractUnitPrice;
      } else {
        return item.ContractYN == true // 계약단가 사용 여부에 따라 계산식 달라짐
          ? item.ContractUnitPrice // 사용할 경우 (true)
          : item.UnitPrice; // 사용안할 경우 (false)
      }
    };
    const computedPrice = (item) => {
      // PJ1 리전은 무조건 계약단가 사용
      if (item.RegionTypeCode == "PJ1") {
        return item.ContractPrice;
      } else {
        return item.ContractYN == true // 계약단가 사용 여부에 따라 계산식 달라짐
          ? item.ContractPrice // 사용할 경우 (true)
          : item.Price; // 사용안할 경우 (false)
      }
    };

    const computedDiscountRate = (item) => {
      // 할인율 계산식 = (정가-할인가)/정가 * 100
      if (!item.UnitPrice || item.UnitPrice == 0) {
        return 0;
      }
      return Math.floor(
        ((item.UnitPrice - computeUnitPrice(item)) / item.UnitPrice) * 100,
      );
    };

    // [grid] start index
    const startPageIndex = computed(() => {
      return (
        (dataset.value.pageInfo.page - 1) * dataset.value.pageInfo.rowsPerPage
      );
    });
    // [grid] start index
    const endPageIndex = computed(() => {
      return startPageIndex.value + dataset.value.pageInfo.rowsPerPage;
    });
    // [grid] search list
    const computedList = computed(() => {
      const list = dataset.value.invoiceList?.Usage;
      return list
        ? list.filter(
            (item) =>
              item[dataset.value.search.keywordType]
                .toString()
                .indexOf(dataset.value.search.keyword) > -1,
          )
        : [];
    });
    // [grid] page list
    const computedPagedList = computed(() => {
      return computedList.value.slice(startPageIndex.value, endPageIndex.value);
    });
    // [grid pagination] start page
    const startPage = computed(() => {
      let value =
        Math.floor(
          (dataset.value.pageInfo.page - 1) /
            dataset.value.pageInfo.defaultPages,
        ) *
          dataset.value.pageInfo.defaultPages +
        1;
      return value;
    });
    // [grid pagination] current page
    const countInPages = computed(() => {
      let currPages = totalPage.value - (startPage.value - 1);
      if (currPages < dataset.value.pageInfo.defaultPages) {
        return currPages;
      } else {
        return dataset.value.pageInfo.defaultPages;
      }
    });
    // [grid pagination] total pages
    const totalPage = computed(() => {
      let _totalPage = Math.floor(
        computedList.value.length / dataset.value.pageInfo.rowsPerPage,
      );
      if (computedList.value.length % dataset.value.pageInfo.rowsPerPage) {
        _totalPage += 1;
      }

      if (_totalPage != 0 && _totalPage < dataset.value.pageInfo.page) {
        event.onClickPageNo(1);
      }
      return _totalPage;
    });

    onBeforeMount(() => {
      initModalData();
      proxy.$emit(
        "footerButtonType", // 모달창 푸터 버튼 1개짜리
        proxy.$modalUtils.modalFooterBtnType.TYPE2,
      );
    });
    return {
      event,
      dataset,
      getData,
      computedPrice,
      computeUnitPrice,
      computedDiscountRate,
      computedPagedList,
      startPageIndex,
      startPage,
      countInPages,
      totalPage,
      computedList,
    };
  },
};
</script>
