export default {
  name: "organizationmember",
  path: "/organizationmember",
  component: () => import("@/views/index"),
  redirect: { name: "organizationmemberList" },
  meta: {
    group: "organization",
    isView: true,
  },
  children: [
    {
      name: "organizationmemberList",
      path: "list",
      meta: {
        title: "조직 멤버 목록",
        pageName: "조직 멤버 목록",
      },
      component: () => import("@/views/organizationmember/organizationmemberList"),
    },
  ],
};
