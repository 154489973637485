<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>
    <div
      class="row mb-4"
      v-show="false"
    >
      <label
        for="roleType"
        class="col-form-label col-lg-2"
        >역할 유형</label
      >
      <div class="col-lg-10">
        <div
          v-for="(item, index) in dataset.roleTypeList"
          v-bind:key="item"
          class="form-check form-check-inline font-size-16"
          v-bind:class="{ 'is-invalid': errors.roleType }"
        >
          <Field
            class="form-check-input"
            type="radio"
            name="roleType"
            v-bind:id="`roleType${index}`"
            v-model="dataset.role.roleType"
            v-bind:class="{ 'is-invalid': errors.roleType }"
            v-bind:value="item.code"
            checked
          />
          <label
            class="form-check-label font-size-13"
            v-bind:for="`roleType${index}`"
            >{{ item.name }}</label
          >
        </div>
        <div class="invalid-feedback">{{ errors.roleType }}</div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="roleName"
        class="col-form-label col-lg-2"
        >역할 이름</label
      >
      <div class="col-lg-10">
        <Field
          name="roleName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.roleName }"
          placeholder="역할 이름을 입력해주세요"
          v-model="dataset.role.roleName"
          maxlength="40"
        />
        <div class="invalid-feedback">
          {{ errors.roleName }}
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { Form, Field, useForm } from "vee-validate";
import * as roleApi from "@/api/portal/roleApi";
import * as yup from "yup";
export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      role: {
        roleType: "ORGANIZATION",
        roleName: "",
      },
      roleTypeList: [],
    });

    // validation schema
    const schema = yup.object().shape({
      roleName: yup
        .string()
        .min(1, "1글자 이상 40 글자 이하로 입력해주세요")
        .max(40, "1글자 이상 40글자 이하로 입력해주세요")
        // .matches(
        //   /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_| ]+$/,
        //   "역할 이름은 영문 대소문자, 숫자, 한글, _ , 공백만 입력 가능합니다"
        // )
        .required("이름은 필수 입력값 입니다."),
      roleType: undefined,
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (value, actions) => {
        roleApi.createRole(dataset.value.role).then((response) => {
          const successMessage = "생성 되었습니다.";
          proxy.$modalUtils.handleApiResponse(
            response,
            successMessage,
            actions,
            dataset.value.alert,
          );
        });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        // 필드 에러 외 에러메세지 close
        dataset.value.alert.isShow = false;
      },
    };

    const fnGetRoleTypeList = () => {
      roleApi.getRoleTypeList().then((response) => {
        if (response.isSuccessful) {
          dataset.value.roleTypeList = response.resultData;
        }
      });
    };

    onMounted(() => {
      fnGetRoleTypeList();
    });
    return { event, dataset, errors, schema };
  },
};
</script>
