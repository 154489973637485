export default {
  name: "optimization",
  path: "/cost/optimization",
  component: () => import("@/views/index"),
  redirect: "optimization",
  meta: {
    group: "optimization",
    isView: true,
  },
  children: [
    {
      name: "optimizationRightSizing",
      path: "rightsizing",
      redirect: { name: "rightSizingList" },
      component: () => import("@/views/index"),
      children: [
        {
          name: "rightSizingList",
          path: "list",
          meta: {
            pageName: "비용 최적화",
          },
          component: () =>
            import("@/views/optimization/optimizationRightSizing"),
        },
        {
          name: "rightSizingView",
          path: ":uid",
          meta: {
            pageName: "비용 최적화 상세",
          },
          component: () =>
            import("@/views/optimization/optimizationRightSizingView"),
        },
      ],
    },
    {
      name: "optimizationIntelligence",
      path: "intelligence",
      meta: {
        pageName: "유휴 자원",
      },
      component: () => import("@/views/optimization/optimizationIntelligence"),
    },
  ],
};
