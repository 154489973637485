import api from "../http";

export default {
  get: (customerId = null) => {
    return api.getPromise("GET", `/portal/customer/${customerId}`);
  },

  update: (data = null) => {
    return api.getPromise("PUT", `/portal/customer/${data.customerId}`, data);
  },
};
