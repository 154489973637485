import { createApp } from "vue";
import App from "./App.vue";

import router from "@/router";
import store from "@/store";
import axios from "axios";

// utils
import "@/utils/formatter/number";
import "@/utils/formatter/date";
import utils from "@/utils/common/install";
import directives from "@/utils/directive/install";
import error from "@/error/install";

// ApexChart
import VueApexCharts from "vue3-apexcharts";
import globalApexLocale from "apexcharts/dist/locales/ko.json";

// i18n
import { createI18n } from "vue-i18n";

import Popper from "vue3-popper";
import VueCookies from "vue3-cookies";
import VueClipboard from "vue3-clipboard";
import awn from "@/plugins/awesome-notifications";

// styles
import "@/styles/custom.scss";

let globalInstance = {
  menu: {},
  menuRoles: [],
  user: {},
  comm: {},
};

router.beforeEach(function (to /* , from */) {
  globalInstance.menu = to;
});

// create vue-i18n object
const i18n = createI18n({
  legacy: false,
  locale: "ko",
  fallbackLocale: "en",
  messages: {},
});

let app = createApp(App);

app.config.globalProperties.$globalInstance = globalInstance;
app.config.globalProperties.$axios = axios;
app.use(router);
app.use(VueApexCharts);
const globalApexCharts = window.Apex;
globalApexCharts.chart = {
  locales: [
    {
      ...globalApexLocale,
      // Override ko message
      ...{
        options: {
          toolbar: {
            exportToSVG: "SVG 저장",
            exportToPNG: "PNG 저장",
            exportToCSV: "CSV 저장",
          },
        },
      },
    },
  ],
  defaultLocale: "ko",
};

app.use(Popper);
app.use(utils);
app.use(directives);
app.use(store);
app.use(error);
app.use(i18n);
app.use(VueCookies, {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None",
});
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
});
app.use(awn);
app.mount("#app");
