export default {
  name: "invoice",
  path: "/invoice",
  component: () => import("@/views/index"),
  redirect: { name: "invoiceSettingList" },
  meta: {
    group: "invoice",
    isView: true,
  },
  children: [
    {
      name: "setting",
      path: "setting",
      component: () => import("@/views/index"),
      redirect: { name: "invoiceSettingList" },
      children: [
        {
          name: "invoiceSettingList",
          path: "list",
          meta: {
            pageName: "청구서 발행 설정 목록",
          },
          component: () => import("@/views/invoice/setting/invoiceSettingList"),
        },
        {
          name: "invoiceSettingWrite",
          path: "write",
          meta: {
            pageName: "청구서 발행 설정 생성",
          },
          component: () =>
            import("@/views/invoice/setting/invoiceSettingWrite"),
        },
        {
          name: "invoiceSettingEdit",
          path: ":invoiceSettingId/edit",
          meta: {
            pageName: "청구서 발행 설정 수정",
          },
          component: () =>
            import("@/views/invoice/setting/invoiceSettingWrite"),
        },
        {
          name: "invoiceSettingView",
          path: ":invoiceSettingId",
          meta: {
            pageName: "청구서 발행 설정 상세",
          },
          component: () => import("@/views/invoice/setting/invoiceSettingView"),
          props: true,
        },
      ],
    },
    {
      name: "publish",
      path: "publish",
      component: () => import("@/views/index"),
      redirect: { name: "invoicePublishList" },
      children: [
        {
          name: "invoicePublishList",
          path: "list",
          meta: {
            pageName: "청구서 발행 목록",
          },
          component: () => import("@/views/invoice/publish/invoicePublishList"),
        },
        {
          name: "invoiceView",
          path: ":invoiceId",
          meta: {
            pageName: "청구서 상세",
          },
          component: () => import("@/views/invoice/publish/invoiceView"),
        },
        {
          name: "invoiceEdit",
          path: ":invoiceId/edit",
          meta: {
            pageName: "청구서 수정",
          },
          component: () => import("@/views/invoice/publish/invoiceEdit"),
        },
        {
          name: "invoiceReport",
          path: ":invoiceId/report",
          meta: {
            pageName: "청구서 리포트",
            isWinPopup: true,
          },
          component: () => import("@/views/invoice/report/invoiceReport"),
        },
        {
          name: "invoiceInspectView",
          path: ":invoiceId/inspect",
          meta: {
            pageName: "청구금액 검수 결과",
          },
          component: () =>
            import("@/views/invoice/publish/components/invoiceInspectView"),
        },
      ],
    },
    {
      name: "history",
      path: "history",
      component: () => import("@/views/index"),
      redirect: { name: "invoiceHistoryList" },
      children: [
        {
          name: "invoiceHistoryList",
          path: "list",
          meta: {
            pageName: "청구서 이력 목록",
          },
          component: () => import("@/views/invoice/history/invoiceHistoryList"),
        },
        {
          name: "historyView",
          path: ":invoiceId",
          meta: {
            pageName: "청구서 이력 상세",
          },
          component: () => import("@/views/invoice/history/invoiceHistoryView"),
          props: true,
        },
      ],
    },
  ],
};
