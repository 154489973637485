import api from "../http";

export default {
  getCodeGroupPage: (data = null) => {
    return api.getPromise("GET", `/portal/codegroup`, data);
  },

  getCodeGroupList: (data = null) => {
    return api.getPromise("GET", `/portal/codegroup`, data);
  },

  getCodeList: (codegroupId, data = null) => {
    return api.getPromise(
      "GET",
      `/portal/codegroup/${codegroupId}/code/list`,
      data,
    );
  },

  getCodeMap: (codegroupId, data = null) => {
    return api.getPromise(
      "GET",
      `/portal/codegroup/${codegroupId}/code/map`,
      data,
    );
  },

  getCodeGroup: (data = null) => {
    return api.getPromise("GET", `/portal/codegroup/${data.codegroupId}`);
  },

  getDuplicateCodeGroup: (data = null) => {
    return api.getPromise("GET", `/portal/codegroup/duplicate`, data);
  },

  createCodeGroup: (data = null) => {
    return api.getPromise("POST", `/portal/codegroup`, data);
  },

  updateCodeGroup: (data = null) => {
    return api.getPromise("PUT", `/portal/codegroup/${data.codegroupId}`, data);
  },

  deleteCodeGroup: (data = null) => {
    return api.getPromise("POST", `/portal/codegroup/delete`, data);
  },

  deleteMultipleCodeGroup: (data = null) => {
    return api.getPromise("POST", `/portal/codegroup/delete/multiple`, data);
  },
};
