<template>
  <div>
    <header id="page-topbar">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box">
            <a
              href="index.html"
              class="logo logo-dark"
            >
              <span class="logo-sm">
                <img
                  :src="`/www/images/logo/${SITE_ID}/logo-sm-white.svg`"
                  alt="로고"
                  height="32"
                  width="32"
                />
              </span>
              <span class="logo-lg">
                <img
                  :src="`/www/images/logo/${SITE_ID}/logo-lg-white.svg`"
                  alt="로고"
                  height="32"
                />
              </span>
            </a>
          </div>

          <button
            type="button"
            class="btn btn-sm px-3 font-size-16 header-item"
            id="vertical-menu-btn"
          >
            <i class="fa fa-fw fa-bars"></i>
          </button>
          <div class="header-organization-box">
            {{ refDataset.getActiveOrganization()?.organizationName }}
          </div>
        </div>

        <div class="d-flex">
          <!-- <div class="dropdown d-inline-block d-lg-none ms-2">
            <button
              type="button"
              class="btn header-item noti-icon"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="mdi mdi-magnify"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown"
            >
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search ..."
                      aria-label="Recipient's username"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-primary"
                        type="submit"
                      >
                        <i class="mdi mdi-magnify"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div> -->

          <div class="dropdown d-inline-block">
            <button
              type="button"
              class="btn header-item"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              v-on="{ 'hide.bs.dropdown': event.onHideUserDropdown }"
            >
              <span class="d-xl-inline-block me-2">{{ userName }} 님</span>
              <img
                class="rounded-circle header-profile-user"
                src="/www/images/users/blank-photo.png"
                alt="Header Avatar"
              />

              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <div class="dropdown-item">
                <select
                  class="form-select"
                  v-model="refDataset.selectOrganizationId"
                >
                  <option
                    v-for="item in dataset.scopeOrganizationList"
                    v-bind:key="item"
                    v-bind:value="item.organizationId"
                  >
                    {{ item.organizationName }}
                  </option>
                </select>
              </div>
              <div class="dropdown-item text-center">
                <button
                  type="button"
                  class="btn w-100 btn-primary"
                  v-on:click="event.onClickUpdateOrg()"
                >
                  <i class="bx bx-wrench font-size-16 align-middle me-1"></i>
                  <span key="t-settings">조직 변경</span>
                </button>
              </div>
              <div class="dropdown-divider"></div>
              <!-- item-->
              <a
                class="dropdown-item text-center"
                href="#"
                v-on:click.prevent="
                  event.onClickOpenModal('마이페이지', 'modalMypage')
                "
                ><i class="bx bx-user font-size-16 align-middle me-1"></i>
                <span key="t-profile">마이페이지</span></a
              >
              <!-- <a
                class="dropdown-item"
                href="#"
                ><i class="bx bx-wallet font-size-16 align-middle me-1"></i>
                <span key="t-my-wallet">My Wallet</span></a
              >
              <a
                class="dropdown-item d-block"
                href="#"
                ><span class="badge bg-success float-end">11</span
                ><i class="bx bx-wrench font-size-16 align-middle me-1"></i>
                <span key="t-settings">Settings</span></a
              >
              <a
                class="dropdown-item"
                href="#"
                ><i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
                <span key="t-lock-screen">Lock screen</span></a
              > -->
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item text-danger text-center"
                href="#"
                v-on:click.prevent="event.onClickLogout()"
                ><i
                  class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
                ></i>
                <span key="t-logout">로그아웃</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import { onMounted, ref, getCurrentInstance, computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    let { proxy } = getCurrentInstance();
    const SITE_ID = import.meta.env.VITE_SITE_ID;
    const { dispatch, getters } = useStore();
    const userName = `${getters["user/userName"]}(${getters["user/loginId"]})`;
    const organizationList = computed(() => {
      return getters["user/organizationList"];
    });

    let dataset = ref({
      scopeOrganizationList: [],
      activeOrganization: { id: "", name: "" },
    });
    const refDataset = ref({
      selectOrganizationId: "",
      getActiveOrganization: () =>
        computed(() => getters["user/activeOrganization"]).value ?? null,
    });
    const event = {
      onHideUserDropdown: () => {
        const initOrganization = refDataset.value.getActiveOrganization();
        refDataset.value.selectOrganizationId =
          initOrganization?.organizationId;
      },
      // 권한 적용(X), 메뉴 및 라우드 변경(X), 페이지 리로드(O)
      onClickUpdateOrg: () => {
        const activeOrganization = organizationList.value.find(
          (item) =>
            item.organizationId === refDataset.value.selectOrganizationId,
        );
        dispatch("user/fetchActiveOrganization", activeOrganization).then(
          () => {
            proxy.$router.go(0);
          },
        );
      },
      onClickLogout: () => {
        dispatch("user/fetchClearLoginSession").then(
          () => (window.location = getters["user/loginPage"]),
        );
      },
      onClickOpenModal: (modalTitle, modalName) => {
        proxy.$modalUtils.handleOpenModal(modalTitle, modalName, null, null, {
          size: proxy.$modalUtils.modalSize.XLarge,
          isScrollable: true,
        });
      },
    };

    const initUserScope = () => {
      if (organizationList.value) {
        dataset.value.scopeOrganizationList = organizationList;

        if (!refDataset.value.getActiveOrganization()) {
          dispatch("user/fetchActiveOrganization", organizationList.value[0]);
        }
      }
      event.onHideUserDropdown();
    };
    onMounted(() => {
      initUserScope();
    });

    return {
      userName,
      event,
      dataset,
      refDataset,
      SITE_ID,
    };
  },
};
</script>
