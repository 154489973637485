<template>
  <div v-show="Object.keys($route.meta).length > 0">
    <div v-if="$route.meta?.isWinPopup === true">
      <routerView :key="$route.fullPath" />
    </div>
    <div
      v-else
      id="layout-wrapper"
    >
      <layoutHeader />
      <layoutLeft />
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <navigationBar />
            <!-- start page title -->
            <div class="row">
              <div class="col-12">
                <routerView :key="$route.fullPath" />
              </div>
            </div>
            <!-- end page title -->
          </div>
          <!-- container-fluid -->
        </div>
        <!-- End Page-content -->

        <footer class="footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6" />
              <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">{{ copyright }}</div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <!-- end main content-->
      <modalCommonError ref="modalCommonError" />
      <modalCommon ref="modalCommon" />
      <layoutModal />
    </div>
  </div>
</template>
<script>
import { onMounted, ref, getCurrentInstance } from "vue";
import modalCommonError from "@/components/common/vues/modal/errorModal";
import modalCommon from "@/components/common/vues/modal/confirmModal";
import navigationBar from "@/components/common/vues/navigationBar";
import layoutLeft from "@/layouts/layoutLeft";
import layoutHeader from "@/layouts/layoutHeader";
import layoutModal from "@/layouts/layoutModal";
import * as httpInterceptor from "@/api/http-interceptor";
import { useStore } from "vuex";
import { AuthenticationError } from "@/error";
import * as messageApi from "@/api/portal/messageApi";
import { useI18n } from "vue-i18n";

export default {
  components: {
    layoutLeft,
    layoutHeader,
    navigationBar,
    modalCommonError,
    modalCommon,
    layoutModal,
  },
  setup() {
    const i18n = useI18n();
    const { getters } = useStore();
    if (!getters["user/isLoggedIn"]) {
      throw AuthenticationError();
    }

    let { proxy } = getCurrentInstance();
    let dataset = ref({
      scopeOrganizationList: [],
      activeOrganization: { id: "", name: "" },
    });

    const openModalErrorAlert = (msg) => {
      // error 팝업
      proxy.$refs.modalCommonError.fnShowAlert(msg);
    };
    /*     const openModalErrorConfirm = (msg, callback) => {
      // 확인 팝업
      proxy.$refs.modalCommonError.fnShowConfirm(msg, callback);
    }; */
    const openModalAlert = (msg, callback, modalTitle) => {
      // 확인 팝업
      proxy.$refs.modalCommon.fnShowAlert(msg, callback, modalTitle);
    };
    const openModalAlertWithCheckBox = (msg, callback, modalTitle) => {
      // 체크박스 포함 확인 팝업
      proxy.$refs.modalCommon.fnShowAlertWithCheckBox(
        msg,
        callback,
        modalTitle,
      );
    };
    const openModalAlertDeleteResultGrid = (msgList, callback, modalTitle) => {
      // 확인 팝업
      proxy.$refs.modalCommon.fnShowAlertDeleteResultGrid(
        msgList,
        callback,
        modalTitle,
      );
    };
    const openModalConfirm = (msg, callback, modalTitle) => {
      // 확인 팝업
      proxy.$refs.modalCommon.fnShowConfirm(msg, callback, modalTitle);
    };
    const openModalDeleteConfirm = (msg, callback, modalTitle) => {
      // 삭제 확인 팝업
      proxy.$refs.modalCommon.fnShowDeleteConfirm(msg, callback, modalTitle);
    };

    httpInterceptor.interceptRequest(proxy.$axios);
    httpInterceptor.interceptResponse(proxy.$axios, openModalErrorAlert);

    const event = {
      openModalConfirm,
      openModalDeleteConfirm,
      openModalAlert,
      openModalAlertWithCheckBox,
      openModalAlertDeleteResultGrid,
    };

    // 다국어 처리
    // const messageIntervalId = ref(null);
    const fetchMessage = async () => {
      try {
        const response = await messageApi.getMessageMap();
        i18n.setLocaleMessage("en", response.resultData?.en);
        i18n.setLocaleMessage("ko", response.resultData?.ko);
      } catch (error) {
        console.error("Error fetching translations:", error);
      }
    };

    onMounted(() => {
      let scriptSimpleBar = document.createElement("script");
      scriptSimpleBar.type = "text/javascript";
      scriptSimpleBar.src = "/www/libs/simplebar/simplebar.min.js"; // use this for linked script
      document.body.appendChild(scriptSimpleBar);

      let scriptApp = document.createElement("script");
      scriptApp.type = "text/javascript";
      scriptApp.src = "/www/js/app.js"; // use this for linked script
      document.body.appendChild(scriptApp);

      fetchMessage();
      // messageIntervalId.value = setInterval(fetchMessage, 1000 * 60 * 60); // 1시간 간격
    });

    // onUnmounted(() => {
    //   clearInterval(messageIntervalId.value);
    // });
    return {
      dataset,
      event,
      copyright: import.meta.env.VITE_SITE_COPYRIGHT,
    };
  },
};
</script>
<style>
body,
#app,
pre {
  font-family: "Noto Sans KR", sans-serif !important;
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url("/www/fonts/NotoSansKR-DemiLight.woff2?v=4.5.0") format("woff2"),
    url("/www/fonts/NotoSansKR-DemiLight.woff?v=4.5.0") format("woff"),
    url("/www/fonts/NotoSansKR-DemiLight.otf?v=4.5.0") format("opentype");
}
</style>
