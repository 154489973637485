<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>

    <div class="row mb-4">
      <label
        for="tagGroupName"
        class="col-form-label col-lg-2"
        >태그그룹 이름</label
      >
      <div class="col-lg-10">
        <Field
          name="taggroupName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.taggroupName }"
          placeholder="태그그룹 이름을 입력해 주세요."
          v-model="dataset.taggroup.taggroupName"
        />
        <div class="invalid-feedback">{{ errors.taggroupName }}</div>
      </div>
    </div>
    <!-- <div class="row mb-4">
              <label for="tagDescription" class="col-form-label col-lg-2"
                >태그 설명</label
              >
              <div class="col-lg-10">
                <Field
                  name="tagDescription"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.tagDescription }"
                  placeholder="계정 설명을 입력해 주세요."
                  v-model="dataset.tag.tagDescription"
                />
                <div class="invalid-feedback">
                  {{ errors.tagDescription }}
                </div>
              </div>
            </div> -->
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { Form, Field, useForm } from "vee-validate";
import taggroupApi from "@/api/portal/taggroupApi";
import * as yup from "yup";
import { useStore } from "vuex";
export default {
  components: { Form, Field },
  // setup(props, { emit }) {
  setup() {
    const { proxy } = getCurrentInstance();
    const { getters } = useStore();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      providerList: [],
      accountTypeList: [],
      credentialFieldList: null,

      taggroup: {
        taggroupName: "",
        organizationId: getters["user/activeOrganization"].organizationId,
      },
    });

    // validation schema
    const schema = yup.object().shape({
      taggroupName: yup
        .string()
        .max(40, "입력할 수 있는 글자수를 초과했습니다.")
        .required("태그그룹 이름은 필수 입력값 입니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (values, actions) => {
        taggroupApi.createTaggroup(dataset.value.taggroup).then((response) => {
          const successMessage = "생성 되었습니다.";
          proxy.$modalUtils.handleApiResponse(
            response,
            successMessage,
            actions,
            dataset.value.alert,
          );
        });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        // 필드 에러 외 에러메세지 close
        dataset.value.alert.isShow = false;
      },
    };

    onMounted(() => {});
    return { dataset, schema, event, errors };
  },
};
</script>
