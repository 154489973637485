export default {
  name: "privilege",
  path: "/privilege",
  component: () => import("@/views/index"),
  redirect: { name: "privilegeList" },
  meta: {
    group: "portal",
    isView: true,
  },
  children: [
    {
      name: "privilegeList",
      path: "list",
      meta: {
        title: "권한 목록",
        pageName: "권한 목록",
      },
      component: () => import("@/views/privilege/privilegeList"),
    },
    {
      name: "privilegeWrite",
      path: "write",
      meta: {
        title: "권한 등록",
        pageName: "권한 등록",
      },
      component: () => import("@/views/privilege/privilegeWrite"),
    },
    {
      name: "privilegeView",
      path: ":privilegeId",
      meta: {
        title: "권한 상세",
        pageName: "권한 상세",
      },
      component: () => import("@/views/privilege/privilegeView"),
      children: [
        {
          name: "privilegeViewDetails",
          path: "details",
          meta: {
            title: "권한 상세-개요",
          },
          component: () => import("@/views/privilege/components/privilegeViewDetails"),
        },
      ],
    },
  ],
};
