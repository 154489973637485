<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>

    <div class="row mb-4">
      <label
        for="domainType"
        class="col-form-label col-lg-2"
        >도메인 유형</label
      >
      <div class="col-lg-10">
        <Field
          name="domainType"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.domainType }"
          placeholder="도메인 유형을 입력해 주세요."
          v-model="dataset.privilege.domainType"
          maxlength="35"
        />
        <div class="invalid-feedback">{{ errors.domainType }}</div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="resourceId"
        class="col-form-label col-lg-2"
        >리소스ID</label
      >
      <div class="col-lg-10">
        <Field
          name="resourceId"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.resourceId }"
          placeholder="리소스ID를 입력해 주세요."
          v-model="dataset.privilege.resourceId"
          maxlength="36"
        />
        <div class="invalid-feedback">{{ errors.resourceId }}</div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="action"
        class="col-form-label col-lg-2"
        >액션</label
      >
      <div class="col-lg-10">
        <div class="input-group">
          <Field
            id="action"
            name="action"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.action }"
            placeholder="권한 액션(기능)을 입력해 주세요."
            v-model="dataset.privilege.action"
            maxlength="35"
          />
          <div class="invalid-feedback">{{ errors.action }}</div>
        </div>
      </div>
    </div>
  </Form>
  <!-- data -->
  <!-- content end-->
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import privilegeApi from "@/api/portal/privilegeApi";
import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";
export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      privilege: {
        domainType: "",
        resourceId: "",
        action: "",
        externalYn: true,
      },
      privilegeList: [],
      domainList: [],
    });

    // validation schema
    const schema = yup.object().shape({
      domainType: yup
        .string()
        .min(1, "1글자 이상 35글자 이하로 입력해주세요")
        .max(35, "1글자 이상 35글자 이하로 입력해주세요")
        .required("도메인 타입은 필수 입력값 입니다."),
      resourceId: yup
        .string()
        .min(1, "1글자 이상 36 글자 이하로 입력해주세요")
        .max(36, "36글자 이하로 입력해 주세요")
        .required("리소스 ID는 필수 입력값 입니다."),
      action: yup
        .string()
        .min(0)
        .max(35, "35글자 이내로 입력해 주세요")
        .required("필수 입력사항 입니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const dataInit = () => {
      getDomainTypeListForPrivilege();
    };

    const getDomainTypeListForPrivilege = async () => {
      let result = await privilegeApi.getPrivilegeDomainTypeList();
      if (result.isSuccessful) {
        dataset.value.domainList = result.resultData;
      }
    };

    const event = {
      onSubmit: (values, actions) => {
        dataset.value.privilegeList.push(dataset.value.privilege);
        privilegeApi
          .createPrivilege(dataset.value.privilegeList)
          .then((response) => {
            const successMessage = "등록 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              actions,
              dataset.value.alert,
            );
          });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        // 필드 에러 외 에러메세지 close
        dataset.value.alert.isShow = false;
      },
    };

    onMounted(() => {
      dataInit();
    });

    return { dataset, schema, event, errors };
  },
};
</script>
