import { toDayAlias, isDayAlias } from "./alias/weekAlias";
import { toMonthAlias, isMonthAlias } from "./alias/monthAlias";

function parseSubExpr(expr) {
  expr = expr.trim();
  let match;
  if ((match = expr.match(/\*\/(\d+)/)) != null) {
    return {
      type: "cronNumber",
      at: { type: "asterisk" },
      every: { type: "number", value: parseInt(match[1]) },
    };
  }
  if ((match = expr.match(/(\d+)\/(\d+)/)) != null) {
    return {
      type: "cronNumber",
      at: { type: "number", value: parseInt(match[1]) },
      every: { type: "number", value: parseInt(match[2]) },
    };
  }
  if ((match = expr.match(/(\d+)/)) != null) {
    return {
      type: "number",
      value: parseInt(match[1]),
    };
  }
  if (expr == "?") {
    return { type: "question" };
  }
  if (expr == "*") {
    return { type: "asterisk" };
  }
  throw new Error(`Unhandled subexpression: ${expr}`);
}

/**
 * 요일 파싱
 * @param {*} expr
 * @returns
 */
function parseDayOfWeek(expr) {
  expr = expr.trim();
  if (expr == "*") {
    return {
      type: "asterisk",
    };
  }
  if (expr == "?") {
    return {
      type: "question",
    };
  }

  if (expr.includes("-")) {
    // MON-FRI (요일을 범위로 지정하는 경우는 advance로 표현)
    return {
      type: "dash",
    };
  }

  let groups = expr.match(
    /([a-zA-Z0-9]+)(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?/,
  );

  console.log("parseDayOfWeek", expr, groups);

  if (groups == null) {
    throw new Error(`invalid days expression: ${expr}`);
  }

  return {
    type: "setOfDays",
    days: groups
      .slice(1)
      .map((d) => d && d.replace(/,/, ""))
      .filter((d) => d)
      .map((d) => (!isDayAlias(d) ? toDayAlias(parseInt(d)) : d)),
  };
}

/**
 * 일 파싱
 * @param {*} expr
 * @returns
 */
function parseDayOfMonth(expr) {
  expr = expr.trim();
  if (expr == "*")
    return {
      type: "asterisk",
    };
  if (expr == "?")
    return {
      type: "question",
    };

  let groups = expr.match(
    /([a-zA-Z0-9]+)(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?(,[a-zA-Z0-9]+)?/,
  );
  if (groups == null) throw new Error(`invalid month expression: ${expr}`);
  return {
    type: "setOfMonths",
    months: groups
      .slice(1)
      .map((d) => d && d.replace(/,/, ""))
      .filter((d) => d)
      .map((d) => (!isMonthAlias(d) ? toMonthAlias(parseInt(d)) : d)),
  };
}

/**
 * 언제나
 * @param {*} token ? or *
 * @returns
 */
const isAny = (token) => {
  return token.type == "question" || token.type == "asterisk";
};

/**
 * 아무 시간 이나
 * @param {*} token * or (number type and 0)
 * @returns
 */
const isAnyTime = (token) => {
  return (
    token.type == "asterisk" || (token.type == "number" && token.value == 0)
  );
};

/**
 * CronExpression 파싱
 * @param {*} expression
 * @returns
 */
export const parseExpression = (expression) => {
  const advanced = {
    type: "advanced",
    cronExpression: expression,
  };

  const groups = expression.split(" ");
  if (groups.length != 5 && groups.length != 6) {
    return advanced;
  }

  const cron =
    groups.length == 6
      ? {
          seconds: parseSubExpr(groups[0]),
          minutes: parseSubExpr(groups[1]),
          hours: parseSubExpr(groups[2]),
          dayOfTheMonth: parseSubExpr(groups[3]),
          month: parseDayOfMonth(groups[4]),
          dayOfWeek: parseDayOfWeek(groups[5]),
        }
      : {
          minutes: parseSubExpr(groups[0]),
          hours: parseSubExpr(groups[1]),
          dayOfTheMonth: parseSubExpr(groups[2]),
          month: parseDayOfMonth(groups[3]),
          dayOfWeek: parseDayOfWeek(groups[4]),
        };

  // 매분마다
  if (
    cron.minutes.type == "cronNumber" &&
    isAnyTime(cron.minutes.at) &&
    cron.hours.type == "asterisk" &&
    cron.dayOfTheMonth.type == "asterisk" &&
    cron.month.type == "asterisk" &&
    isAny(cron.dayOfWeek)
  ) {
    return {
      type: "minutes",
      minuteInterval: cron.minutes.every.value,
    };
  }

  // 매시마다
  if (
    cron.minutes.type == "number" &&
    cron.hours.type == "cronNumber" &&
    isAnyTime(cron.hours.at) &&
    cron.dayOfTheMonth.type == "asterisk" &&
    cron.month.type == "asterisk" &&
    isAny(cron.dayOfWeek)
  ) {
    return {
      type: "hourly",
      minutes: cron.minutes.value,
      hourInterval: cron.hours.every.value,
    };
  }

  // 매일마다
  if (
    cron.minutes.type == "number" &&
    cron.hours.type == "number" &&
    cron.dayOfTheMonth.type == "cronNumber" &&
    cron.dayOfTheMonth.at.type == "asterisk" &&
    cron.month.type == "asterisk" &&
    isAny(cron.dayOfWeek)
  ) {
    return {
      type: "daily",
      minutes: cron.minutes.value,
      hours: cron.hours.value,
      dayInterval: cron.dayOfTheMonth.every.value,
    };
  }

  // 매요일마다
  if (
    cron.minutes.type == "number" &&
    cron.hours.type == "number" &&
    isAny(cron.dayOfTheMonth) &&
    cron.month.type == "asterisk" &&
    cron.dayOfWeek.type == "setOfDays"
  ) {
    return {
      type: "weekly",
      minutes: cron.minutes.value,
      hours: cron.hours.value,
      days: cron.dayOfWeek.days,
    };
  }

  // 매월마다
  if (
    cron.minutes.type == "number" &&
    cron.hours.type == "number" &&
    cron.dayOfTheMonth.type == "number" &&
    cron.month.type == "setOfMonths" &&
    isAny(cron.dayOfWeek)
  ) {
    return {
      type: "monthly",
      minutes: cron.minutes.value,
      hours: cron.hours.value,
      day: cron.dayOfTheMonth.value,
      month: cron.month.months,
    };
  }

  return advanced;
};
