<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codegroupId"
        class="col-form-label col-lg-2"
        >그룹코드 ID</label
      >
      <div class="col-lg-10">
        <div class="input-group">
          <Field
            id="codegroupId"
            name="codegroupId"
            class="form-select"
            :class="{ 'is-invalid': errors.codegroupId }"
            v-model="dataset.code.codegroupId"
            as="select"
          >
            <option
              value=""
              disabled
            >
              전체
            </option>
            <option
              v-for="(item, index) in dataset.codeGroupList"
              v-bind:key="index"
              v-bind:value="item.codegroupId"
            >
              {{ item.codegroupName }}
            </option>
          </Field>
          <div class="invalid-feedback">{{ errors.codegroupId }}</div>
        </div>
      </div>
    </div>
    <div
      class="row mb-4"
      :class="!dataset.duplicateCodeId ? 'was-validated' : ''"
    >
      <label
        for="codeId"
        class="col-form-label col-lg-2"
        >코드 ID</label
      >
      <div class="col-lg-10">
        <div class="input-group">
          <Field
            id="codeId"
            name="codeId"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.codeId }"
            placeholder="코드 ID를 입력해 주세요."
            v-model.trim="dataset.code.codeId"
            autocomplete="off"
            maxlength="36"
          />
          <div class="input-group-append">
            <button
              class="btn btn-primary ms-1"
              type="button"
              v-on:click="event.onClickDuplicateCode()"
            >
              <i class="bx bx-check-double font-size-16 align-middle me-1" />
              중복 확인
            </button>
          </div>

          <div
            :class="
              dataset.duplicateCodeId ? 'invalid-feedback' : 'valid-feedback'
            "
          >
            {{ errors.codeId }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeName"
        class="col-form-label col-lg-2"
        >코드 이름</label
      >
      <div class="col-lg-10">
        <Field
          name="codeName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.codeName }"
          placeholder="코드 이름을 입력해주세요"
          v-model.trim="dataset.code.codeName"
          maxlength="40"
        />
        <div class="invalid-feedback">
          {{ errors.codeName }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeDescription"
        class="col-form-label col-lg-2"
        >코드 설명</label
      >
      <div class="col-lg-10">
        <Field
          as="textarea"
          rows="10"
          name="codeDescription"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.codeDescription }"
          placeholder="코드 설명을 입력해주세요"
          v-model="dataset.code.codeDescription"
          maxlength="200"
        />
        <div class="invalid-feedback">
          {{ errors.codeDescription }}
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <label
        for="codeAttributeName1"
        class="col-form-label col-lg-2"
        >코드 속성1</label
      >
      <div class="col-lg-10">
        <div class="d-flex">
          <Field
            name="codeAttributeName1"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.codeAttributeName1 }"
            placeholder="코드 속성1 을 입력해주세요"
            v-model.trim="dataset.code.codeAttributeName1"
            maxlength="200"
          />
          <!-- <button
            type="button"
            class="btn btn-outline-secondary ms-2"
          >
            <i class="bx bx-minus fa-lg"></i>
            코드 속성 삭제
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary ms-2"
          >
            <i class="bx bx-plus fa-lg"></i>
            코드 속성 추가
          </button> -->
        </div>
        <div class="invalid-feedback">
          {{ errors.codeAttributeName1 }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeAttributeName2"
        class="col-form-label col-lg-2"
        >코드 속성2</label
      >
      <div class="col-lg-10">
        <Field
          name="codeAttributeName2"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.codeAttributeName2 }"
          placeholder="코드 속성2 을 입력해주세요"
          v-model.trim="dataset.code.codeAttributeName2"
          maxlength="200"
        />
        <div class="invalid-feedback">
          {{ errors.codeAttributeName2 }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeAttributeName3"
        class="col-form-label col-lg-2"
        >코드 속성3</label
      >
      <div class="col-lg-10">
        <Field
          name="codeAttributeName3"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.codeAttributeName3 }"
          placeholder="코드 속성3 을 입력해주세요"
          v-model.trim="dataset.code.codeAttributeName3"
          maxlength="200"
        />
        <div class="invalid-feedback">
          {{ errors.codeAttributeName3 }}
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <label
        for="outputOrder"
        class="col-form-label col-lg-2"
        >순서</label
      >
      <div class="col-lg-10">
        <Field
          name="outputOrder"
          type="number"
          class="form-control"
          :class="{ 'is-invalid': errors.outputOrder }"
          placeholder="순서를(을) 입력해주세요"
          v-model.number="dataset.code.outputOrder"
          min="0"
        />
        <div class="invalid-feedback">
          {{ errors.outputOrder }}
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted, watch } from "vue";
import { Form, Field, useForm } from "vee-validate";
import codeApi from "@/api/portal/codeApi";
import codeGroupApi from "@/api/portal/codegroupApi";
import * as yup from "yup";
export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      code: {
        codegroupId: "",
        codeId: "",
        codeName: "",
        codeDescription: "",
        codeAttributeName1: "",
        codeAttributeName2: "",
        codeAttributeName3: "",
        outputOrder: "",
      },
      codeGroupList: [],
      prevCodeId: "",
      duplicateCodeId: true,
    });

    const schema = yup.object().shape({
      codegroupId: yup.string().required("코드그룹 ID는 필수 선택값 입니다."),
      codeId: yup
        .string()
        .min(1, "1글자 이상 36글자 이하로 입력해주세요")
        .max(36, "1글자 이상 36글자 이하로 입력해주세요")
        .required("코드 ID는 필수 입력값 입니다."),
      codeName: yup
        .string()
        .min(1, "1글자 이상 40 글자 이하로 입력해주세요")
        .max(40, "1글자 이상 40글자 이하로 입력해주세요")
        .required("코드 이름은 필수 입력값 입니다."),
      outputOrder: yup.string().matches(/^[0-9]+$/, "숫자만 입력해주세요"),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (value, actions) => {
        if (
          dataset.value.prevCodeId !== dataset.value.code.codeId ||
          dataset.value.duplicateCodeId
        ) {
          proxy.$awn.warning("중복체크를 해주세요.");
          return false;
        }

        codeApi.createCode(dataset.value.code).then((response) => {
          const successMessage = "생성 되었습니다.";
          proxy.$modalUtils.handleApiResponse(
            response,
            successMessage,
            actions,
            dataset.value.alert,
          );
        });
      },
      onClickOk: async () => {
        const { valid } = await proxy.$refs.form.validate();
        if (!valid) return false;

        const compareOrder = (await fnGetCodeByCodeGroupId()) ?? [];
        if (compareOrder.indexOf(dataset.value.code.outputOrder) > -1) {
          setFieldError("outputOrder", "중복되는 정렬순서입니다");
          return false;
        }

        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        dataset.value.alert.isShow = false;
      },
      onClickDuplicateCode: async () => {
        dataset.value.prevCodeId = dataset.value.code.codeId;
        dataset.value.code.codeId && dataset.value.code.codegroupId
          ? fnDuplicateCode()
          : proxy.$awn.warning("그룹코드와 코드를 입력 해주세요.");
      },
    };

    const fnDuplicateCode = async () => {
      const rs = await codeApi.getDuplicateCode(dataset.value.code);
      try {
        if (rs.isSuccessful) {
          dataset.value.duplicateCodeId = rs.resultData;
          rs.resultData
            ? setFieldError("codeId", "이미 존재하는 아이디입니다.")
            : setFieldError("codeId", "사용 가능한 코드입니다.");
        } else {
          if (rs.resultData.fieldErrors) {
            rs.resultData.fieldErrors.forEach((item) => {
              setFieldError(item.field, item.defaultMessage);
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const setFieldError = (id, message) => {
      proxy.$refs.form.setFieldError(id, message);
    };

    watch(
      () => dataset.value.code.codeId,
      (/* value */) => {
        dataset.value.duplicateCodeId = true;
        setFieldError("codeId", "");
      },
    );

    // const num = function () {
    //   let num = 0;
    //   return function () {
    //     return num++;
    //   };
    // };

    //  onClickAddCodeAttribute: () => {
    //   //splice 로 특정 index delete
    //   //cmp
    //   let no = num();
    //   dataset.value.codeAttributeList.push(`codeAttribueName${no}`);
    //   //codeNo1 code
    //   //codeAttributeName3 문자열을 mapping 하는 부분
    // },

    const fnGetCodeGroupList = async () => {
      const result = await codeGroupApi.getCodeGroupList();
      if (result.isSuccessful) {
        dataset.value.codeGroupList = result.resultData ?? [];
      }
    };

    const fnGetCodeByCodeGroupId = async () => {
      const result = await codeGroupApi.getCodeList(
        dataset.value.code.codegroupId,
      );
      if (result.isSuccessful) {
        return result.resultData.map((item) => item.outputOrder);
      }
    };

    onMounted(async () => {
      await fnGetCodeGroupList();
      const codegroupId = proxy.$modalUtils.getModalData()?.codegroupId;

      if (codegroupId !== undefined) {
        dataset.value.code.codegroupId = codegroupId;
        dataset.value.codeGroupList = dataset.value.codeGroupList.filter(
          (item) => item.codegroupId === codegroupId,
        );
      }
    });
    return { event, dataset, errors, schema };
  },
};
</script>
