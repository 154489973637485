<template>
  <div
    v-if="handle.isShow"
    v-bind:class="{ show: handle.isShow }"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    style="display: block"
    role="dialog"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <!-- isDeleteConfirm -->
    <div
      v-show="handle.isDeleteConfirm"
      class="modal-dialog modal-lg"
    >
      <div class="modal-content">
        <VeeForm
          v-on:submit="event.onSubmitConfirm"
          v-bind:validation-schema="schema"
          v-slot="{ errors }"
          ref="form"
        >
          <div class="modal-header">
            <h5 class="modal-title">
              {{ handle.modalTitle }}
            </h5>
            <button
              type="button"
              class="btn-close"
              v-on:click="event.onClickBtnClose"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div
                  class="p-3"
                  style="background: #f0f7ff; border: 1px solid #dbeafa"
                >
                  <!-- 선택 사항을 확인해 주세요. 이 작업은 취소할 수 없습니다.<br />진행하시겠습니까? -->
                  <b class="custom-modal-key">{{ handle.msg }}</b
                  >을(를) 선택했습니다.<br />선택 사항을 확인해 주세요. 이
                  작업은 취소할 수 없습니다.
                </div>
              </div>
            </div>
            <div class="row mt-3 mb-2">
              <div class="col">
                <div>
                  데이터를 삭제 하려면 아래에
                  <b class="custom-modal-key">'{{ handle.msg }}'</b>을(를)
                  입력해 주세요.
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col"
                style="height: 40px"
              >
                <Field
                  id="msg"
                  name="msg"
                  type="hidden"
                  class="form-control border-0"
                  v-model="handle.msg"
                  readonly
                />
                <Field
                  id="deleteKey"
                  name="deleteKey"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.deleteKey }"
                  :placeholder="`${handle.msg}`"
                  v-model="handle.deleteKey"
                  autocomplete="off"
                />
                <div class="invalid-feedback">{{ errors.deleteKey }}</div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary waves-effect waves-light"
              v-on:click="event.onClickBtnClose"
            >
              <i class="bx bx-block font-size-16 align-middle me-2"></i>
              취소
            </button>
            <button
              type="submit"
              class="btn btn-primary waves-effect waves-light"
            >
              <i class="bx bx-check-double font-size-16 align-middle me-2"></i>
              확인
            </button>
          </div>
        </VeeForm>
      </div>
    </div>
    <!-- isConfirm -->
    <div
      v-show="handle.isConfirm"
      class="modal-dialog"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ handle.modalTitle }}
          </h5>
          <button
            type="button"
            class="btn-close"
            v-on:click="event.onClickBtnClose"
          ></button>
        </div>
        <div class="modal-body">
          <span
            class="font-size-15"
            v-html="handle.msg"
          ></span>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary waves-effect waves-light"
            v-on:click="event.onClickBtnClose"
          >
            <i class="bx bx-block font-size-16 align-middle me-2"></i>
            취소
          </button>
          <button
            type="button"
            class="btn btn-primary waves-effect waves-light"
            v-on:click="event.onClickBtnConfirm"
          >
            <i class="bx bx-check-double font-size-16 align-middle me-2"></i>
            확인
          </button>
        </div>
      </div>
    </div>
    <!-- isAlert -->
    <div
      v-show="handle.isAlert"
      class="modal-dialog"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ handle.modalTitle }}
          </h5>
        </div>
        <div class="modal-body">
          <div
            class="custom-modal-body custom-modal-body-center overflow-auto h-75"
            v-html="handle.msg"
          ></div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary waves-effect waves-light"
            v-on:click="event.onClickBtnConfirm"
          >
            <i class="bx bx-check-double font-size-16 align-middle me-2"></i>
            확인
          </button>
        </div>
      </div>
    </div>
    <!-- isAlert -->
    <div
      v-show="handle.isAlertCheckBox"
      class="modal-dialog modal-md"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ handle.modalTitle }}
          </h5>
        </div>
        <div class="modal-body">
          <div
            class="custom-modal-body custom-modal-body-center overflow-auto h-75"
            v-html="handle.msg"
          ></div>
        </div>
        <div class="modal-footer justify-content-between">
          <div
            v-if="!handle.checkBox"
            @click="event.onClickCheckBox"
            class="d-flex content-items-center justify-content-start gap-1"
          >
            <input
              id="viewDetailed"
              class="form-check-input"
              type="checkbox"
              v-model="handle.isChecked"
            />
            <span class="mr-1">오늘 하루 다시 보지 않기</span>
          </div>
          <button
            type="button"
            class="btn btn-primary waves-effect waves-light"
            v-on:click="event.onClickCheckBoxBtnConfirm(handle.isChecked)"
          >
            <i class="bx bx-check-double font-size-16 align-middle me-2"></i>
            확인
          </button>
        </div>
      </div>
    </div>
    <!-- isAlertDeleteResultGrid -->
    <div
      v-if="handle.isAlertDeleteResultGrid"
      class="modal-dialog modal-lg modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ handle.modalTitle }}
          </h5>
        </div>
        <div class="modal-body">
          <DataGrid
            v-bind:headers="handle.deleteResultGrid.headers"
            v-bind:items="handle.deleteResultGrid.items"
            v-bind:subPageInfo="handle.deleteResultGrid.pageInfo"
            v-bind:loading="handle.deleteResultGrid.loading"
            v-bind:body-text-direction="'center'"
            v-bind:changeRowsPerPage="false"
            border-cell
          >
            <template #expand="item">
              <div class="my-2">
                [결과 상세]
                <ul>
                  <li
                    v-for="_item in item.deletionCauseList"
                    v-bind:key="_item.id"
                  >
                    {{ _item }}
                  </li>
                  <li v-if="item.operationResult === 'SUCCESS'">
                    정상적으로 삭제 되었습니다.
                  </li>
                </ul>
              </div>
            </template>
            <template #item-operationResultName="item">
              <span
                :class="
                  item.operationResult === 'SUCCESS'
                    ? 'text-success'
                    : 'text-danger'
                "
                >{{ item.operationResultName }}</span
              >
            </template>
          </DataGrid>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light waves-effect waves-light"
            v-on:click="event.onClickDeleteResultDownload"
          >
            <i class="bx bxs-download font-size-16 align-middle me-2"></i>
            결과 다운로드
          </button>
          <button
            type="button"
            class="btn btn-primary waves-effect waves-light"
            v-on:click="event.onClickBtnConfirm"
          >
            <i class="bx bx-check-double font-size-16 align-middle me-2"></i>
            확인
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="handle.isShow"
    v-bind:class="{ show: handle.isShow }"
    class="modal-backdrop fade"
  ></div>
</template>
<script>
import { ref, getCurrentInstance } from "vue";

// Component
import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";
import DataGrid from "@/components/common/vues/grid/clientSideDataGrid.vue";

export default {
  components: {
    VeeForm: Form,
    Field,
    DataGrid,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    let callbackFunc;
    let handle = ref({
      modalTitle: "",
      isShow: false,
      isConfirm: false,
      isDeleteConfirm: false,
      isAlert: false,
      isAlertDeleteResultGrid: false,
      msg: "",
      deleteKey: "",
      deleteResultGrid: {
        headers: [
          { text: "ID", value: "id", sortable: false },
          { text: "이름", value: "name", sortable: false },
          {
            text: "작업결과",
            value: "operationResultName",
            sortable: false,
            textAlign: "text-center",
          },
        ],
        items: [],
        loading: false,
        pageInfo: proxy.$pageUtils.getPageInfo(),
      },
      isChecked: false,
      isAlertCheckBox: false,
    });
    // validation schema
    const schema = yup.object().shape({
      deleteKey: yup
        .string()
        // .required("필수 입력값 입니다.")
        .oneOf([yup.ref("msg"), ""], "입력값이 일치하지 않습니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    // 단순팝업
    const fnShowAlert = (msg, callback, modalTitle) => {
      handle.value.isShow = true;
      handle.value.isAlert = true;
      handle.value.isAlertCheckBox = false;
      handle.value.isAlertDeleteResultGrid = false;
      handle.value.isConfirm = false;
      handle.value.isDeleteConfirm = false;
      handle.value.msg = msg;
      handle.value.modalTitle = modalTitle ?? "확인";
      callbackFunc = callback;
    };

    // 체크박스가 포함된 팝업
    const fnShowAlertWithCheckBox = (msg, callback, modalTitle) => {
      handle.value.isAlertCheckBox = true;
      handle.value.isShow = true;
      handle.value.isAlert = false;
      handle.value.isAlertDeleteResultGrid = false;
      handle.value.isConfirm = false;
      handle.value.isDeleteConfirm = false;
      handle.value.msg = msg;
      handle.value.modalTitle = modalTitle ?? "확인";
      callbackFunc = callback;
    };

    // 삭제 결과 확인 팝업(Grid)
    const fnShowAlertDeleteResultGrid = (msgList, callback, modalTitle) => {
      handle.value.isShow = true;
      handle.value.isAlert = false;
      handle.value.isAlertDeleteResultGrid = true;
      handle.value.isAlertCheckBox = false;
      handle.value.isConfirm = false;
      handle.value.isDeleteConfirm = false;
      handle.value.deleteResultGrid.items = msgList;
      handle.value.modalTitle = modalTitle ?? "삭제 결과 확인";
      callbackFunc = callback;
    };

    // 확인팝업
    const fnShowConfirm = (msg, callback, modalTitle) => {
      handle.value.isShow = true;
      handle.value.isAlert = false;
      handle.value.isAlertCheckBox = false;
      handle.value.isAlertDeleteResultGrid = false;
      handle.value.isConfirm = true;
      handle.value.isDeleteConfirm = false;
      handle.value.msg = msg;
      handle.value.modalTitle = modalTitle ?? "확인";
      callbackFunc = callback;
    };
    // 삭제 팝업
    const fnShowDeleteConfirm = (name, callback, modalTitle) => {
      handle.value.isShow = true;
      handle.value.isAlert = false;
      handle.value.isAlertCheckBox = false;
      handle.value.isAlertDeleteResultGrid = false;
      handle.value.isConfirm = false;
      handle.value.isDeleteConfirm = true;
      handle.value.msg = name;
      handle.value.modalTitle = modalTitle ?? "삭제 하시겠습니까?";
      callbackFunc = callback;
    };
    const onSubmitConfirm = (values, alerts) => {
      if (values.msg === values.deleteKey) {
        event.onClickBtnConfirm();
        alerts.resetForm();
        // proxy.$refs.form.resetForm(); // form init
      } else {
        alerts.setFieldError("deleteKey", "필수 입력값 입니다.");
      }
    };
    const onClickBtnConfirm = () => {
      callbackFunc();
      onClickBtnClose();
    };

    const onClickCheckBoxBtnConfirm = (data) => {
      callbackFunc(data);
      onClickBtnClose();
    };
    const onClickBtnClose = () => {
      handle.value.msg = "";
      handle.value.deleteKey = "";
      handle.value.isShow = false;
    };
    const event = {
      onClickBtnConfirm,
      onClickCheckBoxBtnConfirm,
      onClickBtnClose,
      onSubmitConfirm,
      onClickDeleteResultDownload: () => {
        const fileName = handle.value.modalTitle;
        const rowData = handle.value.deleteResultGrid.items.flatMap((item) => {
          if (!item.deletionCauseList) {
            return {
              ["ID"]: item.id,
              ["이름"]: item.name,
              ["작업 결과"]: item.operationResult.toUpperCase(),
              ["상세 내역"]: "정상적으로 삭제 되었습니다.",
            };
          }

          return item.deletionCauseList.map((cause) => {
            return {
              ["ID"]: item.id,
              ["이름"]: item.name,
              ["작업 결과"]: item.operationResult.toUpperCase(),
              ["상세 내역"]: cause,
            };
          });
        });
        proxy.$downloadUtils.downloadRowDataToCsv(fileName, rowData);
      },
    };
    return {
      handle,
      fnShowAlert,
      fnShowAlertDeleteResultGrid,
      fnShowConfirm,
      fnShowDeleteConfirm,
      fnShowAlertWithCheckBox,
      event,
      errors,
      schema,
    };
  },
};
</script>
