export default {
  name: "user",
  path: "/user",
  component: () => import("@/views/index"),
  redirect: { name: "userList" },
  meta: {
    group: "portal",
    isView: true,
  },
  children: [
    {
      name: "userList",
      path: "list",
      meta: {
        title: "사용자 목록",
        pageName: "사용자 목록",
      },
      component: () => import("@/views/user/userList"),
    },
    // {
    //   name: 'userWrite',
    //   path: 'write',
    //   meta: { title: '사용자 등록' },
    //   component: () => import('@/views/user/userWrite'),
    // },
    // {
    //   name: 'userEdit',
    //   path: 'edit/:userId',
    //   meta: { title: '사용자 수정' },
    //   component: () => import('@/views/user/userEdit'),
    // },
    {
      name: "userView",
      path: ":userId",
      component: () => import("@/views/user/userView"),
      meta: {
        title: "사용자 상세",
        pageName: "사용자 상세",
      },
      children: [
        {
          name: "userViewDetails",
          path: "details",
          meta: {
            title: "사용자 상세 - 기본정보",
          },
          component: () =>
            import("@/views/user/components/userViewDetails.vue"),
        },
        {
          name: "userViewOrganizationMember",
          path: "organizationmember",
          meta: {
            title: "사용자 상세 - 소속조직",
          },
          component: () =>
            import("@/views/user/components/userViewOrganizationMember.vue"),
        },
        {
          name: "userViewAuditLog",
          path: "auditlog",
          meta: {
            title: "사용자 상세 - 감사로그",
          },
          component: () =>
            import("@/views/user/components/userViewAuditLog.vue"),
        },
      ],
    },
  ],
};
