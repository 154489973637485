<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeId"
        class="col-form-label col-lg-2"
        >그룹코드 ID</label
      >
      <div class="col-lg-10">
        <div class="input-group">
          <Field
            id="codegroupId"
            name="codegroupId"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.codegroupId }"
            placeholder="그룹코드 ID를 입력해 주세요."
            v-model="dataset.code.codegroupId"
            autocomplete="off"
            maxlength="36"
            disabled
          />
          <div class="invalid-feedback">
            {{ errors.codegroupId }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeId"
        class="col-form-label col-lg-2"
        >코드 ID</label
      >
      <div class="col-lg-10">
        <div class="input-group">
          <Field
            id="codeId"
            name="codeId"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.codeId }"
            placeholder="코드 ID를 입력해 주세요."
            v-model.trim="dataset.code.codeId"
            autocomplete="off"
            maxlength="36"
            disabled
          />

          <div class="invalid-feedback">
            {{ errors.codeId }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeName"
        class="col-form-label col-lg-2"
        >코드 이름</label
      >
      <div class="col-lg-10">
        <Field
          name="codeName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.codeName }"
          placeholder="코드 이름을 입력해주세요"
          v-model.trim="dataset.code.codeName"
          maxlength="40"
        />
        <div class="invalid-feedback">
          {{ errors.codeName }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeDescription"
        class="col-form-label col-lg-2"
        >코드 설명</label
      >
      <div class="col-lg-10">
        <Field
          as="textarea"
          rows="10"
          name="codeDescription"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.codeDescription }"
          placeholder="코드 설명을 입력해주세요"
          v-model="dataset.code.codeDescription"
          maxlength="200"
        />
        <div class="invalid-feedback">
          {{ errors.codeDescription }}
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <label
        for="codeAttributeName1"
        class="col-form-label col-lg-2"
        >코드 속성1</label
      >
      <div class="col-lg-10">
        <div class="d-flex">
          <Field
            name="codeAttributeName1"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.codeAttributeName1 }"
            placeholder="코드 속성1 을 입력해주세요"
            v-model.trim="dataset.code.codeAttributeName1"
            maxlength="200"
          />
        </div>
        <div class="invalid-feedback">
          {{ errors.codeAttributeName1 }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeAttributeName2"
        class="col-form-label col-lg-2"
        >코드 속성2</label
      >
      <div class="col-lg-10">
        <Field
          name="codeAttributeName2"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.codeAttributeName2 }"
          placeholder="코드 속성2 을 입력해주세요"
          v-model.trim="dataset.code.codeAttributeName2"
          maxlength="200"
        />
        <div class="invalid-feedback">
          {{ errors.codeAttributeName2 }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeAttributeName3"
        class="col-form-label col-lg-2"
        >코드 속성3</label
      >
      <div class="col-lg-10">
        <Field
          name="codeAttributeName3"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.codeAttributeName3 }"
          placeholder="코드 속성3 을 입력해주세요"
          v-model.trim="dataset.code.codeAttributeName3"
          maxlength="200"
        />
        <div class="invalid-feedback">
          {{ errors.codeAttributeName3 }}
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <label
        for="outputOrder"
        class="col-form-label col-lg-2"
        >순서</label
      >
      <div class="col-lg-10">
        <Field
          name="outputOrder"
          type="number"
          class="form-control"
          :class="{ 'is-invalid': errors.outputOrder }"
          placeholder="순서를(을) 입력해주세요"
          v-model.number="dataset.code.outputOrder"
          min="0"
        />
        <div class="invalid-feedback">
          {{ errors.outputOrder }}
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <label
        class="form-check-label col-lg-2"
        for="useYn"
        >사용여부</label
      >

      <div class="d-flex col-lg-10">
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input
              class="form-check-input"
              type="radio"
              name="useYn"
              v-model="dataset.code.useYn"
              :value="true"
            />예</label
          >
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label"
            ><input
              class="form-check-input"
              type="radio"
              name="useYn"
              v-model="dataset.code.useYn"
              :value="false"
            />아니오</label
          >
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { Form, Field, useForm } from "vee-validate";
import codeApi from "@/api/portal/codeApi";
import codeGroupApi from "@/api/portal/codegroupApi";
import * as yup from "yup";
export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      code: {
        codegroupId: "",
        codeId: "",
        codeName: "",
        codeDescription: "",
        codeAttributeName1: "",
        codeAttributeName2: "",
        codeAttributeName3: "",
        outputOrder: "",
        useYn: "",
      },
      originCode: {},
      codeGroupList: [],
    });

    const schema = yup.object().shape({
      codeId: yup
        .string()
        .min(1, "1글자 이상 36글자 이하로 입력해주세요")
        .max(36, "1글자 이상 36글자 이하로 입력해주세요")
        .required("코드 ID는 필수 입력값 입니다."),
      codeName: yup
        .string()
        .min(1, "1글자 이상 40 글자 이하로 입력해주세요")
        .max(40, "1글자 이상 40글자 이하로 입력해주세요")
        .required("코드 이름은 필수 입력값 입니다."),
      outputOrder: yup.string().matches(/^[0-9]+$/, "숫자만 입력해주세요"),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (value, actions) => {
        codeApi.updateCode(dataset.value.code).then((response) => {
          const successMessage = "수정 되었습니다.";
          proxy.$modalUtils.handleApiResponse(
            response,
            successMessage,
            actions,
            dataset.value.alert,
          );
        });
      },
      onClickOk: async () => {
        const compareOrder = (await fnGetCodeByCodeGroupId()) ?? [];
        const valid =
          compareOrder
            .filter((item) => item !== dataset.value.originCode.outputOrder)
            .indexOf(dataset.value.code.outputOrder) > -1;

        if (valid) {
          setFieldError("outputOrder", "중복되는 정렬순서입니다");
          return false;
        }
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        dataset.value.alert.isShow = false;
      },
    };

    const fnGetCodeByCodeGroupId = async () => {
      const result = await codeGroupApi.getCodeList(
        dataset.value.code.codegroupId,
      );
      if (result.isSuccessful) {
        return result.resultData.map((item) => item.outputOrder);
      }
    };

    const setFieldError = (id, message) => {
      proxy.$refs.form.setFieldError(id, message);
    };

    const initModalData = () => {
      dataset.value.code = proxy.$modalUtils.getModalData();
      dataset.value.originCode = proxy.$objectUtils.deepCopy(
        dataset.value.code,
      );
    };

    onMounted(() => {
      initModalData();
    });
    return { event, dataset, errors, schema };
  },
};
</script>
