<template>
  <div>
    <!-- <pre>
      startPage : {{ startPage }}
      totalPage : {{ totalPage }}
      countInPages : {{ countInPages }}
      dataset : {{ dataset }}
      pageInfo: {{ pageInfo }}
    </pre> -->
    <div class="row">
      <div class="col-lg-12">
        <ul class="pagination pagination-sm justify-content-center my-2">
          <li
            class="page-item"
            :class="startPage - dataset.defaultPages < 1 ? 'disabled' : null"
          >
            <button
              v-on:click="event.onClickPageNo(1)"
              class="page-link"
            >
              <i class="mdi mdi-chevron-double-left"></i>
            </button>
          </li>
          <li
            class="page-item"
            :class="startPage - dataset.defaultPages < 1 ? 'disabled' : null"
          >
            <a
              href="javascript: void(0);"
              class="page-link"
              v-on:click.prevent="event.onClickPrevGroupStartPage()"
            >
              <i class="mdi mdi-chevron-left"></i>
            </a>
          </li>
          <li
            class="page-item"
            v-for="item in countInPages"
            :key="item"
            :class="startPage - 1 + item == pageInfo.page ? 'active' : null"
          >
            <button
              v-on:click="event.onClickPageNo(startPage - 1 + item)"
              class="page-link page-link-number"
            >
              {{ startPage - 1 + item }}
            </button>
          </li>
          <template v-if="countInPages === 0">
            <li class="page-item disabled">
              <button class="page-link page-link-number">1</button>
            </li>
          </template>
          <li
            class="page-item"
            :class="
              totalPage >= startPage + dataset.defaultPages ? null : 'disabled'
            "
          >
            <a
              href="javascript: void(0);"
              class="page-link"
              v-on:click.prevent="event.onClickNextGroupStartPage()"
            >
              <i class="mdi mdi-chevron-right"></i>
            </a>
          </li>
          <li
            class="page-item"
            :class="
              totalPage >= startPage + dataset.defaultPages ? null : 'disabled'
            "
          >
            <button
              v-on:click="event.onClickLastGroupStartPage()"
              class="page-link"
            >
              <i class="mdi mdi-chevron-double-right"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
export default {
  props: {
    pageInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    let dataset = ref({
      defaultPages: 10,
    });

    const startPage = computed(() => {
      let value =
        Math.floor((props.pageInfo.page - 1) / dataset.value.defaultPages) *
          dataset.value.defaultPages +
        1;
      return value;
    });

    const countInPages = computed(() => {
      let currPages = totalPage.value - (startPage.value - 1);
      if (currPages < dataset.value.defaultPages) {
        return currPages;
      } else {
        return dataset.value.defaultPages;
      }
    });

    const totalPage = computed(() => {
      const totalPage = props.pageInfo.totalPage ?? 10;

      if (totalPage != 0 && totalPage < props.pageInfo.page) {
        emit("changePageNumber", 1);
      }
      return totalPage;
    });

    const event = {
      onClickPageNo: (page) => {
        emit("changePageNumber", page);
      },
      onClickPrevGroupStartPage: () => {
        let prevGroupStartPage = startPage.value - dataset.value.defaultPages;
        if (prevGroupStartPage < 1) {
          prevGroupStartPage = 1;
        }
        event.onClickPageNo(prevGroupStartPage);
      },
      onClickNextGroupStartPage: () => {
        let nextGroupStartPage = startPage.value + dataset.value.defaultPages;
        if (nextGroupStartPage > totalPage.value) {
          nextGroupStartPage =
            totalPage.value -
            ((totalPage.value - 1) % dataset.value.defaultPages);
        }
        event.onClickPageNo(nextGroupStartPage);
      },
      onClickLastGroupStartPage: () => {
        let lastGroupStartPage =
          Math.floor((totalPage.value - 1) / dataset.value.defaultPages) *
            dataset.value.defaultPages +
          1;
        event.onClickPageNo(lastGroupStartPage);
      },
    };

    return {
      dataset,
      event,
      startPage,
      countInPages,
      totalPage,
    };
  },
};
</script>
<style scoped>
.page-link.page-link-number {
  padding-bottom: 5px;
}
</style>
