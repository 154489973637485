import api from "@/api/http";
export default {
  getDummyResourceList: (data = null) => {
    // 인텔리전스 리소스 더미 데이터 목록
    return api.getDummyPromise(
      "GET",
      "/www/dummy/optimizationIntelligence.json",
      data,
    );
  },

  getDummyIntelligenceOverview: (data = null) => {
    return api.getDummyPromise(
      "GET",
      "/www/dummy/optimizationIntelligenceOverview.json",
      data,
    );
  },
  getDummyIntelligenceDetail: (data = null) => {
    return api.getDummyPromise(
      "GET",
      "/www/dummy/optimizationIntelligenceDetail.json",
      data,
    );
  },

  getDummyResourceStatusTypeList: (data = null) => {
    // 인텔리전스 리소스 타입 코드 더미 데이터
    return api.getDummyPromise(
      "GET",
      "/www/dummy/optimizationResourceStatusType.json",
      data,
    );
  },
  //인텔리전스 목록조회
  getIntelligenceList: (data = null, useMultiValue = false) => {
    return api.getCostPromise(
      "GET",
      "/optimization/intelligence",
      data,
      null,
      useMultiValue,
    );
  },
  // 인텔리전스 요약
  getIntelligenceOverview: (data = null) => {
    return api.getCostPromise(
      "GET",
      "/optimization/intelligence/overview",
      data,
    );
  },
  // 인텔리전스 상세
  getIntelligence: (uid, data = null) => {
    return api.getCostPromise(
      "GET",
      `/optimization/intelligence/detail/${uid}`,
      data,
    );
  },
  // 인텔리전스 목록 엑셀 다운
  downIntelligenceExcel: (data = null) => {
    return api.getCostPromise("GET", "/optimization/intelligence/excel", data, {
      responseType: "blob",
      headers: { "x-file-type": true },
    });
  },
  getDummyOptimization: (data = null) => {
    return api.getDummyPromise("GET", "/www/dummy/optimization.json", data);
    // return api.getCostPromise("GET", `/optimization`, data, null);
  },

  getDummyOptimizationOverview: (data = null) => {
    return api.getDummyPromise(
      "GET",
      "/www/dummy/optimizationOverview.json",
      data,
    );
  },

  getDummyOptimizationDetail: (data = null) => {
    return api.getDummyPromise(
      "GET",
      "/www/dummy/optimizationDetail.json",
      data,
    );
  },

  getOptimization: (data = null) => {
    return api.getCostPromise("GET", `/optimization`, data, null, true);
  },

  getOptimizationOverview: (data = null) => {
    return api.getCostPromise("GET", `/optimization/overview`, data);
  },

  getOptimizationDetail: (uid, data = null) => {
    return api.getCostPromise("GET", `/optimization/detail/${uid}`, data);
  },

  getOptimizationExcel: (data) => {
    return api.getCostPromise("GET", `/optimization/excel`, data, {
      responseType: "blob",
      headers: { "x-file-type": true },
    });
  },
  getOptimizationExcelForBilling: ({
    Project,
    Region,
    InstanceText,
    InstanceType,
    CPUMax,
    MemMax,
    IsSavingPossible,
    page,
    size,
  }) => {
    return api.getPromise(
      "POST",
      `/portal/cost/optimization/excel`,
      {
        Project,
        Region,
        InstanceText,
        InstanceType,
        CPUMax,
        MemMax,
        IsSavingPossible,
        page,
        size,
      },
      {
        responseType: "blob",
        headers: { "x-file-type": true },
      },
    );
  },
  getIntelligenceExcelForBilling: ({
    Project,
    Region,
    Service,
    IntelligenceType,
    page,
    size,
  }) => {
    return api.getPromise(
      "POST",
      `/portal/cost/optimization/intelligence/excel`,
      {
        Project,
        Region,
        Service,
        IntelligenceType,
        page,
        size,
      },
      {
        responseType: "blob",
        headers: { "x-file-type": true },
      },
    );
  },
};
