<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="domainType"
        class="col-form-label col-lg-2"
        >도메인 유형</label
      >
      <div class="col-lg-10">
        <Field
          name="domainType"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.domainType }"
          placeholder="도메인 유형을 입력해주세요"
          v-model="dataset.privilege.domainType"
          maxlength="40"
        />
        <div class="invalid-feedback">
          {{ errors.domainType }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="resourceId"
        class="col-form-label col-lg-2"
        >리소스 ID</label
      >
      <div class="col-lg-10">
        <Field
          name="resourceId"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.resourceId }"
          placeholder="리소스 ID를 입력해주세요"
          v-model="dataset.privilege.resourceId"
          maxlength="40"
        />
        <div class="invalid-feedback">
          {{ errors.resourceId }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="action"
        class="col-form-label col-lg-2"
        >액션</label
      >
      <div class="col-lg-10">
        <Field
          name="action"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.action }"
          placeholder="액션을 입력해주세요"
          v-model="dataset.privilege.action"
          maxlength="40"
        />
        <div class="invalid-feedback">
          {{ errors.action }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="action"
        class="col-form-label col-lg-2"
        >외부권한여부</label
      >
      <div class="col-lg-10">
        <div class="form-check form-check-inline font-size-16">
          <input
            class="form-check-input"
            type="radio"
            name="externalYn"
            v-model="dataset.privilege.externalYn"
            :value="true"
          />
          <label class="form-check-label font-size-13">사용</label>
        </div>
        <div class="form-check form-check-inline font-size-16">
          <input
            class="form-check-input"
            type="radio"
            name="externalYn"
            v-model="dataset.privilege.externalYn"
            :value="false"
          />
          <label class="form-check-label font-size-13">사용안함</label>
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { Form, Field, useForm } from "vee-validate";
import privilegeApi from "@/api/portal/privilegeApi";
import * as yup from "yup";
export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      privilege: {
        domainType: "",
        resourceId: "",
        action: "",
      },
      domainList: [],
    });

    // validation schema
    const schema = yup.object().shape({
      action: yup
        .string()
        .min(1, "1글자 이상 40 글자 이하로 입력해주세요")
        .max(40, "1글자 이상 40글자 이하로 입력해주세요")
        .required("이름은 필수 입력값 입니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (value, actions) => {
        let request = [];
        request.push({
          privilegeId: dataset.value.privilege.privilegeId,
          domainType: dataset.value.privilege.domainType,
          action: dataset.value.privilege.action,
          externalYn: dataset.value.privilege.externalYn,
          resourceId: dataset.value.privilege.resourceId,
        });
        privilegeApi.updatePrivilege(request).then((response) => {
          const successMessage = "수정 되었습니다.";
          proxy.$modalUtils.handleApiResponse(
            response,
            successMessage,
            actions,
            dataset.value.alert,
          );
        });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        // 필드 에러 외 에러메세지 close
        dataset.value.alert.isShow = false;
      },
    };

    const initModalData = () => {
      dataset.value.privilege = proxy.$modalUtils.getModalData();
    };

    const getDomainTypeListForPrivilege = async () => {
      let result = await privilegeApi.getPrivilegeDomainTypeList();
      if (result.isSuccessful) {
        dataset.value.domainList = result.resultData;
      }
    };

    onMounted(() => {
      initModalData();
      getDomainTypeListForPrivilege();
    });
    return { event, dataset, errors, schema };
  },
};
</script>
