<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>
    <div class="row mb-4">
      <div
        class="alert alert-info mb-0 d-flex justify-content-left"
        role="alert"
      >
        <p class="me-1 mb-0 mdi mdi-alert-circle-outline"></p>
        <pre>
AI 엔진으로 학습된 데이터를 기반으로 설정된 지속기간동안 제한 값을 예측치보다 벗어나는 경우 알림이 생성됩니다.</pre
        >
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="ProjectId"
        class="col-form-label col-lg-2"
        >프로젝트</label
      >
      <div class="col-lg-10">
        <Field
          id="ProjectId"
          name="ProjectId"
          class="form-group"
          v-model="dataset.anomaly.ProjectId"
        >
          <VueTreeselect
            :class="{
              'anomaly-alarm-error-style': errors.ProjectId,
              'is-invalid': errors.ProjectId,
            }"
            v-if="!dataset.isLoading"
            v-model="dataset.anomaly.ProjectId"
            class="anomaly-alarm-custom-select"
            ref="customHierarchyFilter"
            :options="dataset.projectResultData"
            v-bind:multiple="false"
            v-bind:disableBranchNodes="true"
            v-bind:valueConsistsOf="`LEAF_PRIORITY`"
            v-bind:defaultExpandLevel="Infinity"
            :show-count="true"
            :clearable="false"
            v-bind:maxHeight="300"
            @select="event.onChangeSelectedProjectData"
            @deselect="event.onChangeSelectedProjectData"
            :placeholder="`프로젝트를 선택해주세요.`"
            :noOptionsText="`선택할 수 있는 옵션이 없습니다.`"
            :noResultsText="`선택할 수 있는 항목이 없습니다.`"
            v-bind:limitText="(count) => `(${count + limit})`"
            search-nested
          >
          </VueTreeselect>
        </Field>
        <div class="invalid-feedback">
          {{ errors.ProjectId }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="Region"
        class="col-form-label col-lg-2"
        >리전</label
      >
      <div class="col-lg-10">
        <Field
          id="Region"
          name="Region"
          class="form-group"
          v-model="dataset.anomaly.Region"
        >
          <VueTreeselect
            v-if="!dataset.isLoading"
            v-model="dataset.anomaly.Region"
            ref="customRegionType"
            :class="{
              'anomaly-alarm-error-style': !!errors.Region,
              'is-invalid': errors.Region,
            }"
            class="anomaly-alarm-custom-select"
            v-bind:options="dataset.regionResultData"
            v-bind:maxHeight="300"
            v-bind:showCount="true"
            v-bind:disableBranchNodes="true"
            v-bind:placeholder="`리전을 선택해 주세요.`"
            v-bind:multiple="false"
            v-bind:defaultExpandLevel="Infinity"
            v-bind:valueConsistsOf="`ALL`"
            v-bind:limitText="(count) => `(${count + limit})`"
            :clearable="false"
            search-nested
            v-on:select="event.onChangeSelectedRegionType"
            v-on:deselect="event.onChangeSelectedRegionType"
            :noOptionsText="`선택할 수 있는 옵션이 없습니다.`"
            :noResultsText="`선택할 수 있는 항목이 없습니다.`"
          >
          </VueTreeselect>
        </Field>
        <div class="invalid-feedback">{{ errors.Region }}</div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="Duration"
        class="col-form-label col-lg-2"
      >
        지속기간(시간)
      </label>
      <div class="col-lg-10">
        <Field
          id="Duration"
          name="Duration"
          type="number"
          class="form-control"
          :class="{ 'is-invalid': errors.Duration }"
          placeholder="지속기간을 입력해주세요"
          v-model="dataset.anomaly.Duration"
        />
        <div class="invalid-feedback">
          {{ errors.Duration }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="LimitType"
        class="col-form-label col-lg-2"
        >제한 기준</label
      >
      <div class="col-lg-10">
        <div class="form-group">
          <Field
            id="LimitType"
            name="LimitType"
            class="form-select"
            :class="{ 'is-invalid': errors.LimitType }"
            as="select"
            v-model="dataset.anomaly.LimitType"
          >
            <option
              value=""
              disabled
            >
              제한 기준 선택
            </option>
            <option
              v-for="(item, index) in dataset.limitTypeList"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </Field>
          <div class="invalid-feedback">{{ errors.LimitType }}</div>
        </div>
      </div>
    </div>
    <div
      class="row mb-4"
      v-if="dataset.anomaly.LimitType === 'VALUE'"
    >
      <label
        for="Value1"
        class="col-form-label col-lg-2"
      >
        제한 금액
        <Popper
          arrow
          placement="right"
          class="viewPopper"
        >
          <i class="bx bxs-info-circle font-size-16 mx-1"></i>
          <template #content>
            예측치에서 벗어나는 금액의 기준을 설정합니다. <br />예를 들어 예측
            금액이 1,000원 이고 실 금액이 1,200원일 때, 금액 기준 제한값을
            100원으로 설정하는 경우 알림이 생성됩니다.
          </template>
        </Popper>
      </label>
      <div class="col-lg-10">
        <div class="input-group">
          <Field
            v-if="!dataset.isLoading"
            id="Value1"
            name="Value1"
            type="number"
            class="form-control"
            :class="{ 'is-invalid': errors.Value1 }"
            placeholder="제한금액을 입력해주세요"
            v-model="dataset.limitCost"
          />
          <span
            class="input-group-text"
            id="basic-addon2"
            >원</span
          >
          <div class="invalid-feedback">
            {{ errors.Value1 }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="dataset.anomaly.LimitType === 'PCT'">
      <div class="row mb-4">
        <label
          for="Value2"
          class="col-form-label col-lg-2"
          >제한 비율</label
        >
        <div class="col-lg-10">
          <div class="input-group">
            <Field
              v-if="!dataset.isLoading"
              id="Value2"
              name="Value2"
              type="number"
              class="form-control"
              placeholder="제한 비율을 입력해주세요"
              :class="{ 'is-invalid': errors.Value2 }"
              v-model="dataset.limitRate"
            />
            <span
              class="input-group-text"
              id="basic-addon2"
              >%</span
            >
            <div class="invalid-feedback">{{ errors.Value2 }}</div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label
          for="MinimumValue"
          class="col-form-label col-lg-2"
          >최소 금액
          <Popper
            arrow
            placement="right"
            class="viewPopper"
          >
            <i class="bx bxs-info-circle font-size-16 mx-1"></i>
            <template #content>
              비율로 설정할 경우 비율의 최소금액을 설정합니다. <br />최소 금액
              미만의 비율 금액인 경우 알림이 생성되지 않습니다. <br />예를 들어
              비율을 10%로 설정하고 최소 금액이 100원인 경우 비율로 계산된
              금액이 80원인 경우 알림이 생성되지 않습니다.
            </template>
          </Popper></label
        >
        <div class="col-lg-10">
          <div class="input-group">
            <Field
              id="MinimumValue"
              name="MinimumValue"
              type="number"
              class="form-control"
              placeholder="최소 금액을 입력해주세요"
              :class="{ 'is-invalid': errors.MinimumValue }"
              v-model="dataset.anomaly.MinimumValue"
            />
            <span
              class="input-group-text"
              id="basic-addon2"
              >원</span
            >
            <div class="invalid-feedback">{{ errors.MinimumValue }}</div>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";
import anomalyApi from "@/api/cost/anomalyApi";
import VueTreeselect from "vue3-treeselect";
import organizationApi from "@/api/portal/organizationApi.js";
import codeApi from "@/api/portal/codeApi";
import { useStore } from "vuex";
export default {
  components: { Form, Field, VueTreeselect },
  setup() {
    const { proxy } = getCurrentInstance();
    const { getters } = useStore();
    const activeOrganization = getters["user/activeOrganization"];
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      anomaly: {},
      limitTypeList: [
        { value: "PCT", name: "비율" },
        { value: "VALUE", name: "고정금액" },
      ],
      initData: {
        region: [],
        project: [],
      },
      projectResultData: [],
      regionResultData: [],
      isLoading: true,
      limitCost: "",
      limitRate: "",
    });

    const schema = yup.object().shape({
      ProjectId: yup
        .string()
        .required("프로젝트는 필수 선택값 입니다.")
        .label("ProjectId"),
      Region: yup
        .string()
        .required("리전은 필수 선택값입니다.")
        .label("Region"),
      Duration: yup
        .number()
        .typeError("정수를 입력해주세요.")
        .integer("지속기간은 0보다 큰 정수값 입니다.")
        .positive("지속기간은 0보다 큰 정수값 입니다.")
        .min(0, "지속기간은 0보다 큰 정수값 입니다."),
      LimitType: yup.string().required("제한 기준은 필수 선택값 입니다."),
      Value1: yup
        .number()
        .typeError("숫자를 입력해주세요.")
        .integer("제한 금액은 0보다 큰 정수값 입니다.")
        .positive("제한 금액은 0보다 큰 정수값 입니다.")
        .min(0, "제한 금액은 0보다 큰 정수값 입니다.")
        .label("Value"),
      Value2: yup
        .number()
        .typeError("숫자를 입력해주세요.")
        .integer("제한 비율 0보다 큰 정수값 입니다.")
        .positive("제한 비율은 0보다 큰 정수값 입니다.")
        .min(0, "제한 비율은 0보다 큰 정수값 입니다.")
        .label("Value"),
      MinimumValue: yup
        .number()
        .typeError("숫자를 입력해주세요.")
        .integer("최소 금액은 0보다 커야합니다.")
        .positive("최소 금액은 0보다 커야합니다.")
        .min(0, "최소 금액은 0보다 커야합니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const getOrganizationHierarchyList = async () => {
      try {
        const result = await organizationApi.getOrganizationHierarchy(
          activeOrganization.organizationId,
        );

        if (result.resultData) {
          const transformOrganization = (organization) => {
            const { organizationId, organizationName, projectList } =
              organization;
            const children = projectList
              ? projectList
                  .filter((project) => project.projectType === "NHN")
                  .map((project) =>
                    transformProject(project, organizationId, organizationName),
                  )
              : null;

            return {
              id: organizationId,
              label: organizationName,
              children,
            };
          };

          const transformProject = (project, organizationId) => {
            const { projectName, projectId } = project;

            return {
              id: projectId,
              label: projectName,
              orgId: organizationId,
            };
          };

          dataset.value.projectResultData = result.resultData.map(
            transformOrganization,
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getCodeListForRegion = async () => {
      let response = await codeApi.getCodeListByCodegroupId("NHN_REGION_TYPE");
      if (response.isSuccessful) {
        dataset.value.regionResultData = response.resultData
          .sort((a, b) => a.outputOrder - b.outputOrder)
          .map((e) => {
            return {
              id: e.codeId,
              label: e.codeName,
            };
          });
      }
    };

    const event = {
      onChangeSelectedProjectData: (data) => {
        dataset.value.anomaly.ProjectId = data?.id;
        dataset.value.anomaly.ProjectName = data?.label;
        if (!dataset.value.anomaly.ProjectId)
          setFieldError("ProjectId", "잘못된 형식의 프로젝트입니다.");
      },
      onChangeSelectedRegionType: (data) => {
        dataset.value.anomaly.Region = data?.id;
        if (!dataset.value.anomaly.Region)
          setFieldError("Region", "잘못된 형식의 리전입니다");
      },
      onSubmit: (value, actions) => {
        const currentUid = proxy.$modalUtils.getModalData().UID;
        anomalyApi
          .updateAnomalySetting(currentUid, dataset.value.anomaly)
          .then((response) => {
            const successMessage = "수정 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              actions,
              dataset.value.alert,
            );
          });
      },
      onClickOk: async () => {
        const { valid } = await proxy.$refs.form.validate();
        if (!valid) return false;

        if (dataset.value.anomaly.LimitType === "VALUE") {
          dataset.value.anomaly.Value = dataset.value.limitCost;
          dataset.value.anomaly.MinimumValue = 0;
        } else if (dataset.value.anomaly.LimitType === "PCT") {
          dataset.value.anomaly.Value = dataset.value.limitRate;
        }

        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        dataset.value.alert.isShow = false;
      },
    };

    const setFieldError = (id, message) => {
      proxy.$refs.form.setFieldError(id, message);
    };

    const fnGetAnomalyAlarmSettingDetail = async (uid) => {
      const result = await anomalyApi.getAnomalySettingDetail(uid);
      if (result.isSuccessful) {
        dataset.value.anomaly = result.resultData;
        if (dataset.value.anomaly.LimitType === "VALUE") {
          dataset.value.limitCost = dataset.value.anomaly.Value;
        } else if (dataset.value.anomaly.LimitType === "PCT") {
          dataset.value.limitRate = dataset.value.anomaly.Value;
        }
        dataset.value.isLoading = false;
      }
    };

    const initModalData = () => {
      const modalData = proxy.$modalUtils.getModalData();
      modalData !== undefined && fnGetAnomalyAlarmSettingDetail(modalData.UID);
    };

    onMounted(async () => {
      await Promise.all([
        getOrganizationHierarchyList(),
        getCodeListForRegion(),
      ]);
      initModalData();
    });

    return { event, dataset, schema, errors };
  },
};
</script>
