<template>
  <div class="row">
    <div class="col-lg-6">
      <DataGrid
        v-bind:headers="dataset.allGrid.headers"
        v-bind:items="dataset.allGrid.items"
        v-bind:subPageInfo="dataset.allGrid.pageInfo"
        v-bind:loading="dataset.allGrid.loading"
        v-bind:refresh="false"
        v-bind:body-text-direction="'center'"
        v-bind:changeRowsPerPage="false"
        border-cell
      >
        <template #item-_actions="item">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-outline-primary btn-xs"
              v-on:click.prevent="event.onClickAdd($event, item)"
            >
              추가
            </button>
          </div>
        </template>
      </DataGrid>
    </div>
    <div class="col-lg-6">
      <DataGrid
        v-bind:headers="dataset.selectedGrid.headers"
        v-bind:items="dataset.selectedGrid.items"
        v-bind:subPageInfo="dataset.selectedGrid.pageInfo"
        v-bind:loading="dataset.selectedGrid.loading"
        v-bind:body-text-direction="'center'"
        v-bind:changeRowsPerPage="false"
        border-cell
      >
        <template #item-customHeader="item">
          <div class="btn-group">
            <select
              class="form-select form-select-sm"
              v-model="
                item[
                  dataset.selectedGrid.selectedGridCustomHeader.custom.idColumn
                ]
              "
              @change="event.onChangeCustomHeader($event, item)"
            >
              <option
                v-for="opt in dataset.selectedGrid.selectedGridCustomHeader
                  .custom.list"
                v-bind:key="opt"
                v-bind:value="
                  opt[
                    dataset.selectedGrid.selectedGridCustomHeader.custom
                      .idColumn
                  ]
                "
              >
                {{
                  opt[
                    dataset.selectedGrid.selectedGridCustomHeader.custom
                      .nameColumn
                  ]
                }}
              </option>
            </select>
          </div>
        </template>
        <template #item-_actions="item">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-outline-danger btn-xs"
              v-on:click.prevent="event.onClickRemove($event, item)"
            >
              제거
            </button>
          </div>
        </template>
      </DataGrid>
    </div>
  </div>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";

import DataGrid from "@/components/common/vues/grid/clientSideDataGrid.vue";
export default {
  components: { DataGrid },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const { data } = props;

    const dataset = ref({
      allGrid: {
        headers: [],
        items: [],
        loading: false,
        pageInfo: proxy.$pageUtils.getPageInfo(),
      },
      selectedGrid: {
        headers: [],
        items: [],
        originalItems: [],
        loading: false,
        pageInfo: proxy.$pageUtils.getPageInfo(),
        selectedGridCustomHeader: null,
      },
      idColumn: "id",
    });
    const gridAction = {
      text: "action",
      value: "_actions",
      sortable: false,
      width: 100,
      textAlign: "text-center",
    };

    const event = {
      onClickAdd: ($event, item) => {
        dataset.value.allGrid.items = proxy.$objectUtils.getArrayFilterItem(
          dataset.value.allGrid.items,
          item,
          dataset.value.idColumn,
        );
        dataset.value.selectedGrid.items.unshift(item);
        if ($event) {
          $event.target.blur();
        }
      },
      onClickRemove: ($event, item) => {
        dataset.value.selectedGrid.items =
          proxy.$objectUtils.getArrayFilterItem(
            dataset.value.selectedGrid.items,
            item,
            dataset.value.idColumn,
          );
        dataset.value.allGrid.items.push(item);
        if ($event) {
          $event.target.blur();
        }
      },
      init: (
        { headers, loading, pageInfo, idColumn, selectedGridCustomHeader },
        allGridItems,
        selectedGridItems,
      ) => {
        if (!headers) {
          console.error("Not Found Item [headers]");
        }

        // basic
        dataset.value.idColumn = idColumn ?? dataset.value.idColumn;

        // all grid
        const _allGridheaders = proxy.$objectUtils.deepCopy(headers);
        _allGridheaders.push(proxy.$objectUtils.deepCopy(gridAction));
        dataset.value.allGrid.loading = loading ?? false;
        dataset.value.allGrid.pageInfo =
          pageInfo ?? dataset.value.allGrid.pageInfo;
        dataset.value.allGrid.headers = _allGridheaders;
        dataset.value.allGrid.items = proxy.$objectUtils.deepCopy(allGridItems);

        // selected grid
        const _selectedGridheaders = proxy.$objectUtils.deepCopy(headers);
        if (selectedGridCustomHeader) {
          _selectedGridheaders.push(selectedGridCustomHeader);
          dataset.value.selectedGrid.selectedGridCustomHeader =
            selectedGridCustomHeader;
        }
        _selectedGridheaders.push(proxy.$objectUtils.deepCopy(gridAction));
        dataset.value.selectedGrid.loading = loading ?? false;
        dataset.value.selectedGrid.pageInfo =
          pageInfo ?? dataset.value.selectedGrid.pageInfo;
        dataset.value.selectedGrid.headers = _selectedGridheaders;
        if (selectedGridItems) {
          const copyOriginalItems =
            proxy.$objectUtils.deepCopy(selectedGridItems);
          const copyItems = proxy.$objectUtils.deepCopy(selectedGridItems);
          dataset.value.selectedGrid.originalItems = copyOriginalItems;
          for (const item of copyItems) {
            event.onClickAdd(null, item);
          }
        }

        // $(`#${dataset.value.modal.id}`).modal("show");
      },
      onChangeCustomHeader: ($event, item) => {
        const id = dataset.value.idColumn;
        const _item = dataset.value.selectedGrid.items.find(
          (obj1) => obj1[id] === item[id],
        );
        _item[
          dataset.value.selectedGrid.selectedGridCustomHeader.custom.idColumn
        ] = $event.target.value;
      },
      onClickCloseModal: () => {
        dataset.value = {
          allGrid: {
            headers: [],
            items: [],
            loading: false,
            pageInfo: proxy.$pageUtils.getPageInfo(),
          },
          selectedGrid: {
            headers: [],
            items: [],
            originalItems: [],
            loading: false,
            pageInfo: proxy.$pageUtils.getPageInfo(),
            selectedGridCustomHeader: null,
          },
          idColumn: "id",
        };
      },
      // 결과 리턴
      onClickOk: () => {
        let result = {
          createList: proxy.$objectUtils.getArrayDifference(
            dataset.value.selectedGrid.items,
            dataset.value.selectedGrid.originalItems,
            dataset.value.idColumn,
          ),
          deleteList: proxy.$objectUtils.getArrayDifference(
            dataset.value.selectedGrid.originalItems,
            dataset.value.selectedGrid.items,
            dataset.value.idColumn,
          ),
          updateList: [],
        };

        if (dataset.value.selectedGrid.selectedGridCustomHeader) {
          const id = dataset.value.idColumn;
          const customId =
            dataset.value.selectedGrid.selectedGridCustomHeader.custom.idColumn;
          const arr1 = proxy.$objectUtils.getArrayIntersection(
            dataset.value.selectedGrid.items,
            dataset.value.selectedGrid.originalItems,
            dataset.value.idColumn,
          );
          const arr2 = proxy.$objectUtils.getArrayIntersection(
            dataset.value.selectedGrid.originalItems,
            dataset.value.selectedGrid.items,
            dataset.value.idColumn,
          );
          const arr3 = arr1.filter((obj1) =>
            arr2.find(
              (obj2) =>
                obj1[id] === obj2[id] && obj1[customId] !== obj2[customId],
            ),
          );
          result["updateList"] = arr3;
        }

        if (
          result.createList.length === 0 &&
          result.updateList.length === 0 &&
          result.deleteList.length === 0
        ) {
          proxy.$awn.warning("변경사항이 없습니다.");
          return false;
        }
        return result;
      },
    };

    onMounted(() => {
      event.init(data.option, data.allGridList, data.selectedGridList);
    });
    return { event, dataset };
  },
};
</script>
