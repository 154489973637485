<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <!-- prettier-ignore -->
    <div class="row mb-4">
      <div class="alert alert-info mb-0 d-flex justify-content-left" role="alert">
        <p class="me-1 mdi mdi-alert-circle-outline mb-0"></p>
        <pre>부가서비스 추가 시 이전 청구서에는 반영되지 않으며 새로 생성되는 청구서부터 적용됩니다.</pre>
      </div>
    </div>
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="category"
        class="col-form-label col-lg-2"
        >카테고리</label
      >
      <div class="col-lg-10">
        <div class="form-group">
          <Field
            id="category"
            :class="{ 'is-invalid': errors.category }"
            v-model="dataset.invoice.category"
            name="category"
            as="select"
            class="form-select"
          >
            <option
              value=""
              disabled
            >
              부가서비스 카테고리 선택
            </option>
            <option
              v-for="item in dataset.categoryList"
              :key="item.codeId"
              :value="item"
            >
              {{ item.codeName }}
            </option>
          </Field>
          <div class="invalid-feedback">
            {{ errors.category }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <label
        for="additionalServiceName"
        class="col-form-label col-lg-2"
        >부가서비스 이름</label
      >
      <div class="col-lg-10">
        <Field
          id="additionalServiceName"
          name="additionalServiceName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.additionalServiceName }"
          placeholder="부가서비스 이름을 입력해주세요"
          v-model="dataset.invoice.additionalServiceName"
        />
        <div class="invalid-feedback">{{ errors.additionalServiceName }}</div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="applyLevel"
        class="col-form-label col-lg-2 d-flex align-items-center"
      >
        청구 기준

        <Popper
          arrow
          class="viewPopper"
          placement="right"
        >
          <i class="bx bxs-info-circle font-size-16 mx-1"></i>
          <!-- prettier-ignore -->
          <template #content>
              1) 조직 : 청구서의 전체 금액을 기준으로 계산합니다.<br />
              2) 프로젝트 : 프로젝트 금액을 기준으로 계산합니다.<br/>
            </template>
        </Popper>
      </label>
      <div class="col-lg-10">
        <div class="form-group">
          <Field
            :class="{ 'is-invalid': errors.applyLevel }"
            id="applyLevel"
            v-model="dataset.invoice.applyLevel"
            name="applyLevel"
            class="form-select"
            as="select"
          >
            <option
              value=""
              disabled
            >
              청구 기준 선택
            </option>
            <option
              v-for="(item, index) in dataset.applyLevelList"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </Field>
          <div class="invalid-feedback">
            {{ errors.applyLevel }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="row mb-4"
      v-if="dataset.invoice.applyLevel === 'Project'"
    >
      <label
        for="selectProjects"
        class="col-form-label col-lg-2"
        >구분/프로젝트</label
      >
      <div class="col-lg-10">
        <Field
          id="selectProjects"
          name="selectProjects"
          class="form-group"
          v-model="dataset.invoice.selectedProjects"
        >
          <accessible-hierarchy-invoice-filter
            class="anomaly-alarm-custom-select"
            :class="{ 'is-invalid': errors.selectProjects }"
            :status="errors.selectProjects !== undefined"
            @get-result-data="getData"
            :selectProjectIds="dataset.selectProjectIds"
          >
          </accessible-hierarchy-invoice-filter>
        </Field>
        <div class="invalid-feedback">
          {{ errors.selectProjects }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="priceUnit"
        class="col-form-label col-lg-2 d-flex align-items-center"
        >과금 방식
        <Popper
          arrow
          class="viewPopper"
          placement="right"
        >
          <i class="bx bxs-info-circle font-size-16 mx-1"></i>
          <!-- prettier-ignore -->
          <template #content>
            1) 비율 : "{청구 기준(조직/프로젝트)의 금액} X {비율} X {수량}"으로 부가서비스의 금액을 계산합니다.<br />
            2) 고정금액 : "{청구 기준(조직/프로젝트)의 금액} X {금액} X {수량}"으로로 부가서비스의 금액을 계산합니다.<br/>
          </template>
        </Popper>
      </label>
      <div class="col-lg-10">
        <div class="form-group">
          <Field
            :class="{ 'is-invalid': errors.priceUnit }"
            id="priceUnit"
            v-model="dataset.invoice.priceUnit"
            name="priceUnit"
            class="form-select"
            as="select"
          >
            <option
              value=""
              disabled
            >
              과금 방식 선택
            </option>
            <option
              v-for="(item, index) in dataset.priceUnitList"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </Field>
          <div class="invalid-feedback">
            {{ errors.priceUnit }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="row mb-4"
      v-if="dataset.invoice.priceUnit !== ''"
    >
      <label
        for="value"
        class="col-form-label col-lg-2"
        >비용/비율</label
      >
      <div class="col-lg-10">
        <div class="input-group">
          <Field
            id="value"
            name="value"
            type="number"
            class="form-control"
            :class="{ 'is-invalid': errors.value }"
            placeholder="비용/비율을 입력해주세요"
            v-model="dataset.invoice.value"
            @keydown="event.onChangeNumber($event)"
          />
          <!-- .lazy -->
          <span
            v-if="dataset.invoice.priceUnit === 'VALUE'"
            class="input-group-text"
            id="basic-addon2"
            >원</span
          >
          <span
            v-if="dataset.invoice.priceUnit === 'PCT'"
            class="input-group-text"
            id="basic-addon2"
            >%</span
          >
          <div class="invalid-feedback">
            {{ errors.value }}
          </div>
        </div>

        <div v-if="dataset.invoice.priceUnit === 'VALUE'">
          <p class="mt-2 mb-0 mx-1 align-middle text-end">
            {{ dataset.koreanNum }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="row mb-4"
      v-if="dataset.invoice.priceUnit !== ''"
    >
      <label
        for="amount"
        class="col-form-label col-lg-2"
        >수량</label
      >
      <div class="col-lg-10">
        <Field
          id="amount"
          name="amount"
          type="number"
          class="form-control"
          :class="{ 'is-invalid': errors.amount }"
          placeholder="수량을 입력해주세요"
          v-model="dataset.invoice.amount"
          @keydown="event.onChangeNumber($event)"
        />
        <div class="invalid-feedback">
          {{ errors.amount }}
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, onMounted, getCurrentInstance, watch } from "vue";
import { useStore } from "vuex";
import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";
import AccessibleHierarchyInvoiceFilter from "@/components/common/vues/accessibleHierarchyInvoiceFilter";
import customerCodeApi from "@/api/portal/customerCodeApi";
export default {
  components: { Form, Field, AccessibleHierarchyInvoiceFilter },
  setup() {
    const { proxy } = getCurrentInstance();
    const { getters } = useStore();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      selectProjectIds: [],
      invoice: {
        applyLevel: "",
        selectedProjects: [],
        category: "",
        additionalServiceName: "",
        priceUnit: "",
        amount: "",
        value: "",
      },
      applyLevelList: [
        { value: "Organization", name: "조직" },
        { value: "Project", name: "프로젝트" },
      ],
      priceUnitList: [
        { value: "PCT", name: "비율" },
        { value: "VALUE", name: "고정금액" },
      ],
      categoryList: [],
      isLoading: true,
      activeOrganizationId:
        proxy.$store.getters["user/activeOrganization"].organizationId,
      koreanNum: "",
    });

    const schema = yup.object().shape({
      applyLevel: yup.string().required("청구 기준은 필수 선택값 입니다."),
      additionalServiceName: yup
        .string()
        .required("이름은 필수 입력값 입니다."),
      category: yup
        .object()
        .shape({
          codeId: yup.string().required("카테고리는 필수 선택값 입니다."),
        })
        .typeError("카테고리는 필수 선택값 입니다."),
      priceUnit: yup.string().required("과금 방식은 필수 선택값 입니다."),
      amount: yup
        .number()
        .typeError("숫자를 입력해주세요.")
        .integer("수량은 0보다 큰 정수값 입니다.")
        .positive("수량은 0보다 큰 정수값 입니다.")
        .min(0, "수량은 0보다 큰 정수값 입니다."),
      value: yup
        .number()
        .typeError("숫자를 입력해주세요.")
        .integer("비용/비율은 0보다 커야합니다.")
        .positive("비용/비율은 0보다 커야합니다.")
        .min(0, "비용/비율은 0보다 커야합니다."),
      selectProjects: yup.array().min(1, "프로젝트를 하나이상 선택해주세요."),
    });

    watch(
      () => [dataset.value.invoice.value, dataset.value.invoice.priceUnit],
      () => {
        if (dataset.value.invoice.value < 0) {
          dataset.value.koreanNum = "";
          return false;
        }

        const convertToString = String(dataset.value.invoice.value);
        if (convertToString.length > 9) {
          proxy.$refs.form.setFieldValue(
            "value",
            Number(convertToString.substring(0, 9)),
          );
          dataset.value.koreanNum = `일금 ${dataset.value.invoice.value.convertToKoreanNumber()}원`;
        } else {
          if (dataset.value.invoice.value === "") {
            dataset.value.koreanNum = "";
          } else if (dataset.value.invoice.priceUnit === "VALUE") {
            dataset.value.koreanNum = `일금 ${dataset.value.invoice.value.convertToKoreanNumber()}원`;
          } else {
            dataset.value.koreanNum = "";
          }
        }
      },
      { deep: true },
    );

    watch(
      () => dataset.value.invoice.amount,
      () => {
        const convertToString = String(dataset.value.invoice.amount);
        if (convertToString.length > 9) {
          proxy.$refs.form.setFieldValue(
            "amount",
            Number(convertToString.substring(0, 9)),
          );
        }
      },
      { deep: true },
    );

    const getData = (data) => {
      dataset.value.invoice.selectedProjects = data;
    };

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onChangeNumber: (event) => {
        if (event.keyCode >= 37 && event.keyCode <= 40) {
          event.preventDefault();
        }
      },
      onClickOk: async () => {
        const { valid } = await proxy.$refs.form.validate();
        if (!valid) return false;

        return dataset.value.invoice ?? [];
      },
    };

    const fnGetCategoryList = async () => {
      const result = await customerCodeApi.getList(
        getters["user/activeCustomer"].customerId,
        "ADDSERVICE_CATEGORY",
      );
      dataset.value.categoryList = result.resultData;
    };

    onMounted(() => {
      fnGetCategoryList();
      dataset.value.selectProjectIds = proxy.$modalUtils.getModalData();
    });
    return { event, dataset, schema, errors, getData };
  },
};
</script>
<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
