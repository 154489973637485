<template>
  <div
    class="col"
    style="box-sizing: border-box"
  >
    <VueTreeselect
      v-bind:class="{
        'border-custom': status,
      }"
      v-if="dataset.isLoading"
      ref="component"
      v-model="dataset.selectedValues"
      :valueFormat="valueFormat"
      :options="dataset.resultData"
      v-bind:alwaysOpen="alwaysOpen"
      v-bind:value-consists-of="valueConsistsOf"
      v-bind:multiple="multiple"
      v-bind:placeholder="placeholder"
      v-bind:autoLoadRootOptions="autoLoadRootOptions"
      v-bind:branchNodesFirst="branchNodesFirst"
      v-bind:disableBranchNodes="disableBranchNodes"
      v-bind:showCount="showCount"
      v-bind:maxHeight="maxHeight"
      v-bind:sortValueBy="sortValueBy"
      v-bind:noOptionsText="noOptionsText"
      v-bind:noResultsText="noResultsText"
      v-bind:defaultExpandLevel="defaultExpandLevel"
      v-bind:clearOnSelect="clearOnSelect"
      :value="dataset.value"
      @select="getResultData"
      @deselect="getResultData"
      v-bind:limit="limit"
      v-bind:limitText="(count) => `(총 ${limit + count}개 선택)`"
      search-nested
    >
      <template v-slot:value-label="{ node }">
        <div>{{ $stringUtils.stringEscape(node.raw.label, 10) }}</div>
      </template>
    </VueTreeselect>
    <VueTreeselect v-if="!dataset.isLoading"> </VueTreeselect>
  </div>
</template>

<script>
import { onMounted, ref, getCurrentInstance, watch } from "vue";
import VueTreeselect from "vue3-treeselect";
import projectApi from "@/api/portal/projectApi.js";
import codegroupApi from "@/api/portal/codegroupApi";

export default {
  components: {
    VueTreeselect,
  },
  props: {
    noOptionsText: {
      type: String,
      default: "선택할 수 있는 옵션이 없습니다.",
    },
    noResultsText: {
      type: String,
      default: "선택할 수 있는 항목이 없습니다.",
    },
    sortValueBy: {
      // 정렬되는 순서 지정 - ORDER_SELECTED : 선택한 순서 / LEVEL / INDEX
      type: String,
      default: "ORDER_SELECTED",
    },
    maxHeight: {
      type: Number,
      default: 500,
    },
    showCount: {
      // 하위 노드 개수 표시
      type: Boolean,
      default: true,
    },
    disableBranchNodes: {
      // branch node 사용 여부 (true 시 branch node를 선택할 수 없음)
      type: Boolean,
      default: false,
    },
    branchNodesFirst: {
      type: Boolean,
      default: true,
    },
    autoLoadRootOptions: {
      // true : 마운트시 루트 옵션 자동 로드 / false : 메뉴가 열릴 때 데이터 로드
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "그룹 / 프로젝트를 선택해주세요.",
    },
    multiple: {
      // 중복 선택 가능
      type: Boolean,
      default: true,
    },
    alwaysOpen: {
      // 선택 창 항상 열어두기
      type: Boolean,
      default: false,
    },
    defaultExpandLevel: {
      // 선택 창 확장 레벨 선택
      type: Number,
      default: Infinity,
    },
    valueConsistsOf: {
      type: String,
      default: "LEAF_PRIORITY", // ALL, BRANCH_PRIORITY, ALL_WITH_INDETERMINATE, LEAF_PRIORITY
    },
    valueFormat: {
      // 선택 값 형식 설정 (id / object)
      type: String,
      default: "object",
    },
    defaultSelected: {
      // 전체 노드를 기본적으로 선택
      type: Boolean,
      default: false,
    },
    clearOnSelect: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 10,
    },
    selectProjectIds: {
      type: Array,
    },
    status: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();

    const dataset = ref({
      value: "node",
      selectedValues: [],
      resultData: [],
      selectProjectIds: props.selectProjectIds ?? [],
      isLoading: false,
      activeOrganization:
        proxy.$store.getters["user/activeOrganization"].organizationId,
    });

    // 부모 컴포넌트로 emit
    watch(
      () => dataset.value.selectedValues,
      () => {
        proxy.$emit("getResultData", dataset.value.selectedValues);
      },
    );

    // watch 대신 사용
    const getResultData = (/* node, instance */) => {
      let values = proxy.$refs.component.getValue() ?? [];
      proxy.$emit("getResultData", values);
    };

    const getProjectHierarchyList = async () => {
      const result = await projectApi.getHierarchyList(
        dataset.value.activeOrganization,
      );
      if (result.isSuccessful) {
        return result.resultData;
      }
      return [];
    };

    const fnGetCodeMapByCodeGroupId = async () => {
      const result = await codegroupApi.getCodeMap(
        `PROJECT_GROUP_${dataset.value.activeOrganization}`,
      );
      if (result.isSuccessful) {
        return result.resultData;
      }
      return {};
    };

    const selectProjects = (arr) => {
      const result = arr.filter((item) => {
        if (Array.isArray(item.children) && item.children.length > 0) {
          item.children = item.children.filter((val) => {
            return dataset.value.selectProjectIds.indexOf(val.id) > -1;
          });

          return item.children.length > 0;
        }
      });
      return result;
    };

    const mapData = (codeMapData, projectData) => {
      const transformProjectGroup = (item) => {
        const { projectGroup, projectInfo } = item;
        const children = projectInfo
          ? projectInfo.map((project) =>
              transformProject(project, projectGroup),
            )
          : null;

        return {
          id: projectGroup,
          label: codeMapData[projectGroup]?.codeName ?? projectGroup,
          children,
        };
      };

      const transformProject = (project, projectGroup) => {
        const { projectName, projectId } = project;
        return {
          group: projectGroup,
          id: projectId,
          label: projectName,
        };
      };

      dataset.value.resultData = selectProjects(
        projectData.map((item) => transformProjectGroup(item)),
      );

      proxy.$emit("onInitialData", dataset.value.resultData);
      dataset.value.isLoading = true;
    };

    onMounted(async () => {
      const [codeMapData, projectData] = await Promise.all([
        fnGetCodeMapByCodeGroupId(),
        getProjectHierarchyList(),
      ]);
      mapData(codeMapData, projectData);
    });

    return {
      dataset,
      getResultData,
    };
  },
};
</script>
<style>
@import "/www/css/vue3-treeselect-custom.css";

.border-custom {
  border: 1px solid #f46a6a;
  border-radius: 0.1rem;
  cursor: pointer;
  width: 100%;
}
</style>
