import api from "../http";
export default {
  getTaggroupList: (data = null) => {
    return api.getPromise("GET", "/portal/taggroup", data);
  },
  getTaggroupDetail: (tagGroupId = null) => {
    return api.getPromise("GET", `/portal/taggroup/${tagGroupId}`);
  },
  createTaggroup: (data = null) => {
    return api.getPromise("POST", `/portal/taggroup`, data);
  },
  updateTaggroup: (tagGroupId = null, data = null) => {
    return api.getPromise("PUT", `/portal/taggroup/${tagGroupId}`, data);
  },
  deleteTaggroup: (data = null) => {
    return api.getPromise("POST", `/portal/taggroup/delete`, data);
  },

  getTaggroupTagPagedList: (taggroupId = null, data = null) => {
    return api.getPromise("GET", `/portal/taggroup/${taggroupId}/tag`, data);
  },
  getTaggroupTagList: (taggroupId = null, data = null) => {
    return api.getPromise(
      "GET",
      `/portal/taggroup/${taggroupId}/tag/list`,
      data,
    );
  },
  createTaggroupTag: (taggroupId = null, data = null) => {
    return api.getPromise("POST", `/portal/taggroup/${taggroupId}/tag`, data);
  },

  deleteTaggroupTag: (taggroupId = null, data = null) => {
    return api.getPromise(
      "POST",
      `/portal/taggroup/${taggroupId}/tag/delete`,
      data,
    );
  },
  multipleDeleteTaggroup: (data = null) => {
    return api.getPromise("POST", `/portal/taggroup/delete/multiple`, data);
  },

  //naming 논의
  getTaggroupAllList: (data = null) => {
    return api.getPromise("GET", `/portal/taggroup/list`, data);
  },
};
