import api from "../http";

export default {
  getCodePage: (data = null) => {
    return api.getPromise("GET", `/portal/code`, data);
  },

  createCode: (data = null) => {
    return api.getPromise("POST", `/portal/code`, data);
  },

  updateCode: (data = null) => {
    return api.getPromise("PUT", `/portal/code/${data.codeId}`, data);
  },

  deleteCode: (data = null) => {
    return api.getPromise("POST", `/portal/code/delete`, data);
  },

  deleteMultipleCode: (data = null) => {
    return api.getPromise("POST", `/portal/code/delete/multiple`, data);
  },

  getCode: (data = null) => {
    return api.getPromise("GET", `/portal/code/${data.codeId}`, data);
  },

  getDuplicateCode: (data = null) => {
    return api.getPromise("GET", `/portal/code/duplicate`, data);
  },

  getCodeList: (data = null) => {
    return api.getPromise("GET", "/portal/code/list", data);
  },

  getCodeListByCodegroupId: (codegroupId) => {
    const data = {
      codegroupId,
      useYn: true,
      sort: "outputOrder#asc",
    };
    return api.getPromise("GET", "/portal/code/list", data);
  },

  sort: (codegroupId, data = null) => {
    return api.getPromise("POST", `/portal/code/${codegroupId}/sort`, data);
  },
};
