import api from "../http";

export default {
  getWorkGroupList: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/workgroup/list`,
      data,
    );
  },

  getWorkGroupPage: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/workgroup`,
      data,
    );
  },

  getMyWorkGroupList: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/workgroup/my/list`,
      data,
    );
  },

  getWorkGroup: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/workgroup/${data.workgroupId}`,
    );
  },

  createWorkGroup: (data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${data.organizationId}/workgroup`,
      data,
    );
  },

  updateWorkGroup: (data = null) => {
    return api.getPromise(
      "PUT",
      `/portal/organization/${data.organizationId}/workgroup/${data.workgroupId}`,
      data,
    );
  },

  deleteWorkGroup: (data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${data.organizationId}/workgroup/delete`,
      data,
    );
  },

  deleteMultipleWorkGroup: (data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${data.organizationId}/workgroup/delete/multiple`,
      data,
    );
  },

  getWorkGroupMemberList: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/workgroup/${data.workgroupId}/member/list`,
      data,
    );
  },

  getWorkGroupMemberPage: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/workgroup/${data.workgroupId}/member`,
      data,
    );
  },

  createWorkGroupMember: (data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${data.organizationId}/workgroup/${data.workgroupId}/member`,
      data,
    );
  },

  deleteWorkGroupMember: (data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${data.organizationId}/workgroup/${data.workgroupId}/member/delete`,
      data,
    );
  },

  getWorkGroupTagGroupPage: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/workgroup/${data.workgroupId}/taggroup`,
      data,
    );
  },

  getWorkGroupTagGroupList: (data = null) => {
    return api.getPromise(
      "GET",
      `/portal/organization/${data.organizationId}/workgroup/${data.workgroupId}/taggroup/list`,
      data,
    );
  },

  updateWorkGroupTagGroupInfo: (data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${data.organizationId}/workgroup/${data.workgroupId}/taggroup/manage`,
      data,
    );
  },

  updateWorkGroupUserGroupInfo: (data = null) => {
    return api.getPromise(
      "POST",
      `/portal/organization/${data.organizationId}/workgroup/${data.workgroupId}/usergroup/manage`,
      data,
    );
  },
};
