<template>
  <Form
    ref="form"
    @submit="event.onSubmit"
  >
    <div class="row mb-4 justify-content-start align-items-center">
      <label
        for="cost"
        class="col-form-label col-2"
        >금액</label
      >
      <div class="col-lg-10">
        <div class="input-group text-end">
          <Field
            id="cost"
            type="number"
            v-model="dataset.cost"
            :class="{ 'is-invalid': !dataset.cost }"
            name="cost"
            class="form-control col-8"
          />
          <span class="input-group-text">원</span>
        </div>
      </div>
      <div class="invalid-feedback">
        {{ errors.Cost }}
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import { Form, Field, useForm } from "vee-validate";
import { useCookies } from "vue3-cookies";

import * as yup from "yup";
export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      cost: 0,
    });

    const setNotShowToday = () => {
      isNotShow.value = true;
    };

    const confirm = () => {
      if (isNotShow.value) {
        cookies.set("isNotShow", "true", "1d"); // 1일 동안 쿠키 설정
      }
      showPopup.value = false;
    };

    const setFieldError = (id, message) => {
      proxy.$refs.form.setFieldError(id, message);
    };

    const schema = yup.object().shape({
      Cost: yup.string().required("금액은 필수입니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onClickOk: () => {
        // TODO validation 처리
        // if (invalidCost) {
        //   setFieldError("Cost", "금액은 필수 입력값 입니다.");
        //   return false;
        // }

        return dataset.value.cost ?? [];
      },
    };

    onMounted(() => {
      dataset.value.cost = proxy.$modalUtils.getModalData().budget;
    });
    return { event, dataset, errors, schema };
  },
};
</script>
