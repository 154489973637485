<template>
  <div
    class="row mb-4"
    v-show="dataset.alert.isShow"
  >
    <div
      class="alert alert-danger alert-dismissible fade mb-0"
      :class="{ show: dataset.alert.isShow }"
      role="alert"
    >
      <i class="mdi mdi-alert-circle-outline me-2"></i>
      {{ dataset.alert.message }}
      <button
        type="button"
        class="btn-close"
        v-on:click="event.onClickErrorAlertClose()"
      ></button>
    </div>
  </div>
  <div class="row mb-4">
    <span class="col-lg-2">스케줄 설정여부</span>
    <div class="col-lg-10">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            name="scheduled"
            :value="true"
            v-model="dataset.job.scheduled"
            @change="event.onChangeScheduled()"
          />예</label
        >
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label"
          ><input
            class="form-check-input"
            type="radio"
            name="scheduled"
            :value="false"
            v-model="dataset.job.scheduled"
            @change="event.onChangeScheduled()"
          />아니오</label
        >
      </div>
    </div>
  </div>
  <template v-if="dataset.job.scheduled">
    <div
      v-if="dataset.isLoading"
      class="loading-spinner"
    ></div>
    <cronEditor
      ref="cronEditorRef"
      v-if="!dataset.isLoading"
      v-model="dataset.cronExpression"
      @updateCronExpression="cronEditorEvent.changeCronExpression"
      @isValidExpression="cronEditorEvent.isValidExpression"
    />
  </template>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import schedulerApi from "@/api/portal/schedulerApi";

import cronEditor from "@/components/cronEditor/cronEditor";
export default {
  components: { cronEditor },
  setup() {
    const { proxy } = getCurrentInstance();
    const cronEditorRef = ref(null);

    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      cronExpression: "",
      cronExpressionValid: {
        isValid: false,
        message: "",
      },
      job: {},
      jobSchedule: {
        cronExpression: "",
      },
      isLoading: true,
    });

    const cronEditorEvent = {
      changeCronExpression: (cronExpression) => {
        console.log("changeCronExpression", cronExpression);
        dataset.value.cronExpression = cronExpression;
      },
      isValidExpression: (isValid, message) => {
        console.log("isValidExpression", isValid);
        dataset.value.cronExpressionValid.isValid = isValid;
        dataset.value.cronExpressionValid.message = message;
      },
    };

    const event = {
      onClickOk: () => {
        // if (!dataset.value.isValidExpression) {
        //   dataset.value.alert.isShow = true;
        //   dataset.value.alert.message =
        //     "Crontab 문자열 형식이 잘못되었습니다.";
        //   dataset.value.isValidExpression = false;
        //   return;
        // }
        const data = {
          cronExpression: dataset.value.cronExpression,
          scheduled: dataset.value.job.scheduled,
        };

        // schedulerApi
        //   .updateJobScheduleEnable(dataset.value.job.jobId)
        //   .then((response) => {
        //     const successMessage = "수정 되었습니다.";
        //     proxy.$modalUtils.handleApiResponse(
        //       response,
        //       successMessage,
        //       null,
        //       dataset.value.alert,
        //     );
        //   });

        return schedulerApi
          .updateJobSchedule(dataset.value.job.jobId, data)
          .then((response) => {
            const successMessage = "수정 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              null,
              dataset.value.alert,
            );
          });
      },
      onChangeScheduled: () => {},
    };

    // 모달 데이터 초기화
    const initModalData = () => {
      dataset.value.job = proxy.$modalUtils.getModalData();

      if (dataset.value.job.scheduled) {
        getJobSchedule();
      } else {
        dataset.value.isLoading = false;
      }
    };

    const getJobSchedule = () => {
      schedulerApi.getJobSchedule(dataset.value.job.jobId).then((response) => {
        if (response.isSuccessful) {
          dataset.value.jobSchedule = response.resultData ?? [];
          dataset.value.cronExpression =
            dataset.value.jobSchedule.cronExpression;

          dataset.value.isLoading = false;
        }
      });
    };

    onMounted(() => {
      initModalData();
    });

    return { dataset, event, cronEditorEvent, cronEditorRef };
  },
};
</script>
