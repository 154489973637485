import store from "@/store/index";

// 엘리먼트 처리 함수
function changeElement(vnode, el, removeOption) {
  if (removeOption == 1) {
    // 엘리먼트 제거
    el.parentElement.removeChild(el);
  } else if (removeOption == 2) {
    // 엘리먼트 숨김(엘리먼트의 영역 또는 크기 제거)
    el.style.display = "none";
  } else if (removeOption == 3) {
    // 엘리먼트 숨김(엘리먼트의 영역 또는 크기 유지)
    el.style.visibility = "hidden";
  } else if (removeOption == 4) {
    // 드롭다운 아이템 내 모두 없을 경우 드롭다운 display none 삭제
    if (!el.previousSibling && !el.nextSibling) {
      const DROPDOWN = "dropdown";
      const isNoBrother =
        el.parentElement.previousSibling.getAttribute("data-bs-toggle") ===
        DROPDOWN;
      // el.parentElement.previousSibling.remove()
      el.parentElement.previousSibling.style.display = isNoBrother
        ? "none"
        : "";
    } else {
      el.parentElement.removeChild(el);
    }
  }
}

export default {
  mounted(el, binding, vnode) {
    // 추후 10~40 까지 세션 스토리지로 고도화 가능

    // 0. cookie에서 사용자의 권한 확인
    //const userPrivilegeList = ['testexample_projects_C']; // ['testexample_projects_C', 'testexample_projects_R', 'testexample_projects_U', 'testexample_projects_D']
    const userPrivilegeList = store.getters["user/activePrivilegeList"]; // ['testexample_projects_C', 'testexample_projects_R', 'testexample_projects_U', 'testexample_projects_D']
    const isAdmin = store.getters["user/isAdmin"];

    // 10. 최고 관리자(ROLE_ADMIN)는 모든 권한을 가짐
    if (isAdmin) {
      return;
    }

    // 20. 컴포넌트 바인딩 값 확인
    let { domain, resource, action, removeOption } = binding.value; // keywords 중 하나
    removeOption = removeOption ?? 1; // 1: element remove(default), 2: element display none, 3: element visible hide

    // 21. domain: COST or PORTAL only
    if (!domain) {
      domain = window.location.hash
        .replace(/#\/([^\\/]+)\/.*/, "$1")
        .toUpperCase();
    }
    domain = "COST" == domain ? domain : "PORTAL";

    // 정보를 찾을 수 없으면 엘리먼트 처리
    if (!domain || !resource || !action) {
      changeElement(vnode, el, removeOption);
    }

    // 30. 현재 페이지의 리소스 조회
    let pagePrivilegeKeyword = `${domain}_${resource}_${action}`;
    const isPrivilegeCheck = !userPrivilegeList.find(
      (privilege) => privilege.privilegeCode == pagePrivilegeKeyword,
    );
    // 40. 사용자가 해당 엘리먼트를 사용 할 수 있는지 체크
    if (isPrivilegeCheck) {
      changeElement(vnode, el, removeOption);
    }
  },
};
