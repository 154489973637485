<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    v-slot="{ errors }"
    ref="form"
  >
    <div
      class="row mb-4"
      v-show="dataset.alert.isShow"
    >
      <div
        class="alert alert-danger alert-dismissible fade mb-0"
        :class="{ show: dataset.alert.isShow }"
        role="alert"
      >
        <i class="mdi mdi-alert-circle-outline me-2"></i>
        {{ dataset.alert.message }}
        <button
          type="button"
          class="btn-close"
          v-on:click="event.onClickErrorAlertClose()"
        ></button>
      </div>
    </div>
    <div
      class="row mb-4"
      :class="!dataset.duplicateCodeId ? 'was-validated' : ''"
    >
      <label
        for="codeId"
        class="col-form-label col-lg-2"
        >코드 ID</label
      >
      <div class="col-lg-10">
        <div class="input-group">
          <Field
            id="codeId"
            name="codeId"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.codeId }"
            placeholder="코드 ID를 입력해 주세요."
            v-model.trim="dataset.code.codeId"
            autocomplete="off"
            maxlength="36"
          />
          <div class="input-group-append">
            <button
              class="btn btn-primary ms-1"
              type="button"
              v-on:click="event.onClickDuplicateCode()"
            >
              <i class="bx bx-check-double font-size-16 align-middle me-1" />
              중복 확인
            </button>
          </div>

          <divcol-lg-2
            :class="
              dataset.duplicateCodeId ? 'invalid-feedback' : 'valid-feedback'
            "
          >
            {{ errors.codeId }}
          </divcol-lg-2>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeName"
        class="col-form-label col-lg-2"
        >코드 이름</label
      >
      <div class="col-lg-10">
        <Field
          name="codeName"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.codeName }"
          placeholder="코드 이름을 입력해주세요"
          v-model.trim="dataset.code.codeName"
          maxlength="40"
        />
        <div class="invalid-feedback">
          {{ errors.codeName }}
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label
        for="codeDescription"
        class="col-form-label col-lg-2"
        >코드 설명</label
      >
      <div class="col-lg-10">
        <Field
          as="textarea"
          rows="5"
          name="codeDescription"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errors.codeDescription }"
          placeholder="코드 설명을 입력해주세요"
          v-model="dataset.code.codeDescription"
          maxlength="200"
        />
        <div class="invalid-feedback">
          {{ errors.codeDescription }}
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted, watch } from "vue";
import { useStore } from "vuex";

import { Form, Field, useForm } from "vee-validate";
import * as yup from "yup";

import customerCodeApi from "@/api/portal/customerCodeApi";

export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const { getters } = useStore();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      codegroupId: "ADDSERVICE_CATEGORY",
      code: {
        codeId: "",
        codeName: "",
        codeDescription: "",
        codeAttributeName1: "",
      },
      prevCodeId: "",
      duplicateCodeId: true,
    });

    const schema = yup.object().shape({
      codeId: yup
        .string()
        .min(1, "1글자 이상 36글자 이하로 입력해주세요")
        .max(36, "1글자 이상 36글자 이하로 입력해주세요")
        .required("코드 ID는 필수 입력값 입니다."),
      codeName: yup
        .string()
        .min(1, "1글자 이상 40 글자 이하로 입력해주세요")
        .max(40, "1글자 이상 40글자 이하로 입력해주세요")
        .required("코드 이름은 필수 입력값 입니다."),
    });

    const { errors } = useForm({
      validationSchema: schema,
    });

    const event = {
      onSubmit: (value, actions) => {
        if (
          dataset.value.prevCodeId !== dataset.value.code.codeId ||
          dataset.value.duplicateCodeId
        ) {
          proxy.$awn.warning("중복체크를 해주세요.");
          return false;
        }

        dataset.value.code.codegroupId = dataset.value.codegroupId;
        customerCodeApi
          .create(
            getters["user/activeCustomer"].customerId,
            dataset.value.codegroupId,
            dataset.value.code,
          )
          .then((response) => {
            const successMessage = "생성 되었습니다.";
            proxy.$modalUtils.handleApiResponse(
              response,
              successMessage,
              actions,
              dataset.value.alert,
            );
          });
      },
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        dataset.value.alert.isShow = false;
      },
      onClickDuplicateCode: async () => {
        dataset.value.prevCodeId = dataset.value.code.codeId;
        dataset.value.code.codeId
          ? fnDuplicateCode()
          : proxy.$awn.warning("코드를 입력 해주세요.");
      },
    };

    const fnDuplicateCode = async () => {
      const result = await customerCodeApi.getDuplicate(
        getters["user/activeOrganization"].organizationId,
        dataset.value.codegroupId,
        dataset.value.code.codeId,
      );
      try {
        if (result.isSuccessful) {
          dataset.value.duplicateCodeId = result.resultData;
          result.resultData
            ? setFieldError("codeId", "이미 존재하는 아이디입니다.")
            : setFieldError("codeId", "사용 가능한 코드입니다.");
        } else {
          if (result.resultData.fieldErrors) {
            result.resultData.fieldErrors.forEach((item) => {
              setFieldError(item.field, item.defaultMessage);
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const setFieldError = (id, message) => {
      proxy.$refs.form.setFieldError(id, message);
    };

    watch(
      () => dataset.value.code.codeId,
      (/* value */) => {
        dataset.value.duplicateCodeId = true;
        setFieldError("codeId", "");
      },
    );

    // const num = function () {
    //   let num = 0;
    //   return function () {
    //     return num++;
    //   };
    // };

    //  onClickAddCodeAttribute: () => {
    //   //splice 로 특정 index delete
    //   //cmp
    //   let no = num();
    //   dataset.value.codeAttributeList.push(`codeAttribueName${no}`);
    //   //codeNo1 code
    //   //codeAttributeName3 문자열을 mapping 하는 부분
    // },

    onMounted(() => {});
    return { event, dataset, errors, schema };
  },
};
</script>
