export default {
  name: "anomaly",
  path: "/cost/anomaly",
  component: () => import("@/views/index"),
  redirect: "anomaly",
  meta: {
    group: "anomaly",
    isView: true,
  },
  children: [
    {
      name: "anomalyDetectionStatus",
      path: "status",
      meta: {
        title: "이상 비용 감지 현황",
        pageName: "이상 비용 감지 현황 목록",
      },
      component: () => import("@/views/anomaly/anomalyDetectionStatus"),
    },
    {
      name: "anomalyAlarmSettingList",
      path: "alarm",
      meta: {
        title: "이상 비용 알람 설정",
        pageName: "이상 비용 알람 설정 목록",
      },
      component: () => import("@/views/anomaly/anomalyAlarmSetting"),
    },
  ],
};
