<template>
  <ul
    class="nav nav-tabs nav-tabs-custom"
    role="tablist"
  >
    <li
      class="nav-item"
      v-for="item in dataset.ui.tabs"
      v-bind:key="item.id"
      v-on:click="event.onClickActiveTab(item.id)"
    >
      <a
        data-bs-toggle="tab"
        v-bind:href="`#${item.id}`"
        v-bind:class="{ active: dataset.ui.activeTabId == item.id }"
        class="nav-link"
        role="tab"
      >
        <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
        <span class="d-none d-sm-block">{{ item.title }}</span>
      </a>
    </li>
  </ul>

  <!-- Tab panes -->
  <div class="tab-content p-3 text-muted">
    <div
      v-for="item in dataset.ui.tabs"
      v-bind:key="item.id"
      v-bind:id="`${item.id}`"
      v-bind:class="{ active: dataset.ui.activeTabId == item.id }"
      class="tab-pane"
      role="tabpanel"
    >
      <p class="mb-0">
        <component
          v-bind:is="item.id"
          v-if="dataset.ui.activeTabId === item.id"
          :ref="dataset.ui.activeTabId"
        ></component>
      </p>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, getCurrentInstance, defineAsyncComponent } from "vue";

export default {
  components: {
    modalMypageBasicInfo: defineAsyncComponent(() =>
      import("@/views/mypage/components/modalMypageBasicInfo.vue"),
    ),
    modalMypageChangePassword: defineAsyncComponent(() =>
      import("@/views/mypage/components/modalMypageChangePassword.vue"),
    ),
    modalMypageOrganization: defineAsyncComponent(() =>
      import("@/views/mypage/components/modalMypageOrganization.vue"),
    ),
  },
  props: ["rowData"],
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      ui: {
        tabs: [
          {
            id: "modalMypageBasicInfo",
            title: "기본정보",
            footerButtonType: proxy.$modalUtils.modalFooterBtnType.TYPE1,
          },
          {
            id: "modalMypageChangePassword",
            title: "비밀번호 변경",
            footerButtonType: proxy.$modalUtils.modalFooterBtnType.TYPE1,
          },
          {
            id: "modalMypageOrganization",
            title: "나의 조직",
            footerButtonType: proxy.$modalUtils.modalFooterBtnType.TYPE2,
          },
          // { id: "modalMypage", title: "회원 탈퇴" },
        ],
        activeTabId: "modalMypageBasicInfo",
        modal: {
          footer: {
            onlyClose: true,
          },
        },
      },
    });

    const event = {
      onClickOk: (/*values*/) => {
        proxy.$refs[dataset.value.ui.activeTabId][0].event.onClickOk();
      },
      onClickActiveTab: (activeTabId) => {
        dataset.value.ui.activeTabId = activeTabId;

        dataset.value.ui.tabs.forEach((_tab) => {
          if (_tab.id == activeTabId && _tab.footerButtonType) {
            event.changeFooterButtonType(_tab.footerButtonType);
          }
        });
      },
      changeFooterButtonType: (btnType) => {
        proxy.$emit("footerButtonType", btnType);
      },
    };

    onMounted(() => {});
    return { dataset, event };
  },
};
</script>
