export default {
  name: "codeGroup",
  path: "/codegroup",
  component: () => import("@/views/index"),
  redirect: { name: "codeGroupList" },
  meta: {
    group: "portal",
    isView: true,
  },
  children: [
    {
      name: "codeGroupList",
      path: "list",
      meta: {
        title: "그룹코드 목록",
        pageName: "그룹코드 목록",
      },
      component: () => import("@/views/code/codeGroupList"),
    },
    {
      name: "codeGroupView",
      path: ":codeGroupId",
      meta: {
        title: "그룹코드 상세",
        pageName: "그룹코드 상세",
      },
      component: () => import("@/views/code/codeGroupView"),
      children: [
        {
          name: "codeGroupViewDetails",
          path: "details",
          meta: {
            title: "그룹코드 상세-기본정보",
          },
          props: true,
          component: () =>
            import("@/views/code/components/codeGroupViewDetails"),
        },
        {
          name: "codeGroupViewCodeList",
          path: "code",
          meta: {
            title: "그룹코드 상세-코드목록",
          },
          props: true,
          component: () =>
            import("@/views/code/components/codeGroupViewCodeList"),
        },
      ],
    },
  ],
};
