<template>
  <Form
    v-on:submit="event.onSubmit"
    v-bind:validation-schema="schema"
    ref="form"
  >
    <div>
      <div class="col-lg-12">
        <Field
          as="textarea"
          rows="10"
          name="extraNote"
          type="text"
          class="form-control"
          placeholder="기타 설명 내용이 표시되는 곳입니다."
          v-model="dataset.invoiceList[0].ExtraNote"
          maxlength="200"
        />
        <div class="invalid-feedback">
          <!-- {{ errors.codeDescription }} -->
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { Form, Field } from "vee-validate";

export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      alert: {
        isShow: false,
        message: "",
      },
      additionalService: {},
      invoice: {
        serviceType: "",
        selectedProjects: [],
        categoryName: "",
        additionalServiceName: "1",
        priceUnit: "",
        amount: "1",
        cost: "1",
      },
      invoiceList: [],
      isLoading: true,
    });

    const event = {
      onClickOk: () => {
        return dataset.value.invoiceList ?? [];
      },
    };

    const initModalData = () => {
      dataset.value.invoiceList = proxy.$modalUtils.getModalData();
    };

    onMounted(() => {
      initModalData();
    });
    return { event, dataset };
  },
};
</script>
