<template>
  <div class="m-3">
    <div class="row fs-5">
      <div class="col-12">
        <div class="table">
          <table class="table table-hover">
            <colgroup>
              <col class="w-20" />
              <col class="w-30" />
              <col class="w-20" />
              <col class="w-30" />
            </colgroup>
            <tbody>
              <tr>
                <th
                  scope="col"
                  class="table-active"
                >
                  리전
                </th>
                <td>{{ dataset.detail.RegionName }}</td>
                <th
                  scope="col"
                  class="table-active"
                >
                  프로젝트
                </th>
                <td>
                  {{ dataset.detail.ProjectName }}
                </td>
              </tr>
              <tr>
                <th
                  scope="col"
                  class="table-active"
                >
                  서비스
                </th>
                <td>{{ dataset.detail.Service }}</td>
                <th
                  scope="col"
                  class="table-active"
                >
                  유형
                </th>
                <td>
                  <span
                    class="font-size-12 badge"
                    v-bind:class="{
                      'badge-soft-secondary':
                        dataset.detail.IntelligenceType === 'StoppedInstance',
                      'badge-soft-success':
                        dataset.detail.IntelligenceType === 'UnusedFloatingIP',
                      'badge-soft-danger':
                        dataset.detail.IntelligenceType === 'UnusedInstance',
                    }"
                  >
                    {{ dataset.detail.IntelligenceTypeName }}
                  </span>
                </td>
              </tr>
              <tr>
                <th
                  scope="col"
                  class="table-active"
                >
                  리소스 아이디
                </th>
                <td>{{ dataset.detail.ResourceId }}</td>
                <th
                  scope="col"
                  class="table-active"
                >
                  리소스 이름
                </th>
                <td>{{ dataset.detail.ResourceName }}</td>
              </tr>
              <tr>
                <th
                  scope="col"
                  class="table-active"
                >
                  비용(월)
                </th>
                <td class="font-size-15 fw-bolder">
                  &#8361;&nbsp;{{ dataset.detail.LastMonthCost?.withComma() }}
                </td>
                <th
                  scope="col"
                  class="table-active"
                >
                  절감 가능 금액
                </th>
                <td class="text-primary font-size-15 fw-bolder">
                  &#8361;&nbsp;{{ dataset.detail.PossibleSaving?.withComma() }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import optimizationApi from "@/api/cost/optimizationApi";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const event = {};
    const dataset = ref({
      detail: {},
    });

    const initData = async () => {
      const uid = proxy.$modalUtils.getModalData()[0];
      // let response = await optimizationApi.getDummyIntelligenceDetail(uid);
      let response = await optimizationApi.getIntelligence(uid);
      dataset.value.detail = response.resultData;
    };
    onMounted(async () => {
      initData();
      await proxy.$emit(
        "footerButtonType", // 모달창 푸터 버튼 1개짜리
        proxy.$modalUtils.modalFooterBtnType.TYPE2,
      );
    });

    return { event, dataset };
  },
};
</script>
