export default {};
/*
- Do not Use Error Object
- using below Error type
  * SyntaxError
  * TypeError
  * EvalError
  * RangeError
  * ReferenceError
  * SyntaxError
  * URIError
*/

/* class AuthenticationError extends TypeError {
  constructor(description) {
    super("로그인 정보를 찾을 수 없습니다.");
    this.name = 'AuthenticationError';
    this.date = new Date();
    this.description = description;
  }
} */
const AuthenticationError = (description) => {
  const error = new TypeError("Login information not found.");
  error.name = 'AuthenticationError';
  error.date = new Date();
  error.description = description;
  return error;
};

export {
  AuthenticationError
};
