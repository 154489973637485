<template>
  <Form ref="form">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- <div class="loading-spinner"></div> -->
          <div class="bd-highlight"></div>
          <div class="card-body">
            <div>
              <div class="col-sm-auto">
                총
                <span class="fw-bold">{{ dataset.projectSummary?.length }}</span
                >건
              </div>
              <div class="table-responsive mt-2">
                <table class="table mb-0 table-hover">
                  <thead class="table-secondary">
                    <tr>
                      <th>카테고리</th>
                      <th>서비스</th>
                      <th>리전</th>
                      <th>과금단위</th>
                      <th class="text-end">지난달 비용(원)</th>
                      <th class="text-end">이번달 비용(원)</th>
                      <th class="text-end">증감액(원)</th>
                      <th class="text-end">증감률(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in dataset.projectSummary"
                      v-bind:key="item"
                    >
                      <td>{{ item.homepageCategory }}</td>
                      <td>{{ item.homepageService }}</td>
                      <td>{{ item.regionTypeCode }}</td>
                      <td>{{ item.displayName }}</td>
                      <td class="text-end">
                        {{ item.lastMonthPrice.withComma() }}
                      </td>
                      <td class="text-end">
                        {{ item.thisMonthPrice.withComma() }}
                      </td>
                      <td class="text-end">
                        <b
                          :class="
                            dataset.isIncrease(item.riseRate)
                              ? 'cost-increase-font'
                              : 'cost-decrease-font'
                          "
                        >
                          {{ item.riseCost.withComma() }}</b
                        >
                      </td>
                      <td class="text-end">
                        <b
                          :class="
                            dataset.isIncrease(item.riseRate)
                              ? 'cost-increase-font'
                              : 'cost-decrease-font'
                          "
                        >
                          {{ item.riseRate }}</b
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { Form, Field } from "vee-validate";
export default {
  components: { Form, Field },
  setup() {
    const { proxy } = getCurrentInstance();
    const dataset = ref({
      projectSummary: [],
      alert: {
        isShow: false,
        message: "",
      },
      isIncrease: (riseRate) => {
        const _riseRate = riseRate.replace("%", "");
        return _riseRate >= 0;
      },
    });

    const event = {
      onClickOk: () => {
        proxy.$refs.form.$el.requestSubmit();
      },
      onClickErrorAlertClose: () => {
        // 필드 에러 외 에러메세지 close
        dataset.value.alert.isShow = false;
      },
    };
    const initModalData = () => {
      dataset.value.projectSummary = proxy.$modalUtils.getModalData();
      dataset.value.projectSummary.sort(descSort);
    };

    const descSort = (a, b) => {
      if (b.riseRate !== a.riseRate) {
        return (
          proxy.$numberUtils.extractNumbersFromString(b.riseRate) -
          proxy.$numberUtils.extractNumbersFromString(a.riseRate)
        );
      }
      return b.riseCost - a.riseCost;
    };

    onMounted(() => {
      initModalData();
      proxy.$emit(
        "footerButtonType",
        proxy.$modalUtils.modalFooterBtnType.TYPE2,
      );
    });
    return { event, dataset };
  },
};
</script>
